import { IPost } from 'core/interfaces'
import React from 'react'
import styled, { AnyStyledComponent } from 'styled-components'
import {
  BREAKPOINT_DESKTOP,
  BREAKPOINT_TABLET_LARGE,
  BREAKPOINT_TABLET_MOBILE,
  COLORS,
  THEME_COLOR_VARIABLE_NAME,
} from '../../../../static/constants'

interface IProps {
  showDescription?: boolean
  isSignedIn: boolean
  post: IPost
  handleOnMouseEnter?(markerId): void
  handleOnMouseLeave?(): void
  getPostsByPage?(page: number): void
  title(count: number): string
}

const PostItem: React.FC<IProps> = props => {
  const { post } = props

  const isPublished = (state: 'published' | 'closed' | 'suspended' | 'draft') => {
    return state === 'published'
  }

  const handleOnMouseEnter = (id: number) => {
    if (typeof props.handleOnMouseEnter === 'function') {
      props.handleOnMouseEnter(id)
    }
  }

  const handleOnMouseLeave = () => {
    if (typeof props.handleOnMouseLeave === 'function') {
      props.handleOnMouseLeave()
    }
  }
  const TIME_FORMAT = new RegExp('^([01]?[0-9]|2[0-4]):([0-5][0-9])$')
  const checkTimeValue = (start: string, end: string) => {
    return start && end && TIME_FORMAT.test(start) && TIME_FORMAT.test(end)
  }

  return (
    <S.Container onMouseEnter={() => handleOnMouseEnter(post.id)} onMouseLeave={handleOnMouseLeave}>
      <a href={`/posts/${post.slug}`} target="_blank">
        <>
          {!isPublished(post.aasm_state) ? (
            <S.CloseImageContainer>
              {' '}
              {post.post_image && post.post_image.image_url ? (
                <img src={post.post_image.image_url} alt="" />
              ) : (
                <S.NoImage>No Image</S.NoImage>
              )}
              <S.CloseMsg>受付停止中</S.CloseMsg>
            </S.CloseImageContainer>
          ) : post.is_open ? (
            <S.OpenImageContainer>
              {post.post_image && post.post_image.image_url ? (
                <img src={post.post_image.image_url} alt="" />
              ) : (
                <S.NoImage>No Image</S.NoImage>
              )}
            </S.OpenImageContainer>
          ) : (
            <S.CloseImageContainer>
              {post.post_image && post.post_image.image_url ? (
                <img src={post.post_image.image_url} alt="" />
              ) : (
                <S.NoImage>No Image</S.NoImage>
              )}
              <S.CloseMsg>受付時間外</S.CloseMsg>
            </S.CloseImageContainer>
          )}
          <>
            <h2>{post.name}</h2>
            {post.tags && (
              <S.Tag>
                {post.tags.map((tag, tagIndex) => (
                  <span key={tagIndex} className="Tag">
                    <i className="material-icons">local_offer</i>
                    {tag.name}
                  </span>
                ))}
              </S.Tag>
            )}

            {post.category && <S.Category>{post.category.name}</S.Category>}

            {isPublished(post.aasm_state) && (
              <S.Times>
                {checkTimeValue(post.start_time1, post.end_time1) && (
                  <S.OpenTime>
                    <i className="material-icons">store_mall_directory</i>
                    {post.start_time1} - {post.end_time1}
                  </S.OpenTime>
                )}
                {checkTimeValue(post.start_time2, post.end_time2) && (
                  <S.OpenTime>
                    <i className="material-icons">navigate_next</i>
                    {post.start_time2} - {post.end_time2}
                  </S.OpenTime>
                )}
                {checkTimeValue(post.start_time3, post.end_time3) && (
                  <S.OpenTime>
                    <i className="material-icons">navigate_next</i>
                    {post.start_time3} - {post.end_time3}
                  </S.OpenTime>
                )}
              </S.Times>
            )}
          </>
        </>

        {props.showDescription && <p>{post.description}</p>}
      </a>
    </S.Container>
  )
}

const S: { [key: string]: AnyStyledComponent } = {}
S.Container = styled.div`
  padding: 10px;
  width: calc(100% / 4);
  flex-shrink: 0;
  @media (max-width: ${BREAKPOINT_DESKTOP}px) {
    width: calc(100% / 3);
  }

  @media (max-width: ${BREAKPOINT_TABLET_LARGE}px) {
    width: calc(100% / 2);
  }
  @media (max-width: ${BREAKPOINT_TABLET_MOBILE}px) {
    padding: 10px 0;
    width: 100%;
  }
  > a {
    color: ${COLORS.Text};
  }
`

S.OpenImageContainer = styled.div`
  position: relative;
  width: 100%;
  height: 320px;
  background-color: #f4f4f4;
  border-radius: 6px;
  overflow: hidden;
  @media (max-width: ${BREAKPOINT_TABLET_MOBILE}px) {
    width: 100%;
    height: 290px;
  }
  > img {
    width: inherit;
    height: 100%;
    object-fit: cover;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
  }
`

S.CloseImageContainer = styled.div`
  position: relative;
  width: 100%;
  height: 320px;
  background-color: #f4sf4f4;
  border-radius: 6px;
  overflow: hidden;
  @media (max-width: ${BREAKPOINT_TABLET_MOBILE}px) {
    width: 100%;
    height: 290px;
  }
  > img {
    width: inherit;
    height: 100%;
    object-fit: cover;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
  }
  ::after {
    content: '';
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    left: 1px;
    right: 1px;
    top: 0px;
    bottom: 0;
    height: 200pxs;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }
`

S.NoImage = styled.p`
  width: 100%;
  height: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f4f4f4;
`

S.CloseMsg = styled.p`
  margin: 0;
  padding: 0.2em;
  font-size: 2rem;
  color: #fff;
  font-weight: bold;
  position: relative;
  z-index: 1;
  margin-top: -132px;
  text-align: center;
  @media (max-width: ${BREAKPOINT_TABLET_MOBILE}px) {
    margin-top: -170px;
  }
`

S.Tag = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 4px -4px;
  color: var(${THEME_COLOR_VARIABLE_NAME});
  font-size: 13px;
  @media (max-width: ${BREAKPOINT_TABLET_MOBILE}px) {
    font-size: 12px;
  }
  span {
    display: flex;
    align-items: center;
    margin: 0 4px;
    i {
      font-size: 14px;
      margin-right: 4px;
    }
  }
`

S.Category = styled.div`
  font-size: 14px;
  margin-bottom: 4px;
  color: ${COLORS.TextPrimaryGray};
  @media (max-width: ${BREAKPOINT_TABLET_MOBILE}px) {
    font-size: 12px;
  }
`

S.Times = styled.div`
  display: flex;
  flex-wrap: wrap;
  font-size: 0.8rem;
  @media (max-width: ${BREAKPOINT_TABLET_MOBILE}px) {
    font-size: 0.6rem;
  }
  > * {
    display: inline-flex;
    align-items: center;
    @media (max-width: ${BREAKPOINT_TABLET_MOBILE}px) {
      margin: 8px 0;
    }
  }
`

S.OpenTime = styled.div`
  flex-shrink: 0;
  margin-right: 16px;
  @media (max-width: ${BREAKPOINT_TABLET_MOBILE}px) {
    margin: 0;
  }
  i {
    font-size: 17px;
    color: #fb585c;
  }
`

export default PostItem
