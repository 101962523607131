import * as React from 'react'

const SvgLogoTodoxiPc = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={238} height={44} fill="none" {...props}>
<g>
	<g>
		<path fill="#090000" d="M5.3,37.8c-3-2.7-5.6-7.6-1.9-16.7c1.2-3,2.6-5.6,3.9-8C11,5.9,14.6,1.3,23.1,1c10-0.3,13.9,6.8,15.8,10.1
			c1.3,2.4,2.6,5.4,4.5,10.5c2.3,6.2,1.9,13.8-1.5,16.4c-4.7,3.8-9.3,4.7-18.7,4.7C14.8,42.7,8.2,40.4,5.3,37.8z M23.1,43.4
			c9.7,0,14.5-1,19.4-4.8c3.5-2.7,3.9-10.9,1.6-17c-1.9-5.1-3.1-8.4-4.8-10.8C37.2,7.5,34.7,0.3,23.1,0.3c-8.5,0-12.5,5-16.4,12.5
			c-1.3,2.5-3,5.2-4.3,8.3c-3.9,9.4-0.9,14.5,2.2,17.2C7.7,41,14.5,43.4,23.1,43.4z"/>
		<path fill="#090000" d="M20.2,23.9c0,0-1.3,0.8-1.5,1.2c-0.3,0.7,0.2,1.1,0.2,1.1C19.3,26.1,20.4,24.6,20.2,23.9z"/>
		<path fill="#090000" d="M18.2,23H18c0,0-1.6,1.1-1.8,1.6C16,25,15.8,25.2,16,26.1c0,0,0.8,0.1,1.2-1.2c0.5-1.3,0.6-1.4,0.9-1.6
			L18.2,23z"/>
		<path fill="#090000" d="M17,22.3c0,0-2,0.5-3,1.4s-0.4,1.7-0.4,1.7S15.5,24,17,22.3z"/>
		<path fill="#090000" d="M15.1,21.9c0,0-1.9,0.2-2.5,0.6c-0.7,0.4-0.6,0.8-0.6,1.3c0,0,0.8-0.1,1.5-0.7C14.2,22.4,15.1,21.9,15.1,21.9
			z"/>
		<path fill="#090000" d="M13.5,21.5c0,0-1.6-0.1-2.1,0.4s-0.6,0.5-0.5,1.3c0,0,0.4,0,1.1-0.6C12.6,22,13.5,21.5,13.5,21.5z"/>
		<path fill="#090000" d="M12.7,21c0,0-0.5-0.6-1.7-0.1c-1,0.4-0.6,1.2-0.6,1.2s0.4-0.1,1.1-0.6C12,20.9,12.7,21,12.7,21z"/>
		<path fill="#090000" d="M11.6,18c0,0,0.1,0.7,0.9,0.8c0.8,0.1,1.5,0.1,1.5,0.1s-1.9,0.6-2.3,1.1c-0.5,0.4-1.9,1-1.9,1s-0.5-1-0.2-2.4
			c0.2-1.1,1.1-1.5,1.1-1.5l-0.6-0.9c0,0-0.1-1.4,0.3-2.8c0.4-1.3,1.2-2.7,1.8-3.4c0.6-0.7,2-2.5,2-2.5s0.2,0.1,0,0.7
			C14,9,14.3,9.1,14.3,9.1L14,9.2l-0.3,0.2l-0.2,0.3l-0.2,0.2L13.2,10l0.1,0.3c0,0,0.3,0.3,0.3,0.5S13.2,11,13.2,11s-0.6,0-1,0.7
			c0,0-0.1,0.3,0.4,0.5s0.5,0,0.5,0s-0.1,0.6-1.4,1.3c0,0-0.1,0.5,1.1,0.6c0,0,0.6,0.1,0.7,0.4c0,0,0.1,0.2-1.5,0.4
			c0,0,0.3,0.4,1.2,0.7l0,0c1,0.3,1.3,0.1,1.3,0.1s0.1,0.3-2.5,0.4c0,0,1.1,1,2.6,1.5c1.6,0.6,2.8,1,4.3,1.2s3,0.6,3,0.6
			s-2.5,0.1-4.6-0.3C15.2,18.7,11.6,18,11.6,18z"/>
		<path fill="#090000" d="M21.8,14.9l0.1,0.1v0.2l-0.1,0.2l-0.3,0.4l-0.3,0.3l-0.3,0.3l-0.1,0.3v0.2l0.1,0.2l0.2,0.2l0.2,0.1h0.2
			l0.3-0.1l0.3-0.2l0.2-0.1l0.2-0.1h0.2l-0.1-0.5V16v-0.5c0,0,0.1-0.6,0.4-0.8v0.4v0.8v0.8c0,0,0,0.4,0.4,0.7c0,0,0.3,0.2,0.5,0.1
			c0.3-0.1,0.3-0.3,0.3-0.3s0.1-0.1,0.2-0.3c0.1-0.3,0.1-1,0.1-1L24.4,15c0,0,0.2,0,0.4,0.6c0.2,0.6-0.1,1.5-0.1,1.5s0,0.1,0.3,0.3
			c0.3,0.2,0.6,0.3,0.7,0.4s0.3,0.1,0.5-0.1s0.2-0.3,0.2-0.7c-0.1-0.4-0.6-1-0.6-1s-0.5-0.6-0.5-0.7c0,0,0.3-0.1,0.8,0.6
			c0.6,0.7,0.6,1.3,0.6,1.3s0,0.6-0.4,1c0,0-0.4,0.1-1-0.1c-0.6-0.3-0.9-0.5-0.9-0.5s-0.2,0.5-0.9,0.4c-0.7-0.1-1-0.4-1-0.4
			s-0.1,0-0.2,0.1s-0.6,0.3-1.1,0.4s-0.6-0.1-0.7-0.2c-0.1-0.2-0.3-1,0.1-1.6C21.2,15.3,21.8,14.9,21.8,14.9z"/>
		<path fill="#090000" d="M25.4,19.7c0,0,7.5-1,10.3-2.6c0,0-0.1-0.3-2.6-0.4c0,0,0.5-0.3,1.7-0.4c1.2-0.2,1-0.3,1-0.3
			s-1.6-0.3-1.6-0.8c0,0,1.4-0.1,1.7-0.4c0,0,0-0.2-1.4-1.7c0,0,0.1-0.2,0.8,0c0,0,0.4-0.2,0.2-0.5s-0.3-0.7-1.2-0.8
			c0,0,0.3-0.7,0.1-1.1s-0.6-0.6-0.7-0.6c0,0,0.2-0.8-0.1-1.5c0,0,2.8,3,3.6,6s-0.4,3.7-0.4,3.7s0.8,0.5,1,2.1s-0.3,1.9-0.3,1.9
			s-0.8-0.1-2.1-1.3c-1.4-1.2-2-0.9-2-0.9s0-0.2,1.7-0.2c0,0,0.8-0.1,0.7-0.6c0,0-2.2,0.1-5,0.6C28.1,20,25.4,19.7,25.4,19.7z"/>
		<path fill="#090000" d="M26.5,24.2c0,0,0.5,0.2,0.8,1.6c0,0,0.4,1,1,0.7c0,0,0.4-0.4-0.3-1.5C27.5,24.2,26.5,24.2,26.5,24.2z"/>
		<path fill="#090000" d="M28.9,23.5c0,0,0.5,0.5,0.8,1.6s1,1.4,1.1,1.4c0,0,0.6-0.4,0.1-1.6C30.2,23.6,28.9,23.5,28.9,23.5z"/>
		<path fill="#090000" d="M30.2,22.9c0,0,0.7,0.3,1.8,1.7c0,0,0.8,1.1,1.5,1.3c0,0,0.5-0.5-0.3-1.6C32.5,23.2,30.2,22.9,30.2,22.9z"/>
		<path fill="#090000" d="M31.9,22.6c0,0,0.6,0,1.7,1.2c0,0,0.8,0.9,1.5,0.8c0,0,0.2-1-1.1-1.6C33.2,22.6,31.9,22.6,31.9,22.6z"/>
		<path fill="#090000" d="M33.7,22.3c0,0,0.7,0,1.3,1c0,0,0.6,0.8,1.1,0.8c0,0,0.4-0.7-0.2-1.2C34.8,21.9,33.7,22.3,33.7,22.3z"/>
		<path fill="#090000" d="M34.4,21.7c0,0,0.8-0.1,1.2,0.6c0.5,0.6,1.2,0.6,1.2,0.6s0.1-0.7-0.8-1.2C35.3,21.3,34.4,21.7,34.4,21.7z"/>
		<path fill="#090000" d="M18.4,24.1c0,0,0.3,0.1,1.2-0.9c0.9-1,2.1-1.5,3.5-1.5c1.4-0.1,3,0.4,3.5,0.9c0.6,0.4,0.7,0.6,1.2,1.4
			c0,0,0.3,0.4,0.8,0.5c0,0-0.3-1.5-1.3-2.1c-1-0.7-1.9-1.1-3.4-1.2c-1.6-0.1-2.8,0.3-3.1,0.4C19,22.3,18.4,24.1,18.4,24.1z"/>
		<path fill="#C44237" d="M18.8,23.9c0,0-0.2-0.1-0.1-0.3c0.2-0.2,1-1.1,1.6-1.5s2-0.7,2.8-0.7s2.1-0.1,3.7,0.8c0,0,1,0.4,1.7,1.9
			c0,0,0.1,0.1,0,0.3c-0.1,0.2-0.3,0-0.3,0s-0.6-1.1-1.4-1.6c-0.8-0.6-2.1-1.1-3.7-1s-2.6,0.6-2.6,0.6s-0.7,0.3-1.4,1.3
			C19.2,23.6,19,23.9,18.8,23.9z"/>
		<path fill="#090000" d="M19.3,9.3c0,0-0.8,0.3-0.2,1.2c0,0,0.3,0.3,0.6,0c0.3-0.3,0.1-0.7,0.1-0.7s0.7,0.8,0.5,2.4
			c-0.2,1.5-1.2,2-1.2,2s-1,0.8-2.7,0c-1.1-0.5-1.5-2.3-1.1-3.6c0.2-0.6,0.8-1.5,1.9-1.7C18.6,8.6,19.3,9.3,19.3,9.3z"/>
		<path fill="#090000" d="M18,8.4c0,0,1.5-0.1,2.3,1.6c0,0,1,2-0.2,3.7S17.3,15,17.3,15s-1.5,0-2.3-2s0.4-3.4,0.4-3.4S16.2,8.4,18,8.4z
			 M15.9,8.7c0,0-1.5,1.1-1.5,3s1.2,3,1.2,3s1.5,1.1,3,0.6s2-2.2,2-2.2s0.6-1.7-0.2-3.4c-0.8-1.7-2.6-1.5-2.6-1.5
			C16.8,8.1,15.9,8.7,15.9,8.7z"/>
		<path fill="#090000" d="M31.2,9.5c0,0-0.6,0.3-0.1,1.1c0,0,0.3,0.3,0.6,0.1c0.3-0.3,0.2-0.5,0.2-0.5s0.9,1.1,0.5,2.7
			s-1.5,1.9-1.5,1.9s-2,0.8-3.1-1.1c-1.2-1.9,0-3.4,0-3.4S29.3,8.6,31.2,9.5z"/>
		<path fill="#090000" d="M30.1,8.8c0,0,1.9-0.1,2.5,1.9c0.7,2,0,3.2,0,3.2s-0.8,1.8-2.9,1.6s-2.5-2.6-2.5-2.6S26.6,9.2,30.1,8.8z
			 M27.6,9.6c-1.3,1.2-0.8,3.5-0.8,3.5s0.7,2.5,2.8,2.6s2.9-1.1,2.9-1.1s1.4-1.4,0.6-3.9s-2.9-2.2-2.9-2.2S28.9,8.3,27.6,9.6z"/>
		<path fill="#090000" d="M39.1,18.3c-0.1-1.4-0.7-3.5-1.1-4.4c-0.3-0.9-1.3-2.9-1.7-3.7c-0.5-0.8-1.5-2.2-2.6-3.3s-1.9-1.5-2.6-1.8
			S28.6,5,27.3,5.2s-2.1,0.2-3.1,0.3c-1,0.1-3-0.4-3-0.4s-3-0.8-4.8,0S13.6,6.7,12,8.8s-2.6,5.1-3.2,6.5c-0.4,1.3-1,4.4-0.8,5.7
			c0.2,1.4,0.6,2.7,1.9,3.8c1.3,1.1,2.3,1.4,4.4,2.1S23,28,23,28s2.5,0.1,4.7,0.1c2.2-0.1,3.4-0.4,5.3-0.7c1.8-0.3,3.3-1.1,4.4-2.2
			s1.5-3.2,1.5-3.2S39.3,19.6,39.1,18.3z M38.9,20.8c0,1.5-1,3.3-1.5,3.7c-0.5,0.4-1.9,1.8-3.9,2.2c-2,0.4-5.6,1-8,0.8
			s-2.4-0.1-2.4-0.1s-4.7-0.1-6.4-0.6c-1.7-0.4-3.4-0.6-5.2-1.6S9,22.9,8.6,20.9S8.9,16,8.9,16s0.8-3.3,2-5.1s1.6-2.9,3.7-4.4
			c2.1-1.6,3.9-1.6,4.4-1.6c0.4,0,1.4,0.1,2.8,0.4h-0.1c0,0-0.4,0.2-0.4,0.5s0.2,1,0.2,1c-1.8-0.7-2.8-1.1-4.3-1.1S15,6.9,15,6.9
			C17.5,6,18.3,7,20.5,7.8s2.9-0.1,2.9-0.1C23.5,6.3,22,5.5,22,5.5l1,0.1c0.1,0,0.1,0.1,0.2,0.1c0.9,0.3,2,0.2,3.1,0
			c-0.4,0.2-1.2,0.7-1.6,1.2c-0.5,0.8,0.2,1.4,0.2,1.4c0.4,0.5,3.4-0.6,3.4-0.6s2.5-1,3.4-0.6c1,0.5,1.2,0.5,1.2,0.5
			s0.1-1.5-2.2-1.5c-1.4-0.1-4.2,1-4.2,1c-0.1-0.2,0.2-0.7,0.3-1c0.1-0.3-0.4-0.3-0.4-0.3l0.8-0.2h-0.1c1.1-0.2,2.1-0.3,2.9-0.1
			c1.8,0.6,3,0.9,4.7,3c1.8,2,2.9,4.5,3.5,6.8C38.8,17.3,38.9,19.3,38.9,20.8z"/>
	</g>
	<g fill="#090000">
		<path d="M59.5,35.1c-0.7,0-1.6-1-1.6-1.2c-0.1-0.5-0.3-1-0.3-4.5c0-3.3,0.3-5.7,0.6-7.2c-0.9,0-3.1,0.8-3.4,0.8
			c-0.2,0-0.6-0.6-0.6-1c0-0.1,0-0.3,0.1-0.3c0.4-0.3,4.5-1.7,9.2-1.7c9.8,0,10.8,4.7,10.8,6.6C74.3,30.8,70,35.1,59.5,35.1z
			 M63.3,21.7c-1.5,0-2.4,0.1-2.6,0.1c-0.2,1.1-0.6,3.1-0.8,5.3c-0.2,2.4-0.3,5-0.3,6.4c5.5,0,11.6-1.6,11.6-6.9
			C71.2,24.5,69.8,21.7,63.3,21.7z"/>
		<path d="M94.1,30.3c0,0.6-0.4,0.9-0.7,0.9c0,0-0.5,0-1.2-0.1c0.8,2,1.3,3.5,1.3,3.6c0,0.2-0.4,0.4-0.7,0.4c-0.7,0-0.7-0.2-0.8-0.3
			c0,0-0.8-1.7-1.9-3.8c-0.5,0-1-0.1-1.4-0.1c-1.2-0.1-3.2-0.1-5.3-0.1c-1.8,0-3.8,0-5.4,0.1c-1.1,2.1-1.9,3.9-2,3.9
			c0,0.1-0.1,0.3-0.8,0.3c-0.3,0-0.7-0.1-0.7-0.4c0-0.1,0.5-1.7,1.4-3.7c-0.6,0-1.1,0-1.4,0.1c-0.3,0-0.6-0.4-0.6-0.8
			c0-0.2,0.1-0.2,0.2-0.3c0.6-0.1,1.4-0.3,2.4-0.4c1.9-4.3,4.8-9.7,7.5-9.7s5.4,5,7.3,9.2c1.2,0.1,2.3,0.3,2.6,0.5
			c0.2,0.1,0.3,0.3,0.3,0.5C94.1,30.1,94.1,30.2,94.1,30.3z M84,23c-1.4,0-3.5,3.3-5.2,6.4c3-0.3,6.5-0.4,9.3-0.4H89
			C87.3,26,85.3,23,84,23z"/>
		<path d="M100.8,30.5c1.3,0.5,2.4,0.8,3.4,1.2c5.2,1.7,6.2,1.5,6.2,2.2c0,0.6-0.4,1-1,1.1c0,0-0.1,0-0.2,0c-1,0-4-1-6.9-2.1
			c-2.1-0.8-4.1-1.7-5.3-2.4c0,1.3,0,2.4,0,3.1v0.7c0,0.4-0.4,0.6-0.8,0.6s-0.9-0.2-1-0.5c-0.1-0.5-0.3-1.8-0.3-5.3
			c0-3.3,0.3-5.7,0.6-7.2c-0.8,0-3.1,0.8-3.3,0.8c-0.2,0-0.6-0.6-0.6-1c0-0.1,0-0.3,0.1-0.3c0.4-0.3,4.5-1.7,9.2-1.7
			c6.8,0,10.5,1.7,10.5,4.6C111.3,27.9,105.8,30,100.8,30.5z M102.2,21.3c-1.9,0-2.8,0-4.2,0.4c-0.2,1.1-0.6,3.1-0.8,5.4
			c-0.1,0.6-0.1,1.4-0.1,2c0.2,0,0.3,0,0.5,0h0.1c5.7-0.1,10.5-2,10.5-4.7C108.2,21.7,104.4,21.3,102.2,21.3z"/>
		<path d="M126.6,32.3c-1.5,1.8-3.8,2.8-6.1,2.8c-2.3,0-4.7-1-6.1-2.8c-1.7-2.1-2.2-5-2.2-7.2c0-0.8,0.1-1.6,0.2-2.2
			c0.4-2.1,1.4-2.8,2.6-2.9l0,0c0.3,0,0.8,0.1,0.8,0.7s-0.7,2.2-0.7,5.3c0,4.4,1.7,7.6,5.5,7.6c3.7,0,5.5-3.2,5.5-7.6
			c0-3.1-0.8-4.6-0.8-5.3c0-0.6,0.5-0.7,0.8-0.7l0,0c1.2,0,2.2,0.8,2.6,2.9c0.1,0.6,0.2,1.4,0.2,2.2
			C128.8,27.3,128.3,30.2,126.6,32.3z"/>
		<path d="M151.5,34.9L151.5,34.9c-2.2,0-2.7-3.1-2.7-5.5s0.2-3.4,0.2-4.5v-0.7c0-1.1-0.1-2.4-1.3-2.4c-2.4,0-4.2,4.5-4.2,10.8
			c0,0.7-0.4,1.4-1.3,1.4c-0.7,0-1-0.4-1-1.1v-0.1c0-0.7,0-1.4,0-2c0-9.1-2.1-9.1-2.1-9.1c-2.3,0-4.2,3.7-4.2,11.9
			c0,0.9-0.8,1.2-1.5,1.2c-0.5,0-1,0-1-0.8l0,0c0-0.8,0-1.5,0-2.2c0-7.8-1.4-9.4-3.4-9.4c-0.4,0-1-1.2-1-1.9c0-0.3,0.1-0.6,0.5-0.6
			c3.1,0,4.6,2.1,5.4,4.6c0.4-1.2,1.8-4.5,5-4.5c0,0,3.6,0,4,4c1.4-3.9,4.7-3.9,4.7-3.9c2.8,0,4.2,1.4,4.2,4.6
			c0,0.8-0.5,5.8-0.5,5.9c0,3.6,1.4,3.2,1.4,3.8C152.6,34.8,151.8,34.9,151.5,34.9z"/>
		<path d="M172.8,30.3c0,0.6-0.4,0.9-0.7,0.9c0,0-0.5,0-1.2-0.1c0.8,2,1.3,3.5,1.3,3.6c0,0.2-0.4,0.4-0.7,0.4
			c-0.7,0-0.7-0.2-0.8-0.3c0,0-0.8-1.7-1.9-3.8c-0.5,0-1-0.1-1.4-0.1c-1.2-0.1-3.2-0.1-5.3-0.1c-1.8,0-3.8,0-5.4,0.1
			c-1.1,2.1-1.9,3.9-2,3.9c0,0.1-0.1,0.3-0.8,0.3c-0.3,0-0.7-0.1-0.7-0.4c0-0.1,0.5-1.7,1.4-3.7c-0.6,0-1.1,0-1.4,0.1
			c-0.3,0-0.6-0.4-0.6-0.8c0-0.2,0.1-0.2,0.2-0.3c0.6-0.1,1.4-0.3,2.4-0.4c1.9-4.3,4.8-9.7,7.5-9.7s5.4,5,7.3,9.2
			c1.2,0.1,2.3,0.3,2.6,0.5c0.2,0.1,0.3,0.3,0.3,0.5C172.8,30.1,172.8,30.2,172.8,30.3z M162.7,23c-1.4,0-3.5,3.3-5.2,6.4
			c3-0.3,6.5-0.4,9.3-0.4h0.9C166,26,164,23,162.7,23z"/>
		<path d="M181.8,35.1c-0.7,0-1.6-1-1.6-1.2c-0.1-0.5-0.3-1-0.3-4.5c0-3.3,0.3-5.7,0.6-7.2c-0.9,0-3.1,0.8-3.4,0.8
			c-0.2,0-0.6-0.6-0.6-1c0-0.1,0-0.3,0.1-0.3c0.4-0.3,4.5-1.7,9.2-1.7c9.8,0,10.8,4.7,10.8,6.6C196.6,30.8,192.3,35.1,181.8,35.1z
			 M185.6,21.7c-1.5,0-2.4,0.1-2.6,0.1c-0.2,1.1-0.6,3.1-0.8,5.3c-0.2,2.4-0.3,5-0.3,6.4c5.5,0,11.6-1.6,11.6-6.9
			C193.5,24.5,192.1,21.7,185.6,21.7z"/>
		<path d="M203.7,31.8c-0.4,0.8-1.6,2.5-3.6,2.5c-2.7,0-3.7-1.9-3.7-3.1c0-1.7,1.5-3.1,3.4-3.1c1.4,0,2.5,0.8,2.5,1.9
			c0,1.2-1.2,1.9-2.5,1.9c-0.4,0-0.9-0.1-1.3-0.2c0.2,0.8,0.9,1.2,1.7,1.2c1.7,0,2.5-1,3.3-2.4c0.1-0.1,0.1-0.2,0.2-0.2
			c0.1,0,0.3,0.4,0.3,0.8C203.8,31.4,203.8,31.6,203.7,31.8z M199.8,29.5c-0.7,0-1.3,0.7-1.4,1.5c0.1,0.1,0.4,0.1,0.7,0.1
			c0.7,0,1.6-0.2,1.6-0.8C200.7,29.7,200.3,29.5,199.8,29.5z"/>
		<path d="M207.8,31.8c-0.3,0.6-1.2,2.5-2.8,2.5c-1.7,0-1.8-3.6-1.8-3.8c-0.1-1.3-0.1-2.9-0.1-4.9s0.1-4.2,0.3-4.5
			c0.1-0.1,0.7-0.4,0.9-0.4c0.3,0,0.7,0.3,0.7,0.4s0.1,1.1,0.1,2.7c0,1.9-0.2,4.8-0.2,7v0.8c0,0.8,0.1,1.4,0.5,1.4
			c0.6,0,1.5-1.2,2.1-2.5c0.1-0.1,0.1-0.2,0.2-0.2c0.1,0,0.3,0.4,0.3,0.8v0.1C207.9,31.5,207.8,31.7,207.8,31.8z"/>
		<path d="M209.2,25.2c0,0.7-0.6,1.3-1.3,1.3l0,0c-0.7,0-1.2-0.6-1.2-1.4V25c0-0.8,0.6-1.3,1.2-1.3h0.1
			C208.6,23.8,209.2,24.4,209.2,25.2L209.2,25.2z M211.5,31.8c-0.3,0.6-1.2,2.5-2.8,2.5c-1.5,0-2-1.5-2-3.5c0-0.5,0.1-2.2,0.2-2.4
			c0.1-0.2,0.4-0.3,0.8-0.3c0.5,0,0.8,0.1,0.9,0.3c0,0,0,0.2,0,0.5c0,0.6-0.1,1.7-0.1,2.4c0,1,0.1,1.8,0.6,1.8
			c0.6,0,1.5-1.2,2.1-2.5c0.1-0.1,0.1-0.2,0.2-0.2c0.1,0,0.3,0.4,0.3,0.8l0,0C211.7,31.4,211.6,31.6,211.5,31.8z"/>
		<path d="M216.2,28.4c0,0.7-0.7,5.9-3.3,5.9c-2.5,0-3.1-4.8-3.1-5.8v-0.1c0-0.3,0.5-0.3,1-0.3c0.8,0,1,0.1,1.1,0.3
			c0.1,1.1,0.5,4.6,1.5,4.6c0.8,0,1.5-3,1.6-4.6c0-0.3,0.4-0.3,0.6-0.3h0.2C216.1,28.1,216.2,28.2,216.2,28.4L216.2,28.4z"/>
		<path d="M223.6,31.8c-0.4,0.8-1.6,2.5-3.6,2.5c-2.7,0-3.7-1.9-3.7-3.1c0-1.7,1.5-3.1,3.4-3.1c1.4,0,2.5,0.8,2.5,1.9
			c0,1.2-1.2,1.9-2.5,1.9c-0.4,0-0.9-0.1-1.3-0.2c0.2,0.8,0.9,1.2,1.7,1.2c1.7,0,2.5-1,3.3-2.4c0.1-0.1,0.1-0.2,0.2-0.2
			c0.1,0,0.3,0.4,0.3,0.8C223.7,31.4,223.7,31.6,223.6,31.8z M219.7,29.5c-0.7,0-1.3,0.7-1.4,1.5c0.1,0.1,0.4,0.1,0.7,0.1
			c0.7,0,1.6-0.2,1.6-0.8C220.6,29.7,220.2,29.5,219.7,29.5z"/>
		<path d="M229.7,31.8c-0.3,0.6-1.2,2.5-2.8,2.5c-1.1,0-1.6-0.7-1.6-1.6c0-0.6,0.5-1.7,0.7-2.2c0-0.1,0-0.1,0-0.1
			c0-0.1-0.1-0.2-0.2-0.2c-0.5,0-1.2-0.2-1.4-0.3c-0.2,0.6-1.2,2.8-1.6,2.8c-0.2,0-0.3-0.7-0.3-0.8c0-0.1,0.8-1.2,1.1-2.4
			c-0.1-0.1-0.7-0.4-0.7-1.5c0-0.4,0.3-1.1,0.8-1.1c0.7,0,1,0.6,1,1.4c0.3,0.2,1.5,0.3,2,0.3c0.8,0,1.3,0.4,1.3,1c0,0.7-1,2.4-1,2.9
			c0,0.1,0.1,0.3,0.2,0.3c0.6,0,1.5-1.2,2.1-2.5c0.1-0.1,0.1-0.2,0.2-0.2c0.1,0,0.3,0.4,0.3,0.8C229.9,31.4,229.8,31.6,229.7,31.8z"
			/>
		<path d="M237.2,31.8c-0.7,1.3-1.5,2.2-2.4,2.9c0,1.4-0.1,2.7-0.2,3.7c-0.2,2.5-1.8,3.3-2.8,3.3c-1.4,0-2.4-1.2-2.4-2.8
			c0-2.1,1.7-3,3.6-4.3V33c-0.5,0.7-1.2,1.3-2.1,1.3c-1.5,0-2.1-1.5-2.1-3.5c0-0.5,0.1-2.2,0.2-2.4c0.1-0.2,0.4-0.3,0.8-0.3
			c0.5,0,0.9,0.1,1,0.3c0,0,0,0.2,0,0.5c0,0.6-0.1,1.7-0.1,2.4c0,1,0.1,1.8,0.6,1.8s1.1-0.8,1.7-1.7v-0.5c0-1.6,0-2.3,0.1-2.5
			s0.4-0.3,0.8-0.3c0.5,0,0.8,0.1,0.9,0.3c0,0.1,0.1,2.4,0.1,4.9c0.8-0.7,1.6-1.6,2.1-2.7c0-0.1,0.1-0.2,0.2-0.2
			c0.1,0,0.3,0.4,0.3,0.8v0.1C237.3,31.5,237.3,31.6,237.2,31.8z M233,36c-1.3,0.8-2.2,1.6-2.2,2.9c0,0.3,0.2,1.2,1.1,1.2
			c0.8,0,1.2-1,1.2-2.6C233,37.1,233,36.6,233,36z"/>
	</g>
	<g fill="#090000">
		<path d="M58.8,12.3V8.7h-3.3V8.2h7.1v0.5h-3.3v3.6h3.8v0.5h-3.8v3.4h-0.5v-3.4H55v-0.5C55,12.3,58.8,12.3,58.8,12.3z M56.7,9.2
			c0.5,0.8,0.8,1.7,1,2.4c-0.4,0.1-0.4,0.2-0.5,0.2c-0.2-0.7-0.5-1.6-1-2.4L56.7,9.2z M62.1,9.4c-0.3,0.7-0.8,1.6-1.3,2.4l-0.4-0.2
			c0.5-0.8,0.9-1.8,1.2-2.4L62.1,9.4z"/>
		<path d="M66.3,10.6h6.8v0.5h-6.8v1.4c0,1.3-0.2,2.6-0.9,3.7C65.2,16,65.1,16,65,15.9c0.6-1,0.8-2.2,0.8-3.3v-1.8h0.3h0.2V10.6z
			 M66.3,8.3v1.1h2.5V7.9h0.5v1.5h2.6V8.3h0.5v1.6h-6.6V8.3H66.3z M69.4,13.5v-1.2h-2.5v-0.4h5.6v0.4H70v1.2h3.1V14H70v2.1h-0.5V14
			h-2.9v-0.5H69.4z"/>
		<path d="M78,9.4c-0.4,2.2-1.2,4.5-2.4,6.1l-0.5-0.3c1.2-1.5,2-3.8,2.4-5.9L78,9.4z M83,15.2l-0.6,0.2c-0.4-1.7-1.4-4.1-2.5-6
			l0.5-0.2C81.6,11.1,82.6,13.4,83,15.2z"/>
		<path d="M89.2,15.9h-0.6v-4.6c-1.1,0.8-2.3,1.4-3.3,1.8L85,12.6c2.1-0.8,5.1-2.9,6.2-4.4l0.4,0.3c-0.6,0.7-1.5,1.5-2.5,2.3
			L89.2,15.9C89.1,15.9,89.2,15.9,89.2,15.9z"/>
		<path d="M96.3,10.7l-0.6-2.3l0.5-0.1l0.6,2.4l4.1-0.8l0.4,0.2c-0.4,0.9-1,2.1-1.6,3l-0.5-0.3c0.5-0.7,1.1-1.7,1.3-2.4L97,11.1
			l1.1,4.6l-0.5,0.1l-1.1-4.7l-2.4,0.5L94,11.1L96.3,10.7z"/>
	</g>
	<g fill="#090000">
		<path d="M111.2,11.8v0.6h-7.3v-0.6H111.2z"/>
	</g>
	<g fill="#090000">
		<path d="M138.1,15.9l-0.4-0.4c1.9-0.9,2.4-2,2.4-3.9h-3.7v-0.5h7.3v0.5h-3.1C140.6,13.6,140.1,14.8,138.1,15.9z M142.6,8.9v0.5
			h-5.2V8.9H142.6z M142.8,8.2c0.4,0.4,0.7,0.8,1,1.2l-0.3,0.2c-0.3-0.4-0.6-0.9-0.9-1.2L142.8,8.2z M143.6,7.6
			c0.4,0.4,0.7,0.8,1,1.2L144.3,9c-0.3-0.5-0.6-0.9-1-1.2L143.6,7.6z"/>
		<path d="M147.3,8.5v4.3h-0.5V8.5H147.3z M151.6,8.4v3.3c0,1.9-0.9,3.3-2.9,4.1l-0.4-0.5c1.9-0.8,2.7-2,2.7-3.6V8.4H151.6z"/>
		<path d="M157.1,9.5c-0.4,2.2-1.3,4.5-2.4,6l-0.5-0.3c1.1-1.5,2-3.7,2.3-5.8L157.1,9.5z M162.2,15.2l-0.6,0.2
			c-0.4-1.7-1.4-4-2.5-5.9l0.5-0.2C160.8,11.2,161.7,13.4,162.2,15.2z M160.5,8.1c0.3,0.5,0.5,1,0.7,1.5l-0.4,0.1
			c-0.2-0.5-0.4-1-0.7-1.5L160.5,8.1z M161.7,7.8c0.3,0.5,0.5,1,0.7,1.5L162,9.5c-0.2-0.5-0.4-1-0.7-1.5L161.7,7.8z"/>
		<path d="M165.5,8.5v4.3H165V8.5H165.5z M169.8,8.4v3.3c0,1.9-0.9,3.3-2.9,4.1l-0.4-0.5c1.9-0.8,2.7-2,2.7-3.6V8.4H169.8z"/>
		<path d="M180,11.8v0.6h-7.3v-0.6H180z"/>
		<path d="M184,10V8.2h0.5V10h3.2V8.2h0.5V10h1.7v0.5h-1.7v1c0,2.1-0.8,3.4-2.9,4.4l-0.4-0.5c2-0.9,2.7-2.1,2.7-3.9v-1.1h-3.2v2.4
			H184v-2.4h-1.7V10H184z"/>
		<path d="M199.5,11.8v0.6h-7.3v-0.6H199.5z"/>
		<path d="M208.2,10.5c-1.3,0.6-3.1,1.2-4.9,1.6v1.6c0,1,0.4,1.3,1.4,1.4c0.3,0,0.9,0,1.5,0c0.8,0,1.7,0,2.2-0.1v0.6
			c-0.7,0-1.5,0.1-2.3,0.1c-0.6,0-1.2,0-1.5,0c-1.2-0.1-1.9-0.6-1.9-1.8V8.5h0.6v3.1c1.7-0.4,3.5-1,4.7-1.6L208.2,10.5z M207.6,8.3
			c0.4,0.4,0.7,0.8,1,1.2l-0.3,0.2c-0.3-0.4-0.6-0.9-1-1.2L207.6,8.3z M208.5,7.8c0.4,0.4,0.7,0.8,1,1.2l-0.3,0.2
			c-0.3-0.4-0.6-0.9-1-1.2L208.5,7.8z"/>
		<path d="M218.5,15.5l-0.5,0.3c-0.7-0.9-1.9-2.1-2.8-2.8c-1,1.2-2.2,2.2-3.5,2.8l-0.4-0.5c2.5-1.2,4.6-3.8,5.2-5.8h-4.7V9.1h5
			l0.4,0.2c-0.4,1.1-1,2.3-1.8,3.3C216.5,13.4,217.6,14.6,218.5,15.5z"/>
	</g>
</g>
  </svg>
)

export default SvgLogoTodoxiPc
