import * as React from 'react'
import I18n from '../../../core/i18n'
import { IWindow } from '../../../core/interfaces'
import { IJsonResponse } from '../../../core/JsonApiSerializer'
import { adminService } from '../../../core/services'
import { EditTable } from '../../organisms'
import AdminLayoutIndex from './_layouts/'

declare var window: IWindow

interface IProps {
  posts: {
    posts: IJsonResponse
  }
}

const COLUMNS = [
  {
    name: I18n.t('generic.name'),
    field: 'name',
    required: true,
  },
  {
    name: I18n.t('generic.status'),
    field: 'aasm_state_i18n',
    required: false,
  },
  {
    name: I18n.t('generic.updated_at'),
    field: 'updated_at',
    required: false,
  },
]

const Index: React.FC<IProps> = props => {
  const { data } = adminService.getDataFromJson(props.posts.posts)
  const [posts, setPosts] = React.useState(data)
  const handleDelete = React.useCallback(
    async id => {
      const response = await adminService.deletePost(id)
      const flush = response.data.flush
      window.flashMessages.addMessage({ text: flush.message, type: flush.type })
      setPosts(posts.filter(post => post.id !== id))
    },
    [posts]
  )
  return (
    <AdminLayoutIndex
      model="post"
      heading={I18n.t('meta.post.shop')}
      main={
        <EditTable
          editable={true}
          columns={COLUMNS}
          records={posts}
          pagination={props.posts.posts.meta}
          handleDelete={handleDelete}
          getEditLink={id => `/posts/${posts.find(post => post.id === id).slug}/edit`}
        />
      }
    />
  )
}

export default Index
