import * as React from 'react'
import I18n from '../../../../core/i18n'
import { IJsonResponse } from '../../../../core/JsonApiSerializer'
import { adminService } from '../../../../core/services'
import { CheckBox, InputText } from '../../../atoms'
import AdminLayoutEdit from '../_layouts/edit'

interface IProps {
  category: IJsonResponse
}

const FIELDS = {
  id: 'id',
  name: 'name',
  position: 'position',
  direct_order_flag: 'direct_order_flag',
}

const title = I18n.t('admin.edit', {
  model: I18n.t('category', { scope: 'activerecord.models' }),
})

const AdminCategoryEdit: React.FC<IProps> = props => {
  const { data: category } = adminService.getDataFromJson(props.category)
  const handleSubmit = React.useCallback(async (initialValues, values) => {
    const { updatedCategory, flush } = await adminService.updateCategory(values)
    // TODO 遷移先でのFlush
    location.href = '/admin/categories'
  }, [])

  return (
    <AdminLayoutEdit
      model="category"
      title={title}
      fields={FIELDS}
      indexLink="/admin/categories"
      formItems={
        <>
          <InputText readonly={true} name="id" defaultValue={category.id} label="ID" />
          <InputText required={true} name="name" defaultValue={category.name} label="Name" />
          <InputText
            required={true}
            name="position"
            defaultValue={category.position}
            label="Position"
          />
          <CheckBox
            label={I18n.t('generic.direct_order_flag')}
            name="direct_order_flag"
            defaultChecked={category.direct_order_flag}
          />
        </>
      }
      handleSubmit={handleSubmit}
    />
  )
}

export default AdminCategoryEdit
