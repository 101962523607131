import { IAddress } from 'core/interfaces'
import * as React from 'react'
import styled, { AnyStyledComponent } from 'styled-components'
import I18n from '../../../../core/i18n'
import { BREAKPOINT_TABLET_SMALL } from '../../../../static/constants'
import { Button } from '../../../atoms'
import AdminLayoutIndex from '../_layouts/'
interface IProps {
  settings: any
  user_address?: IAddress
}

const AdminSettingsShow: React.FC<IProps> = props => {
  const settings = props.settings.reduce((obj, setting) => {
    obj[setting.var] = typeof setting.value === 'boolean' ? String(setting.value) : setting.value
    return obj
  }, {})

  return (
    <AdminLayoutIndex
      model="address"
      heading={I18n.t('settings.delivery_range')}
      main={
        <S.Settings>
          <dl>
            <dt>{I18n.t('activerecord.models.address')}</dt>
            <dd>{`${props.user_address?.google_full_address ?? ''} ${props.user_address?.other1_2 ??
              ''}`}</dd>
            <dt>{I18n.t('settings.delivery_range')}</dt>
            {!!settings.delivery_range && <dd>{settings.delivery_range} km</dd>}
          </dl>
          <S.Buttons>
            <Button primary={true}>
              <a href="/admin/address/edit">{I18n.t('generic.edit')}</a>
            </Button>
          </S.Buttons>
        </S.Settings>
      }
    />
  )
}

const S: { [key: string]: AnyStyledComponent } = {}

S.Settings = styled.div`
  dl {
    display: flex;
    flex-wrap: wrap;
    @media (max-width: ${BREAKPOINT_TABLET_SMALL}px) {
      flex-direction: column;
    }
  }

  dt {
    width: 240px;
    margin-top: 24px;
    font-weight: bold;
    @media (max-width: ${BREAKPOINT_TABLET_SMALL}px) {
      width: 100%;
      margin-top: 12px;
    }
  }

  dd {
    width: calc(100% - 240px);
    margin-top: 24px;
    @media (max-width: ${BREAKPOINT_TABLET_SMALL}px) {
      width: 100%;
      margin-top: 12px;
    }
  }
`

S.Buttons = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  margin-top: 48px;

  .Button[type='submit'] {
    width: 160px;
  }
`

export default AdminSettingsShow
