import axios from 'axios'
import * as React from 'react'
import styled, { AnyStyledComponent } from 'styled-components'
import { BREAKPOINT_TABLET_SMALL } from '../../../static/constants'

interface IContents {
  createdAt: string
  id: string
  keyImage: {
    url: string
  }
  publishedAt: string
  title: string
  HTML_page_code: string
  updatedAt: string
  viewDate: string
  visible: boolean
}

interface IProps {
  info_post: any
}

const TopNews: React.FC<IProps> = props => {
  const API_URL = 'https://darumadeli.microcms.io/api/v1/info/' + props.info_post.id
  const API_KEY = '657f85a1-cb2a-485b-9dcb-ad844f25016c'
  

  const [content, setContent] = React.useState<IContents>(Object)
  const getData = async () => {
    const { data } = await axios.get(API_URL, { headers: { 'X-API-KEY': API_KEY } })
    if (data) {
      setContent(data)
    }
  }

  React.useEffect(() => {
    getData()
  }, [])

  return (
    <S.TopNews>
      <S.Contents>
        {content ? (
          <S.Content key={content.id}>
            <S.ContentMain>
              {<div dangerouslySetInnerHTML={{ __html: content.HTML_page_code }} />}
            </S.ContentMain>
          </S.Content>
        ) : (
          <S.NoContents>お知らせはありません</S.NoContents>
        )}
      </S.Contents>
    </S.TopNews>
  )
}

const S: { [key: string]: AnyStyledComponent } = {}

S.TopNews = styled.section`
  width: 100%;
  max-width: 1360px;
  margin: 24px auto;
  padding: 0 16px;
`

S.Title = styled.h1`
  font-size: 24px;
  font-weight: bold;
`

S.NoContents = styled.h2`
  font-size: 18px;
  font-weight: normal;
  margin: 24px 0;
  padding: 0 20px;
`

S.Contents = styled.div`
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  margin: 0px;
`

S.Content = styled.div`
  display: flex;
  width: 100%;
  padding: 0px 0px;
  text-align: center;
  margin: 0 auto;
  @media (max-width: ${BREAKPOINT_TABLET_SMALL}px) {
    width: 100%;
  }

  > img {
    width: 160px;
    height: 120px;
    object-fit: cover;
  }
`

S.ContentMain = styled.div`
  margin-left: 20px;

  h2 {
    font-size: 18px;
  }

  p {
    margin-top: 10px;
    font-size: 16px;
  }
`

export default TopNews
