import moment from 'moment'
import { extendMoment } from 'moment-range'

import * as React from 'react'
import styled, { AnyStyledComponent } from 'styled-components'
import I18n from '../../../core/i18n'
import { ICartItem, IPost, IUser, IWindow } from '../../../core/interfaces'
import { reservationService } from '../../../core/services'
import { BREAKPOINT_TABLET_MOBILE } from '../../../static/constants'
import { formatPrice } from '../../../utils/number'
import { Button, InputTextArea, Spinner } from '../../atoms'

import { Form, InputDateTime } from '../../molecules'
import AddressForm, { ADDRESS_FIELDS, emptyToNull } from '../../organisms/AddressForm'
import StripeCardSelect from '../../organisms/StripeCardSelect'
import { IReservationParams } from '../posts/show'
const _moment = extendMoment(moment)

interface IProps {
  post: any
  cartItems: ICartItem[]
  reservationParams: IReservationParams
  user: IUser
}
interface IPriceAndAmount {
  amount: number
  price: number
}
declare var window: IWindow

const ReservationNew: React.FC<IProps> = ({ post, user, cartItems, reservationParams }) => {
  const [updatedAddress, setAddress] = React.useState(user.address)
  const [isCompleted, setIsCompleted] = React.useState(false)
  const [showOverlay, setShowOverlay] = React.useState(false)

  const [createdReservation, setCreatedReservation] = React.useState(null)
  const [updatedReservationParams, setReservationParams] = React.useState<IReservationParams>(
    reservationParams
  )
  const [taxiFare, setTaxiFare] = React.useState<number | null>(null)
  const [deliveryDistance, setDeliveryDistance] = React.useState<number | null>(null)
  const [totalPrice, setTotalPrice] = React.useState<number | null>(null)
  const [couponValue, setCouponValue] = React.useState<number | null>(null)
  const [campaignValue, setCampaignValue] = React.useState<number | null>(null)
  const [campaignName, setCampaignName] = React.useState<string>('')

  const now = moment()
  const [selectedDeliveryTime, setSelectedDeliveryTime] = React.useState<moment.Moment | null>(null)
  const minDate = moment().add(post.preorder_min_hours, 'hours')
  const [hasDeliveryTimeError, setHasDeliveryTimeError] = React.useState(false)

  function onChangeSelectedDeliveryTime(date: moment.Moment) {
    if (isOutsideRange(date)) {
      setHasDeliveryTimeError(true)
    } else {
      setSelectedDeliveryTime(date)
      setHasDeliveryTimeError(false)
    }
  }

  const [priceAndAmount, setPriceAndAmount] = React.useState<IPriceAndAmount>({
    amount: 0,
    price: 0,
  })
  const start = post.event_flag ? moment(post.delivery_start_time) : null
  const end = post.event_flag ? moment(post.delivery_end_time) : null
  let range = post.event_flag ? _moment.range(start, end) : null

  const startNYF = moment('2021/12/29 00:00')
  const endNYF = moment('2022/01/04 00:00')
  const newyearFilterRange = _moment.range(startNYF, endNYF)

  React.useEffect(() => {
    setPriceAndAmount(
      cartItems.reduce(
        (base, item) => {
          ;(base.amount += item.amount), (base.price += calc_price(item))
          return base
        },
        { amount: 0, price: 0 }
      )
    )
  }, [cartItems])

  const calc_price = item => {
    let optionSumItemPrice = 0
    item.cart_options.map(cart_option => {
      cart_option.cart_option_items.map(cart_option_item => {
        optionSumItemPrice += Number(cart_option_item.price)
      })
    })
    return (item.price + optionSumItemPrice) * item.amount
  }

  function getTimesByTen(date: moment.Moment, mode: 'ceil' | 'floor') {
    let transformed: number
    switch (mode) {
      case 'ceil':
        transformed = Math.ceil(date.get('minute') / 10) * 10
        break
      case 'floor':
        transformed = Math.floor(date.get('minute') / 10) * 10
        break
    }

    const newDate = date.clone()
    if (transformed === 60) {
      newDate.add(1, 'hour')
      newDate.set('minute', 0)
    } else {
      newDate.set('minute', transformed)
    }
    return newDate
  }

  const getDefaultStart = () => {
    const today = moment().startOf('day')
    const after_tomorrow = moment()
      .startOf('day')
      .add(2, 'days')
    let defaultStartDate = post.delivery_start_time

    if (post.force_order_date) {
      if (after_tomorrow > start.startOf('day')) {
        range = _moment.range(moment().add(1, 'days'), end)
        defaultStartDate = after_tomorrow.format('YYYY-MM-DD 00:00:00')
      }
    } else {
      if (today > start.startOf('day')) {
        range = _moment.range(moment(), end)
        defaultStartDate = today.format('YYYY-MM-DD 00:00:00')
      }
    }
    return defaultStartDate
  }

  React.useEffect(() => {
    const result = { ...updatedReservationParams }
    result.address = {
      ...result.address,
      ...updatedAddress,
    }
    result.reservation.distance = deliveryDistance || 0

    const deliveryTimeTarget = selectedDeliveryTime || moment()
    result.reservation.specified_delivery_time_at = deliveryTimeTarget.format('YYYY-MM-DD HH:mm:ss')
    setReservationParams(result)
  }, [updatedAddress, taxiFare, deliveryDistance, selectedDeliveryTime])

  const setTaxiFareFromDistance = guestLatLng => {
    const distanceMatrixService = new google.maps.DistanceMatrixService()
    const { latitude1, longitude1 } = post.address

    distanceMatrixService.getDistanceMatrix(
      {
        origins: [new google.maps.LatLng(latitude1, longitude1)],
        destinations: [guestLatLng],
        travelMode: google.maps.TravelMode.DRIVING,
        drivingOptions: {
          departureTime: new Date(),
          trafficModel: google.maps.TrafficModel.BEST_GUESS,
        },
      },
      async (response, status) => {
        if (status === google.maps.DistanceMatrixStatus.OK) {
          const results = response.rows[0].elements
          const distance = results[0].distance.value
          const {
            taxiFare: calculatedTaxiFare,
            price,
            couponValue: coupon_value,
            campaignValue: campaign_value,
            campaignName: campaign_name,
          } = await reservationService.calcTaxiFareAndTotal(
            distance,
            reservationParams.reservation.coupon,
            cartItems
          )
          setDeliveryDistance(distance)
          setTaxiFare(calculatedTaxiFare)
          setTotalPrice(price)
          setCouponValue(coupon_value)
          setCampaignValue(campaign_value)
          setCampaignName(campaign_name)
        }
      }
    )
  }
  const isOutsideRange = (day: moment.Moment) => {
    let response = false
    if (post.newyear_filter && response === false) {
      response = newyearFilterRange.contains(day) ? true : response
    }

    let day_name = day.locale('en').format('ddd')
    if (day_name === 'Thu') {
      day_name = 'Thur'
    }

    if (post.business_days.indexOf(day_name) === -1) {
      response = true
    }

    if (
      day
        .clone()
        .startOf('day')
        .isBefore(minDate.clone().startOf('day'))
    ) {
      response = true
    }

    return response
  }

  function getStartTime(postStartTimeStr: string): moment.Moment | null {
    if (!postStartTimeStr || !selectedDeliveryTime) {
      return null
    }

    const clonedNow = now.clone()
    const nowStr = clonedNow.startOf('date').format('YYYYMMDD')
    const clonedSelectedDate = selectedDeliveryTime.clone()
    const selectedDateStr = clonedSelectedDate.startOf('date').format('YYYYMMDD')
    const splitted = postStartTimeStr.split(':')

    if (nowStr < selectedDateStr) {
      return selectedDeliveryTime
        .clone()
        .set('hour', parseInt(splitted[0], 10))
        .set('minute', parseInt(splitted[1], 10))
    } else {
      const postStartTime = moment()
        .set('hour', parseInt(splitted[0], 10))
        .set('minute', parseInt(splitted[1], 10))

      let returnValue = now.clone()

      if (postStartTime.isAfter(returnValue)) {
        returnValue = postStartTime
      }

      const clonedReturnValue = returnValue.clone().startOf('date')
      const clonedMinDate = minDate.clone().startOf('date')
      if (clonedReturnValue.isSame(clonedMinDate) && minDate.isAfter(returnValue)) {
        returnValue = minDate
      }

      return getTimesByTen(returnValue, 'ceil')
    }
  }

  function getEndTime(postEndTimeStr: string): moment.Moment | null {
    if (!postEndTimeStr || !selectedDeliveryTime) {
      return null
    }

    const splitted = postEndTimeStr.split(':')
    const returnValue = selectedDeliveryTime
      .clone()
      .set('hour', parseInt(splitted[0], 10))
      .set('minute', parseInt(splitted[1], 10))

    if (moment().isAfter(returnValue)) {
      return null
    }

    return getTimesByTen(returnValue, 'floor')
  }

  const startTime1 = React.useMemo(() => getStartTime(post.start_time1)?.format('HH:mm'), [
    selectedDeliveryTime,
  ])
  const startTime2 = React.useMemo(() => getStartTime(post.start_time2)?.format('HH:mm'), [
    selectedDeliveryTime,
  ])
  const startTime3 = React.useMemo(() => getStartTime(post.start_time3)?.format('HH:mm'), [
    selectedDeliveryTime,
  ])

  function formatEndTimeFor24Hour(time?: moment.Moment) {
    const endTime = time?.format('HH:mm')
    if (endTime === '00:00') {
      return '23:59'
    }
    return endTime
  }

  const endTime1 = React.useMemo(() => formatEndTimeFor24Hour(getEndTime(post.end_time1)), [
    selectedDeliveryTime,
  ])
  const endTime2 = React.useMemo(() => formatEndTimeFor24Hour(getEndTime(post.end_time2)), [
    selectedDeliveryTime,
  ])
  const endTime3 = React.useMemo(() => formatEndTimeFor24Hour(getEndTime(post.end_time3)), [
    selectedDeliveryTime,
  ])

  const timeOptionsFilter = !post.event_flag
    ? null
    : (time: string, index: number, array: string[]) => {
        const startIndex1 = array.findIndex(s_time => s_time === startTime1)
        const endIndex1 = array.findIndex(e_time => e_time === endTime1)
        const startIndex2 = array.findIndex(s_time => s_time === startTime2)
        const endIndex2 = array.findIndex(e_time => e_time === endTime2)
        const startIndex3 = array.findIndex(s_time => s_time === startTime3)
        const endIndex3 = array.findIndex(e_time => e_time === endTime3)
        return (
          (index >= startIndex1 && index <= endIndex1) ||
          (index >= startIndex2 && index <= endIndex2) ||
          (index >= startIndex3 && index <= endIndex3)
        )
      }

  React.useEffect(() => {
    if (!isCompleted) {
      return
    }

    if (window.dataLayer) {
      window.dataLayer.push('event', 'conversion', {
        send_to: 'AW-458460316/nJJ1CKKR9-0BEJyZztoB',
        value: [priceAndAmount.price],
        currency: 'JPY',
        transaction_id: [priceAndAmount.amount],
      })
    }

    if (window.fbq) {
      window.fbq('track', 'Subscribe', {
        value: [priceAndAmount.price],
        currency: 'JPY',
        predicted_ltv: [priceAndAmount.amount],
      })
    }
  }, [priceAndAmount, isCompleted])
  return (
    <ReservationNewWrapper>
      <div className="Panel">
        {isCompleted ? (
          <div className="ReservationNew_Completed">
            <h2 className="ReservationNew_CompletedHeader">
              {I18n.t('reservation.reservation_completed')}
            </h2>
            <div className="ReservationNew_CompletedButtons">
              <Button>
                <a href={`/reservations/${createdReservation.slug}`}>
                  {I18n.t('reservation.reservation_detail')}
                </a>
              </Button>
              <Button>
                <a href={`/posts/search`}>{I18n.t('reservation.back_to_posts')}</a>
              </Button>
            </div>
          </div>
        ) : (
          <>
            <div className="ReservationNew_Post">
              <div className="ReservationNew_Image">
                {post.post_images && post.post_images.length > 0 ? (
                  <img src={post.post_images[0].image_url} alt="" />
                ) : (
                  <S.NoImage>No Image</S.NoImage>
                )}
              </div>
              <div className="ReservationNew_PostInfo">
                <h2 className="ReservationNew_PostTitle">{post.name}</h2>
              </div>
            </div>
            <div className="Panel_Section">
              <h3>{I18n.t('reservation.reservation_detail')}</h3>
              <table className="ReservationNew_Table">
                <thead>
                  <tr>
                    <th>商品</th>
                    <th>個数</th>
                    <th />
                  </tr>
                </thead>
                {cartItems.map(item => (
                  <tbody key={item.id}>
                    <tr>
                      <td>{item.name}</td>
                      <td>{item.amount}</td>
                      <td>{formatPrice(calc_price(item))}</td>
                    </tr>
                    <tr>
                      <td colSpan={3}>
                        {item.cart_options.map(cart_option => (
                          <S.OptionItem key={cart_option.id}>
                            {`${cart_option.title}`}
                            {cart_option.cart_option_items.map(cart_option_item => (
                              <li key={cart_option_item.id}>{cart_option_item.name}</li>
                            ))}
                          </S.OptionItem>
                        ))}
                      </td>
                    </tr>
                  </tbody>
                ))}
                <tbody>
                  {/* TODO */}
                  <tr>
                    <td />
                    <td>小計</td>
                    <td>{formatPrice(priceAndAmount.price)}</td>
                  </tr>
                  <tr>
                    <td />
                    <td>配送料</td>
                    <td>
                      {isNaN(taxiFare) ? '配達先住所を入力してください' : formatPrice(taxiFare)}
                    </td>
                  </tr>
                  {campaignValue !== 0 && (
                    <tr>
                      <td />
                      <td>{campaignName}</td>
                      <td>
                        -{''}
                        {isNaN(campaignValue)
                          ? '配達先住所を入力してください'
                          : formatPrice(campaignValue)}
                      </td>
                    </tr>
                  )}
                  {couponValue !== 0 && couponValue !== -1 && (
                    <tr>
                      <td />
                      <td>クーポン</td>
                      <td>
                        -{''}
                        {isNaN(couponValue)
                          ? '配達先住所を入力してください'
                          : formatPrice(couponValue)}
                      </td>
                    </tr>
                  )}
                  {couponValue === -1 && (
                    <tr>
                      <td />
                      <td>クーポン</td>
                      <td>このクーポンを使えません</td>
                    </tr>
                  )}
                  {/* <tr>
                    <td />
                    <td colSpan={2}>店舗と配送場所の距離により料金が変わります</td>
                  </tr> */}
                </tbody>
                <tfoot>
                  <tr>
                    <td />
                    <td>合計</td>
                    <td>{totalPrice ? formatPrice(totalPrice) : '計算中です'}</td>
                  </tr>
                </tfoot>
              </table>
              {post.event_flag && (
                <div className="Panel_Section">
                  <InputDateTime
                    required={post.event_flag}
                    name="set_delivery_time"
                    label={I18n.t('reservation.specified_delivery_time_at')}
                    defaultValue={getDefaultStart()}
                    timeOptionsFilter={timeOptionsFilter}
                    isOutsideRange={isOutsideRange}
                    error={
                      hasDeliveryTimeError ? I18n.t('reservation.outof_delivery_time_message') : ''
                    }
                    onChangeHandler={data => onChangeSelectedDeliveryTime(data)}
                  />
                </div>
              )}
              <div className="Panel_Section">
                <h4>{I18n.t('reservation.remarks_notes')}</h4>
                {post.note && <div className="Post_Note">{post.note}</div>}
                <div className="Panel_Section">
                  <p>
                    注文確定後すぐにお届け準備が開始されますので、商品が食品である事から、ご注文後の変更・キャンセルは原則お受けしておりません。
                    ※詳細は特定商取引に基づく表記をご確認ください。
                  </p>
                  {/* <p>
                    基本的にご注文後の変更・キャンセルはお承りしておりませんが、発送準備前でしたら、ご注文のキャンセル、内容の変更をお受けできる場合がございます。店舗の電話番号まで、お電話にてご連絡ください。
                  </p> */}
                </div>
                {/* {post.address && (
                  <p>
                    <i className="material-icons">phone</i> {post.address.phone}
                  </p>
                )} */}
              </div>
            </div>
            <div className="Panel_Section">
              <ReservationForm
                post={post}
                user={user}
                reservationParams={updatedReservationParams}
                setAddress={setAddress}
                setCreatedReservation={setCreatedReservation}
                setIsCompleted={setIsCompleted}
                setShowOverlay={setShowOverlay}
                updateTaxiFare={setTaxiFareFromDistance}
                cartItems={cartItems}
                taxiFare={taxiFare}
                couponValue={couponValue}
                campaignValue={campaignValue}
                campaignName={campaignName}
                totalPrice={totalPrice}
                priceAndAmount={priceAndAmount}
              />
            </div>
          </>
        )}
      </div>
      <S.Cover show={showOverlay}>
        {' '}
        <Spinner />{' '}
      </S.Cover>
    </ReservationNewWrapper>
  )
}

interface IReservationFormProps {
  post: IPost
  user: any
  reservationParams: IReservationParams
  cartItems: any
  taxiFare: any
  couponValue: any
  campaignValue: any
  campaignName: any
  totalPrice: any
  priceAndAmount: any
  setAddress(address: any): void
  setIsCompleted(flag: boolean): void
  setShowOverlay(flag: boolean): void
  setCreatedReservation(reservation: any): void
  updateTaxiFare(latLng: google.maps.LatLng): void
}

const FORM_RESERVATION_FIELDS = {
  remarks: 'remarks',
}

const FIELDS = {
  ...FORM_RESERVATION_FIELDS,
  ...ADDRESS_FIELDS,
}

const ReservationForm: React.FC<IReservationFormProps> = ({
  post,
  user,
  reservationParams,
  setAddress,
  setIsCompleted,
  setShowOverlay,
  setCreatedReservation,
  updateTaxiFare,
  cartItems,
  taxiFare,
  couponValue,
  campaignValue,
  campaignName,
  totalPrice,
  priceAndAmount,
}) => {
  const [isProcessing, setIsProcessing] = React.useState(false)
  const [getStripeParams, setStripeParamsGetter] = React.useState<any>(null)
  const [isSubmitEnabled, setIsSubmitEnabled] = React.useState(false)
  const [errors, setErrors] = React.useState<any>({})
  const formRef = React.useRef(null)
  const getStripeParamsRef = React.useRef(null)

  const handleUpdateForm = (updatedErrors, updatedIsSubmitEnabled, values) => {
    if (values.latitude1 && values.longitude1) {
      const guestLatLng = new google.maps.LatLng(values.latitude1, values.longitude1)
      updateTaxiFare(guestLatLng)
    }
    setAddress(values)
    setErrors(updatedErrors)
    setIsSubmitEnabled(!isProcessing && updatedIsSubmitEnabled)
  }

  const calc_price = item => {
    let optionSumItemPrice = 0
    item.cart_options.map(cart_option => {
      cart_option.cart_option_items.map(cart_option_item => {
        optionSumItemPrice += Number(cart_option_item.price)
      })
    })
    return (item.price + optionSumItemPrice) * item.amount
  }

  const resetProcess = () => {
    setShowOverlay(false)
    setIsProcessing(false)
  }

  React.useEffect(() => {
    getStripeParamsRef.current = getStripeParams
  }, [getStripeParams])

  const handleSubmit = React.useCallback(
    async (initialValues, values) => {
      if (!values.google_full_address || !values.latitude1 || !values.longitude1) {
        window.flashMessages.addMessage({
          text: '住所が誤っている可能性があります。再入力してください。',
          type: 'error',
        })

        return
      }

      let params = {
        ...reservationParams,
        reservation: {
          ...reservationParams.reservation,
        },
        address: {
          ...reservationParams.address,
        },
      }

      // 予約関連の値
      Object.keys(FORM_RESERVATION_FIELDS).forEach(key => {
        params.reservation[key] = values[key]
      })

      // 住所関連の値
      Object.keys(ADDRESS_FIELDS).forEach(key => {
        params.address[key] = emptyToNull(key, values[key])
      })

      setIsProcessing(true)
      setShowOverlay(true)
      if (typeof getStripeParamsRef.current === 'function') {
        const stripeParams = await getStripeParamsRef.current()
        if (stripeParams !== null) {
          params = { ...params, ...stripeParams }
        } else {
          window.flashMessages.addMessage({
            text:
              'クレジットカード情報が取得できませんでした。再入力するか時間を置いて再度お試しください。',
            type: 'error',
          })
          resetProcess()
          return
        }
      } else {
        window.flashMessages.addMessage({
          text: 'クレジットカード情報が取得できませんでした。時間を置いて再度お試しください。',
          type: 'error',
        })
        resetProcess()
        return
      }

      const createReservationResponse = await reservationService.createReservation(params)
      resetProcess()

      if (createReservationResponse.reservation) {
        setCreatedReservation(createReservationResponse.reservation)
        setIsCompleted(true)
      }
    },
    [getStripeParamsRef]
  )

  return (
    <S.ReservationForm>
      <h3>配達先指定</h3>
      <Form
        fields={FIELDS}
        handleSubmit={handleSubmit}
        handleUpdateForm={handleUpdateForm}
        ref={formRef}
      >
        <S.FormItem>
          <AddressForm
            defaultAddress={user.address}
            withMap={true}
            errors={errors}
            isGuest={true}
            updateForm={() => {
              formRef?.current.handleFormChange()
            }}
          />
        </S.FormItem>
        <S.FormItem>
          <InputTextArea
            required={false}
            name="remarks"
            defaultValue=""
            label={I18n.t('generic.note')}
            error={errors.remarks}
          />
        </S.FormItem>
        <S.FormComment>※さび抜きなど、ご注文に関する特記事項をご記入ください</S.FormComment>
        <StripeCardSelect setStripeParamsGetter={setStripeParamsGetter} />

        <div className="Panel_Section">
          <h3>{I18n.t('reservation.reservation_detail')}</h3>
          <table className="ReservationNew_Table">
            <thead>
              <tr>
                <th>商品</th>
                <th>個数</th>
                <th />
              </tr>
            </thead>
            {cartItems.map(item => (
              <tbody key={item.id}>
                <tr>
                  <td>{item.name}</td>
                  <td>{item.amount}</td>
                  <td>{formatPrice(calc_price(item))}</td>
                </tr>
                <tr>
                  <td colSpan={3}>
                    {item.cart_options.map(cart_option => (
                      <S.OptionItem key={cart_option.id}>
                        {`${cart_option.title}`}
                        {cart_option.cart_option_items.map(cart_option_item => (
                          <li key={cart_option_item.id}>{cart_option_item.name}</li>
                        ))}
                      </S.OptionItem>
                    ))}
                  </td>
                </tr>
              </tbody>
            ))}
            <tbody>
              {/* TODO */}
              <tr>
                <td />
                <td>小計</td>
                <td>{formatPrice(priceAndAmount.price)}</td>
              </tr>
              <tr>
                <td />
                <td>配送料</td>
                <td>{isNaN(taxiFare) ? '配達先住所を入力してください' : formatPrice(taxiFare)}</td>
              </tr>
              {campaignValue !== 0 && (
                <tr>
                  <td />
                  <td>{campaignName}</td>
                  <td>
                    -{' '}
                    {isNaN(campaignValue)
                      ? '配達先住所を入力してください'
                      : formatPrice(campaignValue)}
                  </td>
                </tr>
              )}
              {couponValue !== 0 && couponValue !== -1 && (
                <tr>
                  <td />
                  <td>クーポン</td>
                  <td>
                    -{' '}
                    {isNaN(couponValue) ? '配達先住所を入力してください' : formatPrice(couponValue)}
                  </td>
                </tr>
              )}
              {couponValue === -1 && (
                <tr>
                  <td />
                  <td>クーポン</td>
                  <td>このクーポンを使えません</td>
                </tr>
              )}
              {/* <tr>
                <td />
                <td colSpan={2}>店舗と配送場所の距離により料金が変わります</td>
              </tr> */}
            </tbody>
            <tfoot>
              <tr>
                <td />
                <td>合計</td>
                <td>{totalPrice ? formatPrice(totalPrice) : '計算中です'}</td>
              </tr>
            </tfoot>
          </table>
        </div>
        <Button disabled={!isSubmitEnabled || isProcessing} primary={true}>
          {I18n.t('generic.reservation')}
        </Button>
      </Form>
    </S.ReservationForm>
  )
}

const S: { [key: string]: AnyStyledComponent } = {}
S.ReservationForm = styled.div``
S.FormItem = styled.div<{ hidden?: true }>`
  display: ${({ hidden }) => (hidden ? 'none' : 'block')};
  &.disabled {
    opacity: 0.3;
    pointer-events: none;
  }
  & + & {
    margin-top: 16px;
  }
`

S.FormComment = styled.div`
  font-size: 12px;
  padding-bottom: 16px;
  text-align: right;
  color: #777;
`
S.NoImage = styled.p`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f4f4f4;
`
const ReservationNewWrapper = styled.div`
  padding: 24px 12px;

  .Panel {
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 1px 3px 0 rgba(21, 27, 38, 0.15);
    max-width: 880px;
    margin: 0 auto;
    padding: 24px;
  }

  .Panel_Section {
    margin-top: 24px;
    padding-top: 16px;
    border-top: solid 1px #eaedef;

    > h3 {
      font-size: 18px;
      margin-bottom: 20px;
    }
  }

  .ReservationNew_CompletedHeader {
    margin-bottom: 24px;
    text-align: center;
    font-size: 20px;
  }

  .ReservationNew_CompletedButtons {
    display: flex;
    justify-content: center;
  }

  .ReservationNew_Table {
    width: 100%;
    max-width: 600px;
    font-size: 15px;
    tr {
      height: 40px;
      td {
        min-width: 100px;
      }
    }
    tbody {
      border-bottom: 1px solid #eaedef;
    }
  }

  .ReservationNew_Post {
    display: flex;
    align-items: center;
  }

  .ReservationNew_Image {
    width: 200px;
    height: 160px;
    border-radius: 3px;
    overflow: hidden;

    > img {
      width: 100%;
      height: inherit;
      object-fit: cover;
    }
  }

  .ReservationNew_PostInfo {
    flex: 1;
    margin-left: 16px;
  }

  .ReservationNew_PostTitle {
    font-size: 20px;
    word-break: break-all;
  }

  .Button {
    margin-top: 24px;
  }

  .Post_Note {
    white-space: pre-wrap;
    overflow: auto;
    @media (max-width: ${BREAKPOINT_TABLET_MOBILE}px) {
      max-height: 10em;
    }
  }
`
S.Cover = styled.div<{ show: boolean }>`
  display: ${({ show }) => (show ? 'block' : 'none')};
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10000;
  background-color: rgba(255, 255, 255, 0.4);

  .Spinner {
    z-index: 20000;
  }
`

S.OptionItem = styled.ul`
  font-size: 12px;
  display: flex;
  list-style: none;
  li::after {
    content: '、';
  }
  li:first-child::before {
    content: '（';
  }
  li:last-child::after {
    content: '）';
  }
`

export default ReservationNew
