import ClassNames from 'classnames'
import * as React from 'react'
import styled, { AnyStyledComponent } from 'styled-components'
import { IOptionItem, IPostItem, IPostOption } from '../../core/interfaces'
import { COLORS } from '../../static/constants'
import { Button, FormItemLayout, InputText, Select } from '../atoms'
import { OptionItemForm } from '../organisms'
import { PostContext } from '../pages/posts/new/items'

interface IOptionFormProps {
  item: IPostItem
  postOptions: IPostOption[]
  addOptionItem: (index: number) => () => void
  errors: any
  addPostOption(): void
  removePostOption(option_index: number): void
  removeOptionItem(option_index: number, item_index: number): void
}

interface IErrors {
  [key: string]: string | null
}

const OptionForm: React.FC<IOptionFormProps> = props => {
  const { items, setItems } = React.useContext(PostContext)
  const importSelectRef = React.createRef<HTMLInputElement>()

  const importTargetItems = () => {
    const importTargets = items.filter(
      item => item.post_options.length > 0 && item !== props.item && item.id
    )
    return importTargets.map(target => {
      return { value: target.id, label: target.name }
    })
  }

  // オプションをコピー
  const importPostOption = () => {
    if (!importSelectRef.current.value) {
      return
    }
    const source_item = items.find(item => item.id === Number(importSelectRef.current.value))
    const target_item = items.find(item => item.id === props.item.id)
    if (!source_item || !target_item) {
      return
    }
    setItems(prev => {
      const newState = [...prev]
      newState.map(_item => {
        if (_item === target_item) {
          {
            /* オプション追加 */
          }
          source_item.post_options.forEach(option => {
            // オプションタイトルがかぶるようならインポートしない
            if (
              !_item.post_options.some(
                post_option => !post_option.deleted_at && post_option.title === option.title
              )
            ) {
              _item.post_options.push({
                id: undefined,
                post_item_id: undefined,
                title: option.title,
                description: option.description,
                is_required: option.is_required,
                is_multiple: option.is_multiple,
                option_items: option.option_items.map(option_item => {
                  return {
                    id: undefined,
                    post_option_id: undefined,
                    title: option_item.title,
                    price: option_item.price,
                    is_quantity_limited: option_item.is_quantity_limited,
                    quantity: option_item.quantity,
                    total_ordered_count: 0,
                  }
                }),
              })
            }
          })
        }
      })
      return newState
    })
  }

  const stateRequiredOptions = [
    { value: 'true', label: '必須' },
    { value: 'false', label: '任意' },
  ]

  const stateMultipleOptions = [
    { value: 'true', label: '複数' },
    { value: 'false', label: '単一' },
  ]

  return (
    <S.Inner>
      <S.FormItem>
        <S.Title className="FormLabel">メニューオプション設定</S.Title>
        <S.Title className="FormLabel">メニューオプションインポート</S.Title>
        <S.OptionImportWrapper>
          <Select
            name={`importTargetItems`}
            initialText={`インポート対象のアイテムを選択`}
            options={importTargetItems()}
            selectRef={importSelectRef}
          />
          <Button small={true} handleClick={() => importPostOption()}>
            オプション設定をインポート
          </Button>
        </S.OptionImportWrapper>
      </S.FormItem>
      {props.postOptions.map((option, index) => {
        return (
          <div key={index} style={{ display: `${option.deleted_at ? 'none' : 'block'}` }}>
            <S.OptionFormWrapper>
              <input
                type="hidden"
                id={`post_options_attributes[${index}][id]`}
                name={`post_options_attributes[${index}][id]`}
                readOnly={true}
                value={option.id}
              />
              <input
                type="hidden"
                id={`post_options_attributes[${index}][post_item_id]`}
                name={`post_options_attributes[${index}][post_item_id]`}
                readOnly={true}
                value={props.item.id}
              />
              <input
                type="hidden"
                id={`post_options_attributes[${index}][deleted_at]`}
                name={`post_options_attributes[${index}][deleted_at]`}
                readOnly={true}
                value={option.deleted_at ?? ''}
              />
              <S.ButtonWrap>
                <S.Title className="FormLabel">{`メニューオプション${index + 1}`}</S.Title>
                <Button small={true} handleClick={() => props.removePostOption(index)}>
                  オプション削除
                </Button>
              </S.ButtonWrap>
              <S.FormItem>
                <InputText
                  required={option.deleted_at ? false : true}
                  name={`post_options_attributes[${index}][title]`}
                  label={`オプション名${index + 1}`}
                  defaultValue={option.title}
                  error={props.errors[`post_options_attributes[${index}][title]`]}
                />
              </S.FormItem>
              <S.FormItem>
                <InputText
                  required={option.deleted_at ? false : true}
                  name={`post_options_attributes[${index}][description]`}
                  label={`オプション説明${index + 1}`}
                  defaultValue={option.description}
                  error={props.errors[`post_options_attributes[${index}][description]`]}
                />
              </S.FormItem>
              <S.FormItem>
                <Select
                  required={option.deleted_at ? false : true}
                  name={`post_options_attributes[${index}][is_required]`}
                  label={`任意,必須${index + 1}`}
                  options={stateRequiredOptions}
                  defaultValue={option.is_required.toString()}
                />
              </S.FormItem>
              <S.FormItem>
                <Select
                  required={option.deleted_at ? false : true}
                  name={`post_options_attributes[${index}][is_multiple]`}
                  label={`単一,複数${index + 1}`}
                  options={stateMultipleOptions}
                  defaultValue={option.is_multiple.toString()}
                />
              </S.FormItem>
              <OptionItemForm
                index={index}
                addOptionItem={props.addOptionItem(index)}
                removeOptionItem={props.removeOptionItem}
                optionItems={option.option_items}
                errors={props.errors}
              />
            </S.OptionFormWrapper>
          </div>
        )
      })}
      <Button small={true} handleClick={props.addPostOption}>
        オプション追加
      </Button>
    </S.Inner>
  )
}

const S: { [key: string]: AnyStyledComponent } = {}

S.Inner = styled.div`
  margin: 16px 0px;
  padding: 16px 0px;
  border-top: solid 1px ${COLORS.Border};
`
S.ButtonWrap = styled.div`
  margin: 16px 0px;
  display: flex;
  align-items: center;
`

S.FormItem = styled.div`
  &.disabled {
    opacity: 0.3;
    pointer-events: none;
  }
  & + & {
    margin-top: 16px;
  }
`

S.Title = styled.p`
  width: 240px;
  font-weight: bold;
  padding-bottom: 16px;
`

S.OptionImportWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 16px;
  button {
    margin-left: 24px;
  }
`

S.OptionFormWrapper = styled.div`
  .FormItem {
    width: 100%;
  }
  .FormLabel {
    padding-bottom: 0px;
    padding-left: 16px;
    font-size: 15px;
    font-weight: bold;
  }
`

S.Hidden = styled.div`
  display: none;
`

export default OptionForm
