import * as React from 'react'
import styled, { AnyStyledComponent } from 'styled-components'
import I18n from '../../../core/i18n'
import { IWindow } from '../../../core/interfaces'
import { IJsonResponse } from '../../../core/JsonApiSerializer'
import { userService } from '../../../core/services'
import { COLORS, ROLES, THEME_COLOR_VARIABLE_NAME } from '../../../static/constants'
import { setNativeValue } from '../../../utils/form'
import injectGoogleMaps from '../../../utils/injectGoogleMaps'
import { Button, InputText, Panel } from '../../atoms'
import { Form } from '../../molecules'
import AddressForm, {
  ADDRESS_FIELDS,
  emptyToNull,
  IAddressFormMethods,
} from '../../organisms/AddressForm'
import CropperUploader from '../../organisms/CropperUploader'
import SettingLayoutIndex from '../settings/_layouts/'
import { ActiveTypes } from '../settings/Nav'

declare var window: IWindow

interface IProps {
  user: IJsonResponse
  active_path: ActiveTypes
}

const FORM_USER_FIELDS = {
  username: 'username',
}

const FIELDS = {
  ...FORM_USER_FIELDS,
  ...ADDRESS_FIELDS,
}

const ProfileEdit: React.FC<IProps> = props => {
  const { user: initialUser } = userService.getUserFromJson(props.user)
  const [user, setUser] = React.useState(initialUser)
  const [isGuest, setIsGuest] = React.useState(initialUser)
  const [showModal, setShowModal] = React.useState(false)
  const [cropping, setCropping] = React.useState(false)
  const [previewImage, setPreviewImage] = React.useState({
    file: user.file,
    url: user.avatar_url,
  })
  const [isSubmitEnabled, setIsSubmitEnabled] = React.useState(false)
  const [errors, setErrors] = React.useState<any>({})

  const checkIfGuest = React.useRef(isGuest)
  React.useEffect(() => {
    setIsGuest(user.role.id === ROLES[0].value)
    checkIfGuest.current = user.role.id === ROLES[0].value
  }, [user])

  const imageToUpload = React.useRef(previewImage)
  React.useEffect(() => {
    imageToUpload.current = previewImage
  }, [previewImage])

  const handleSubmit = async (initialValues, values) => {
    if (checkIfGuest.current && (!values.latitude1 || !values.longitude1)) {
      window.flashMessages.addMessage({
        text: '住所が誤っている可能性があります。再入力してください。',
        type: 'error',
      })

      return
    }
    const formData = new FormData()

    // プロフィール関連の値
    Object.keys(FORM_USER_FIELDS).forEach(key => {
      formData.append(`user[${key}]`, emptyToNull(key, values[key]))
    })

    // 住所関連の値
    Object.keys(ADDRESS_FIELDS).forEach(key => {
      formData.append(`address[${key}]`, emptyToNull(key, values[key]))
    })

    if (imageToUpload.current && imageToUpload.current.file !== undefined) {
      formData.append('user[avatar]', imageToUpload.current.file)
    }

    const { updatedUser, flush } = await userService.updateProfile(formData)

    setUser(updatedUser)
    window.flashMessages.addMessage({ text: flush.message, type: flush.type })
  }

  const setCroppedImage = croppedCanvas => {
    croppedCanvas.toBlob(blob => {
      setCropping(false)
      setPreviewImage({
        file: blob,
        url: croppedCanvas.toDataURL(),
      })
    }, 'image/*')
  }

  const handleUpdateForm = (updatedErrors, updatedIsSubmitEnabled, values) => {
    setErrors(updatedErrors)
    setIsSubmitEnabled(updatedIsSubmitEnabled)
  }

  const onChangeHandle = event => {
    setPreviewImage({
      file: event.currentTarget.files[0],
      url: URL.createObjectURL(event.currentTarget.files[0]),
    })
  }

  return (
    <SettingLayoutIndex
      activePath={props.active_path}
      main={
        <S.Main>
          <Panel title={I18n.t('generic.profile')}>
            <Form
              fields={user.admin ? FORM_USER_FIELDS : FIELDS}
              handleSubmit={handleSubmit}
              handleUpdateForm={handleUpdateForm}
            >
              <S.Avator>
                <img src={previewImage ? previewImage.url : user.avatar_url} />
                <p>{user.username}</p>
              </S.Avator>
              <S.Uploaders>
                <Button handleClick={() => setShowModal(true)}>
                  {I18n.t('generic.select_image')}
                </Button>
              </S.Uploaders>
              <S.FormItem>
                <InputText
                  required={true}
                  name="username"
                  label={isGuest ? I18n.t('generic.name_kana') : I18n.t('generic.name')}
                  defaultValue={user.username}
                  error={errors.username}
                  pattern={isGuest ? '^[ァ-ヶ ー　]+$' : null}
                />
              </S.FormItem>
                <S.Address>
                  <AddressForm
                    defaultAddress={user.address}
                    errors={errors}
                    phoneRequired={isGuest}
                    isGuest={isGuest}
                  />
                </S.Address>
              <Button primary={true} disabled={!isSubmitEnabled}>
                {I18n.t('generic.save')}
              </Button>
            </Form>
          </Panel>

          {showModal && (
            <S.Modal>
              <S.ModalContainer>
                <S.ModalTitle>{I18n.t('settings.avatar_image')}</S.ModalTitle>
                <S.Cropper>
                  {cropping ? (
                    <CropperUploader
                      imageFile={previewImage.url}
                      setCroppedImage={setCroppedImage}
                      cancelCrop={() => setCropping(false)}
                    />
                  ) : (
                    <S.CurrentAvator>
                      <img src={previewImage ? previewImage.url : user.avatar_url} />
                      <S.ImageSelect>
                        <label htmlFor="avatarFile">
                          {I18n.t('generic.select_image')}
                          <input
                            id="avatarFile"
                            type="file"
                            accept="image/png, image/jpeg"
                            onChange={onChangeHandle}
                          />
                        </label>
                        {previewImage && (
                          <S.TrimmingButton type="button" onClick={() => setCropping(true)}>
                            {I18n.t('generic.trimming')}
                          </S.TrimmingButton>
                        )}
                      </S.ImageSelect>
                    </S.CurrentAvator>
                  )}
                </S.Cropper>
                <S.ModalButtons>
                  <Button
                    handleClick={() => {
                      setPreviewImage(null)
                      setShowModal(false)
                    }}
                  >
                    {I18n.t('generic.cancel')}
                  </Button>
                  <Button
                    primary={true}
                    handleClick={() => {
                      setShowModal(false)
                    }}
                  >
                    {I18n.t('generic.ok')}
                  </Button>
                </S.ModalButtons>
              </S.ModalContainer>
            </S.Modal>
          )}
        </S.Main>
      }
    />
  )
}

const S: { [key: string]: AnyStyledComponent } = {}

S.Main = styled.div`
  .Panel + .Panel {
    margin-top: 24px;
  }
  label {
    cursor: pointer;
    color: var(${THEME_COLOR_VARIABLE_NAME});
    border: solid 1px var(${THEME_COLOR_VARIABLE_NAME});
    border-radius: 3px;
    padding: 4px 8px;
    font-size: 14px;
  }

  input[type='file'] {
    display: none;
  }
`
S.AuthentificationList = styled.ul`
  li {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`
S.AuthentificationListState = styled.div`
  display: flex;
  align-items: center;
  > span {
    font-size: 14px;
    color: #888;
  }
  > i {
    margin-left: 8px;
  }
  > i._authenticated {
    color: ${COLORS.Success};
  }
  > i._unauthenticated {
    color: ${COLORS.Danger};
  }
`

S.FormItem = styled.div<{ hidden?: true }>`
  display: ${({ hidden }) => (hidden ? 'none' : 'block')};
  &.disabled {
    opacity: 0.3;
    pointer-events: none;
  }
  & + & {
    margin-top: 16px;
  }
`
S.Address = styled.div<{ isAdmin: boolean }>`
  margin-top: 16px;
`

S.Uploaders = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 12px 0 32px;
`

S.Avator = styled.div`
  > img {
    display: block;
    width: 80px;
    height: 80px;
    margin: 0 auto;
    border-radius: 40px;
    object-fit: cover;
  }

  > p {
    text-align: center;
    margin: 8px 0 0;
    line-height: 1;
  }
`

S.Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(34, 43, 55, 0.6);
  z-index: 6000;
`
S.ModalContainer = styled.div`
  width: 680px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
  border-radius: 6px;
  background-color: #fff;
  color: #24282a;
`
S.ModalTitle = styled.p`
  padding: 16px;
  font-weight: bold;
  border-bottom: 1px solid ${COLORS.Border};
`
S.ModalButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 16px;
`
S.TrimmingButton = styled.button`
  padding: 0 4px;
  text-decoration: underline;
  border: none;
  color: #3273dc;
  font-size: 14px;
`

S.Cropper = styled.div`
  display: flex;
  justify-content: center;
  height: 250px;
  padding: 16px;
`

S.CurrentAvator = styled.div`
  width: 100%;
  height: 100%;
  text-align: center;

  > img {
    height: calc(100% - 36px);
    padding: 4px;
    border: solid 1px ${COLORS.Border};
  }

  label {
    cursor: pointer;
    color: var(${THEME_COLOR_VARIABLE_NAME});
    border: solid 1px var(${THEME_COLOR_VARIABLE_NAME});
    border-radius: 3px;
    padding: 4px 8px;
    font-size: 14px;
  }

  input[type='file'] {
    display: none;
  }
`

S.ImageSelect = styled.div`
  margin-top: 12px;
`

S.Map = styled.div`
  height: 300px;
  margin-top: 32px;
  margin-bottom: 32px;

  #map {
    height: 100%;
    width: 100%;
  }
`

export default injectGoogleMaps(ProfileEdit)
