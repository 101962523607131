import { AxiosInstance } from 'axios'
import { IJsonApiSerializer } from '../JsonApiSerializer'

class PostItemService {
  public httpClient: AxiosInstance
  public serializer: IJsonApiSerializer

  constructor(httpClient: AxiosInstance, serializer: IJsonApiSerializer) {
    this.httpClient = httpClient
    this.serializer = serializer
  }

  public async create(item, image, postId) {
    const formData = new FormData()
    if (image?.file) {
      formData.append('post_item[image]', image.file)
    }
    Object.keys(item).forEach(key => {
      formData.append(`post_item[${key}]`, item[key])
    })
    const { data } = await this.httpClient.post(`/api/posts/${postId}/post_items`, formData)
    const { edit_post_path } = data
    const postItem = this.serializer.parseResourceData(data.post_item, data.post_item.data)
    return { postItem, edit_post_path }
  }

  public async update(item, image, postId) {
    const formData = new FormData()
    if (image?.file) {
      formData.append('post_item[image]', image.file)
    }
    Object.keys(item).forEach(key => {
      formData.append(`post_item[${key}]`, item[key])
    })

    const { data } = await this.httpClient.patch(
      `/api/posts/${postId}/post_items/${item.id}`,
      formData
    )
    const { flush } = data
    const postItem = this.serializer.parseResourceData(data.post_item, data.post_item.data)
    const { edit_post_path } = data
    return { edit_post_path, flush, postItem }
  }

  public async delete(itemId, postId) {
    const { data } = await this.httpClient.delete(`/api/posts/${postId}/post_items/${itemId}`)
    const { flush, post_item } = data

    return { flush, post_item }
  }

  public async close(itemId, postId) {
    const { data } = await this.httpClient.put(`/api/posts/${postId}/post_items/${itemId}/close`)
    const { flush, post_item } = data

    return { flush, post_item }
  }

  public async publish(itemId, postId) {
    const { data } = await this.httpClient.put(`/api/posts/${postId}/post_items/${itemId}/publish`)
    const { flush, post_item } = data

    return { flush, post_item }
  }

  public async suspend(itemId, postId) {
    const { data } = await this.httpClient.put(`/api/posts/${postId}/post_items/${itemId}/suspend`)
    const { flush, post_item } = data

    return { flush, post_item }
  }

  public async update_order(itemIds, postId) {
    const { data } = await this.httpClient.put(
      `/api/posts/${postId}/post_items/${itemIds}/update_order`
    )
    const { flush, post } = data

    return { flush, post }
  }
}

export default PostItemService
