import React from 'react'
import styled, { AnyStyledComponent } from 'styled-components'
import I18n from '../../../core/i18n'
import {
  BREAKPOINT_DESKTOP,
  BREAKPOINT_TABLET_MOBILE,
  COLORS,
  THEME_COLOR_VARIABLE_NAME,
} from '../../../static/constants'
import Button from '../../atoms/Button'

const ENTER_KEYCODE = 13

interface IProps {
  getCurrentPosition: () => void
}
const Search: React.FC<IProps> = props => {
  const urlParams = new URLSearchParams(window.location.search)
  const defaultKeywords = urlParams.get('keywords') || ''

  const onSearchHandler = async event => {
    const currentUrlParams = new URLSearchParams(window.location.search)

    if (event.target.value) {
      currentUrlParams.set(event.target.name, event.target.value)
    } else {
      currentUrlParams.delete(event.target.name)
    }

    location.href = `/?${currentUrlParams.toString()}`
  }

  const onKeyDownHandler = event => {
    if (event.keyCode && event.keyCode === ENTER_KEYCODE) {
      onSearchHandler(event)
    }
  }
  if (window.location.search === '') {
    return null
  }
  return (
    <S.SearchContainer>
      {/* <S.Search>
        <div className="Search_Input _keywords">
          <input
            name="keywords"
            type="text"
            placeholder={I18n.t('generic.keyword')}
            defaultValue={defaultKeywords}
            onBlur={onSearchHandler}
            onKeyDown={onKeyDownHandler}
          />
          <i className="material-icons">search</i>
        </div>
      </S.Search> */}
      <div className="GetCurrentPosition_Button">
        <Button handleClick={props.getCurrentPosition}>
          <i className="material-icons">my_location</i>
        </Button>
      </div>
    </S.SearchContainer>
  )
}

const S: { [k: string]: AnyStyledComponent } = {}

S.SearchContainer = styled.div`
  position: relative;
  display: none;
  justify-content: center;
  align-items: center;
  height: 56px;
  padding: 10px 24px;
  border-bottom: solid 1px ${COLORS.Border};
  .GetCurrentPosition_Button {
    position: absolute;
    z-index: 2;
    background: #fff;
    top: 400px;
    right: 10px;
    .Button {
      height: 40px;
      box-shadow: rgb(0 0 0 / 30%) 0px 1px 4px -1px;
      padding: 8px;
      outline: none;
      cursor: pointer;
      line-height: 1;
      font-size: 14px;
      background-color: rgba(255, 255, 255);
      color: #666;
      border-radius: 2px;
      border: none;
    }
  }

  @media (max-width: ${BREAKPOINT_DESKTOP}px) {
    overflow-x: auto;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
    ::-webkit-scrollbar {
      display: none;
    }
  }
  @media (max-width: ${BREAKPOINT_TABLET_MOBILE}px) {
    height: auto;
    padding: 6px 12px;
    .GetCurrentPosition_Button {
      display: none;
    }
  }
`

S.Search = styled.div`
  display: flex;
  position: relative;
  margin-right: 14px;

  .Search_Input {
    position: relative;
    display: flex;
    align-items: center;
    &._area {
      margin-right: 24px;
      @media (max-width: ${BREAKPOINT_TABLET_MOBILE}px) {
        width: 96px;
        margin-right: 12px;
      }
    }
    &._time {
      width: 154px;
      margin-right: 24px;
      @media (max-width: ${BREAKPOINT_TABLET_MOBILE}px) {
        width: 127px;
        margin-right: 12px;
      }
    }
    &._keywords {
      @media (max-width: ${BREAKPOINT_TABLET_MOBILE}px) {
        width: 140px;
      }
    }

    > i {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 8px;
      margin: auto;
      width: 24px;
      height: 24px;
    }
    > input,
    select {
      display: block;
      width: 100%;
      height: 43px;
      border: solid 1px ${COLORS.Border};
      font-size: 15px;
      transition: border 0.2s ease;
      outline: none;
      background: none;
    }
    > select {
      appearance: button;
      background-color: white;
      padding: 0 12px;
      > .time_placeholder {
        color: #babdbf;
      }
    }
    > input {
      padding: 0 12px 0 36px;

      ::placeholder {
        color: #babdbf;
      }

      :focus {
        border: solid 1px var(${THEME_COLOR_VARIABLE_NAME});
      }
    }
  }
`

export default Search
