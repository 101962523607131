import * as React from 'react'
import I18n from '../../../../core/i18n'
import { adminService } from '../../../../core/services'
import STATE_CODES from '../../../../static/stateCodes'
import { InputText, Select } from '../../../atoms'
import AdminLayoutNew from '../_layouts/new'

interface INotificationOption {
  notify_to_email: boolean
  notify_to_fax: boolean
}

const FIELDS = {
  name: 'name',
  email: 'email',
  phone: 'phone',
  fax: 'fax',
  postal_code1: 'postal_code1',
  city1: 'city1',
  state1: 'state1',
  other1_1: 'other1_1',
  other1_2: 'other1_2',
  notification_option: 'notification_option',
  notify_url: 'notify_url',
}
const keys_of_address = ['postal_code1', 'city1', 'state1', 'other1_1', 'other1_2', 'fax', 'phone']
const ignore_keys = ['notification_option']

const title = I18n.t('admin.create_new', {
  model: I18n.t('taxi', { scope: 'activerecord.models' }),
})
const notificationValues = {
  fax: {
    notify_to_email: false,
    notify_to_fax: true,
  },
  email: {
    notify_to_email: true,
    notify_to_fax: false,
  },
  both: {
    notify_to_email: true,
    notify_to_fax: true,
  },
}

const AdminCategoryNew: React.FC<{}> = props => {
  const [selectedNotificationOption, setSelectedNotificationOption] = React.useState<
    INotificationOption
  >(notificationValues.both)

  const stateOptions = [
    { value: '', label: '-' },
    ...STATE_CODES.map(stateName => ({
      value: stateName,
      label: stateName,
    })),
  ]

  const notificationOptions = [
    { value: '', label: '-' },
    { value: 'fax', label: 'FAX' },
    { value: 'email', label: 'メール' },
    { value: 'both', label: 'FAXとメール' },
  ]

  const changeNotificationOption = event => {
    const value = notificationValues[event.target.value]
    if (value) {
      setSelectedNotificationOption(value)
    }
  }

  const handleSubmit = React.useCallback(async (initialValues, values) => {
    const taxiParam = { ...notificationValues[values.notification_option] }
    const addressParam = {}
    Object.keys(values).forEach(key => {
      const value = values[key]
      if (ignore_keys.indexOf(key) !== 0) {
        if (keys_of_address.indexOf(key) === -1) {
          taxiParam[key] = value
        } else {
          addressParam[key] = value
        }
      }
    })

    const { createdTaxi, flush } = await adminService.createTaxi({
      taxi: taxiParam,
      address: addressParam,
    })
    location.href = '/admin/taxies'
  }, [])

  return (
    <AdminLayoutNew
      model="taxi"
      title={title}
      fields={FIELDS}
      indexLink="/admin/taxies"
      formItems={
        <>
          <InputText
            required={true}
            name="name"
            defaultValue=""
            label={I18n.t('generic.company_name')}
          />
          <InputText required={true} name="phone" defaultValue="" label={I18n.t('generic.phone')} />
          <Select
            required={true}
            name="notification_option"
            label={I18n.t('generic.notification')}
            options={notificationOptions}
            defaultValue={''}
            onChangeHandler={event => changeNotificationOption(event)}
          />
          <InputText
            required={selectedNotificationOption.notify_to_email}
            name="email"
            defaultValue={''}
            label={I18n.t('generic.email')}
          />
          <InputText
            required={selectedNotificationOption.notify_to_fax}
            name="fax"
            defaultValue={''}
            label={I18n.t('generic.fax')}
          />
          <InputText
            required={true}
            name="postal_code1"
            defaultValue=""
            label={I18n.t('generic.zipcode')}
          />
          <Select
            required={true}
            name="state1"
            label={I18n.t('generic.prefectures')}
            options={stateOptions}
            defaultValue=""
          />
          <InputText required={true} name="city1" defaultValue="" label={I18n.t('generic.city')} />
          <InputText
            required={false}
            name="other1_1"
            defaultValue=""
            label={I18n.t('generic.street1')}
          />
          <InputText
            required={false}
            name="other1_2"
            defaultValue=""
            label={I18n.t('generic.street2')}
          />
          <InputText
            required={false}
            name="notify_url"
            defaultValue=""
            label={I18n.t('generic.notify_url')}
          />
        </>
      }
      handleSubmit={handleSubmit}
    />
  )
}

export default AdminCategoryNew
