import { CustomMarker } from 'components/atoms'
import { getMergedFilterParams, getMergedFilterQueryString } from 'core/queryString'
import { isEqual, once } from 'lodash'
import React from 'react'
import { postService } from '../../../core/services'

export const urlParams = new URLSearchParams(window.location.search)
const MAP_PADDING = 32
const MAP_DEFAULT_ZOOM = 11
const FILTER_FIELDS = [
  'area',
  'start_date',
  'end_date',
  'min_price',
  'max_price',
  'category_ids',
  'tag_ids',
]
const mapOptions: any = {
  zoom: MAP_DEFAULT_ZOOM,
  mapTypeId: 'roadmap',
  mapTypeControl: false,
  streetViewControl: false,
  fullscreenControl: false,
  scrollwheel: false,
  clickableIcons: false,
}

function _getMapBounds(googleMap) {
  const { north, south, east, west } = googleMap.getBounds().toJSON()
  const bounds = {
    northEast: { lat: north, lng: east },
    southWest: { lat: south, lng: west },
  }
  return bounds
}

const _useMap = ({ favorite, posts, resetPosts, updatePostsByMapBounds }) => {
  const [map, setMap] = React.useState(null)
  React.useEffect(() => {
    if (google) {
      initializeMap()
    }
  }, [google])

  const initializeMap = () => {
    const areaParam = urlParams.get('area')
    const latParam = urlParams.get('lat')
    const lngParam = urlParams.get('lng')
    const userLatParam = urlParams.get('user_lat')
    const userLngParam = urlParams.get('user_lng')
    const zoomParam = urlParams.get('zoom')
    const radiusParam = urlParams.get('radius')

    if (userLatParam && userLngParam) {
      mapOptions.center = {
        lat: parseFloat(userLatParam),
        lng: parseFloat(userLngParam),
      }
    } else if (latParam && lngParam) {
      mapOptions.center = {
        lat: parseFloat(latParam),
        lng: parseFloat(lngParam),
      }
    }
    if (zoomParam) {
      mapOptions.zoom = parseInt(zoomParam, 10)
    }

    const initializedMap = new google.maps.Map(document.getElementById('map'), mapOptions)

    if (userLatParam && userLngParam) {
      new google.maps.Marker({
        map: initializedMap,
        position: new google.maps.LatLng(parseFloat(userLatParam), parseFloat(userLngParam)),
      })
    }

    if (latParam && lngParam) {
      // Mapの初期化後, クエリパラメータで取得したlat, lngのboundsの範囲にあるpostsを返却する
      initializedMap.addListener(
        'bounds_changed',
        once(() => updatePostsByMapBounds(initializedMap))
      )
      new google.maps.Circle({
        center: {
          lat: parseFloat(latParam),
          lng: parseFloat(lngParam),
        },
        fillColor: '#fe3',
        fillOpacity: 0.1,
        map: initializedMap,
        radius: radiusParam ? parseFloat(radiusParam) * 1000 : 3000,
        strokeColor: '#ffa500',
        strokeOpacity: 1,
        strokeWeight: 1,
      })
    } else if (areaParam !== null) {
      const geocoder = new google.maps.Geocoder()

      geocoder.geocode({ address: areaParam }, (results, status) => {
        if (status === google.maps.GeocoderStatus.OK) {
          const location = results[0].geometry.location
          initializedMap.panTo({ lat: location.lat(), lng: location.lng() })
          updatePostsByMapBounds(initializedMap)
        } else if (status === google.maps.GeocoderStatus.ZERO_RESULTS) {
          resetPosts()
        }
      })
    } else {
      if (posts.length === 0) {
        // 東京駅
        initializedMap.panTo({ lat: 35.68155, lng: 139.767178 })
      } else {
        // 取得したPostsが収まる範囲にfitBounds, お気に入り、キーワード検索で使用
        const latitudes = posts.map(post => post.address.latitude1)
        const longitudes = posts.map(post => post.address.longitude1)
        const minPosition = [Math.min.apply(null, latitudes), Math.min.apply(null, longitudes)]
        const maxPosition = [Math.max.apply(null, latitudes), Math.max.apply(null, longitudes)]

        if (posts.length === 1) {
          initializedMap.panTo({ lat: minPosition[0], lng: minPosition[1] })
        } else {
          const latLngBounds = new google.maps.LatLngBounds(
            new google.maps.LatLng(minPosition[0], minPosition[1]),
            new google.maps.LatLng(maxPosition[0], maxPosition[1])
          )
          initializedMap.fitBounds(latLngBounds, MAP_PADDING)
        }
      }
    }

    if (!favorite) {
      initializedMap.addListener('dragend', () => updatePostsByMapBounds(initializedMap))
      initializedMap.addListener('zoom_changed', () => updatePostsByMapBounds(initializedMap))
    }

    setMap(initializedMap)
  }
  return { map }
}

export const useMarkers = ({ map, posts, toggleLike }) => {
  const [markers, setMarkers] = React.useState([])

  const getMarkerPositions = updatedPosts => {
    const markerPositions = []

    for (const post of updatedPosts) {
      const latLng = new google.maps.LatLng(post.address.latitude1, post.address.longitude1)
      markerPositions.push(latLng)
    }

    return markerPositions
  }

  const setActiveMarker = (activeMarker, currentMarkers) => {
    // console.log('activeMarker', markers, activeMarker)
    if (activeMarker === -1) {
      // マップをクリック時に既に開いているマーカー詳細を閉じる
      currentMarkers.forEach(marker => {
        if (marker.div) {
          marker.closeDetail()
        }
      })
    } else {
      // マーカーをクリック時に既に開いているマーカー詳細を閉じる
      currentMarkers.forEach(marker => {
        if (marker.getMarkerId() !== activeMarker) {
          marker.closeDetail()
        }
      })
    }
  }

  const setCustomMarkers = React.useCallback(
    (googleMap, updatedPosts) => {
      if (!googleMap) {
        return
      }

      // Update like
      markers.forEach(marker => {
        const post = updatedPosts.find(item => marker.args?.post?.id === item.id)
        if (post && post.user_liked !== marker.liked) {
          marker.liked = post.user_liked
        }
      })

      if (
        isEqual(
          markers.map(marker => marker.markerId),
          updatedPosts.map(post => post.id)
        )
      ) {
        return
      }

      // Remove markers
      markers.forEach(marker => marker.setMap(null))

      const markerPositions = getMarkerPositions(updatedPosts)
      const updatedMarkers = updatedPosts.map((post, index) =>
        CustomMarker(google.maps)(markerPositions[index], googleMap, {
          post,
          toggleLike,
          setActiveMarker: markerId => setActiveMarker(markerId, updatedMarkers),
        })
      )
      setMarkers(updatedMarkers)

      googleMap.addListener('click', () => {
        updatedMarkers.forEach(marker => {
          if (marker.div) {
            marker.closeDetail()
          }
        })
      })

      return updatedMarkers
    },
    [map, posts]
  )

  const handleOnMouseEnter = React.useCallback(
    markerId => {
      markers.forEach(marker => {
        if (markerId === marker.markerId) {
          marker.handleOnMouseEnter()
        }
      })
    },
    [markers]
  )

  const handleOnMouseLeave = () => {
    markers.forEach(marker => marker.handleOnMouseLeave())
  }

  React.useEffect(() => {
    setCustomMarkers(map, posts)
  }, [map, posts])

  return { markers, handleOnMouseEnter, handleOnMouseLeave }
}

const _searchPost = async baseParams => {
  const keywords = urlParams.get('keywords')
  const params = { ...baseParams }

  if (keywords) {
    params.keywords = keywords
  }

  return postService.search(params)
}

export const usePosts = ({ favorite, initialPosts = [], initialPagination = null }) => {
  const keyword = urlParams.get('keywords')
  const categoryIds = urlParams.get('category_ids[]')
  const [loading, setLoading] = React.useState(favorite || keyword || categoryIds ? false : true)
  const [posts, setPosts] = React.useState(initialPosts)
  const [pagination, setPagination] = React.useState(initialPagination)
  // Map非表示に、表示されてたときのboundsを保持しておく
  const [currentMapBounds, setCurrentMapBounds] = React.useState(null)
  const [showMap, setShowMap] = React.useState(true)

  const resetPosts = React.useCallback(() => {
    setPosts([])
    setPagination(null)
    setLoading(false)
  }, [])

  const updatePostsByMapBounds = React.useCallback(async googleMap => {
    if (!googleMap.getBounds()) {
      return
    }
    setLoading(true)
    const filterParams = getMergedFilterParams(FILTER_FIELDS)
    const bounds = _getMapBounds(googleMap)
    const params = { bounds, ...filterParams }
    const result = await _searchPost(params)

    setPosts(result.posts)
    setPagination(result.pagination)
    setLoading(false)
  }, [])

  const { map } = _useMap({
    favorite,
    posts,
    resetPosts,
    updatePostsByMapBounds,
  })

  const toggleShowMap = React.useCallback(
    (show: boolean) => {
      if (!show && map) {
        const bounds = _getMapBounds(map)
        setCurrentMapBounds(bounds)
      } else {
        setCurrentMapBounds(null)
      }
      setShowMap(show)
    },
    [map]
  )

  const getPostsByPage = React.useCallback(
    async page => {
      setLoading(true)
      const bounds = currentMapBounds ? currentMapBounds : _getMapBounds(map)
      const result = favorite
        ? await postService.getFavorites({ page })
        : await _searchPost({ bounds, page })

      setPosts(result.posts)
      setPagination(result.pagination)
      setLoading(false)
    },
    [map, currentMapBounds]
  )

  const handleOnFilterSubmit = React.useCallback(
    async filterValue => {
      setLoading(true)

      if (!map) {
        return
      }

      const bounds = _getMapBounds(map)
      const filterParams = getMergedFilterParams(FILTER_FIELDS, filterValue)
      const filterQueryString = getMergedFilterQueryString(FILTER_FIELDS, filterParams)
      const result = await _searchPost({ bounds, ...filterParams })

      setPosts(result.posts)
      setPagination(result.pagination)
      setLoading(false)
      history.replaceState(null, null, `?${filterQueryString}`)
    },
    [map]
  )

  const toggleLike = (postId, like) => {
    const detailMarker = document.getElementById(`marker-detail-${postId}`)

    // Update like of the opened detail marker
    if (detailMarker) {
      const el = detailMarker.querySelector('.Marker_Favorite')

      if (!el) {
        return
      }

      if (like) {
        el.classList.add('liked')
      } else {
        el.classList.remove('liked')
      }
    }

    setPosts(currentPosts =>
      currentPosts.map(postItem => ({
        ...postItem,
        user_liked: postItem.id === postId ? like : postItem.user_liked,
      }))
    )
  }

  return {
    showMap,
    toggleShowMap,
    loading,
    setLoading,
    map,
    posts,
    toggleLike,
    getPostsByPage,
    pagination,
    handleOnFilterSubmit,
  }
}
