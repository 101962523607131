import * as React from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import styled, { AnyStyledComponent } from 'styled-components'
import I18n from '../../../../../core/i18n'
import { IPost, IPostItem, IPostOption, IWindow } from '../../../../../core/interfaces'
import { postItemService, utilService } from '../../../../../core/services'
import { COLORS } from '../../../../../static/constants'
import { BREAKPOINT_TABLET_SMALL } from '../../../../../static/constants'
import { Button, Select } from '../../../../atoms'
import { FlashMessage } from '../../../../organisms'
import PostItemEdit from './edit'

declare var window: IWindow

interface IProps {
  post: IPost
  deliveryRate: number
}

const STATUS_OPTIONS = [
  {
    label: I18n.t('generic.publish'),
    value: 'published',
  },
  {
    label: I18n.t('generic.suspended'),
    value: 'suspended',
  },
  {
    label: I18n.t('generic.closed'),
    value: 'closed',
  },
  {
    label: I18n.t('generic.delete'),
    value: 'delete',
  },
]

export const PostContext = React.createContext(
  {} as {
    items: IPostItem[]
    setItems: React.Dispatch<React.SetStateAction<IPostItem[]>>
  }
)

const PostItemIndex: React.FC<IProps> = props => {
  const [items, setItems] = React.useState<IPostItem[]>(props.post.post_items)
  const [editItem, setEditItem] = React.useState<IPostItem | null>(null)
  const context = {
    items,
    setItems,
  }

  const openHTML = () => {
    const url = '/posts/' + props.post.id + '/menus'
    const win = open(url, '_blank')
    win.focus()
  }

  const handleCreateItem = (id?: string) => {
    const newItem = {
      aasm_state: '',
      aasm_state_i18n: '',
      description: '',
      id: undefined,
      name: '',
      retail_price: undefined,
      wholesale_price: undefined,
      published: true,
      quantity: undefined,
      total_ordered_count: undefined,
      is_quantity_limited: false,
      post_options: [],
    }
    setEditItem(newItem)
    setItems(prev => {
      const newState = [...prev]
      newState.push(newItem)
      return newState
    })
  }
  const handleEditItem = (itemId: number) => {
    setEditItem(items.find((item: IPostItem) => item.id === itemId))
  }

  const updateAndShowFlashMessage = (
    itemId: number,
    post_item: object,
    flush: { message: string; type: string },
    deleted?: boolean
  ) => {
    window.flashMessages.addMessage({ text: flush.message, type: flush.type })

    const { data: updatedPostItem } = utilService.getDataFromJson(post_item)
    setItems(
      deleted
        ? items.filter((item: IPostItem) => item.id !== itemId)
        : items.map((item: IPostItem) => {
            return item.id !== itemId ? item : updatedPostItem
          })
    )
  }

  const handleDelete = async (itemId: number) => {
    const { flush, post_item } = await postItemService.delete(itemId, props.post.id)
    updateAndShowFlashMessage(itemId, post_item, flush, true)
    window.globalModal.closeModal()
  }
  const showDeleteModal = (itemId: number) => {
    window.globalModal.showModal({
      title: '本当にメニューを削除しますか？',
      closeText: I18n.t('generic.cancel'),
      submitText: I18n.t('generic.delete'),
      submitDanger: true,
      handleSubmit: () => handleDelete(itemId),
    })
  }
  const handlers = {
    published: async (itemId: number) => {
      const { flush, post_item } = await postItemService.publish(itemId, props.post.id)
      updateAndShowFlashMessage(itemId, post_item, flush)
    },
    suspended: async (itemId: number) => {
      const { flush, post_item } = await postItemService.suspend(itemId, props.post.id)
      updateAndShowFlashMessage(itemId, post_item, flush)
    },
    closed: async (itemId: number) => {
      const { flush, post_item } = await postItemService.close(itemId, props.post.id)
      updateAndShowFlashMessage(itemId, post_item, flush)
    },
    error: () => {
      window.flashMessages.addMessage({ text: '変更に失敗しました', type: 'error' })
    },
  }

  const EditButtons: React.FC<{ id: number; aasm_state: string }> = ({ id, aasm_state }) => {
    const [targetState, setTargetState] = React.useState<string>(aasm_state)
    const onChangeHandle = event => {
      setTargetState(event.target.value)
    }

    const handleClickByTargetState = (state: string) => {
      if (targetState === aasm_state) {
        return
      }
      if (handlers[state]) {
        handlers[state](id)
      } else {
        handlers.error()
      }
    }
    return (
      <S.Buttons>
        <Button handleClick={() => handleEditItem(id)}>{I18n.t('generic.edit')}</Button>
        <S.StateChange>
          <h4>公開状態の変更</h4>
          <Select
            name="status"
            options={STATUS_OPTIONS}
            defaultValue={aasm_state}
            onChangeHandler={onChangeHandle}
          />

          {targetState === 'delete' && (
            <Button
              primary={true}
              backgroundColor={COLORS.Danger}
              handleClick={() => showDeleteModal(id)}
            >
              削除する
            </Button>
          )}
          {targetState !== 'delete' && (
            <Button primary={true} handleClick={() => handleClickByTargetState(targetState)}>
              {I18n.t('generic.update')}
            </Button>
          )}
        </S.StateChange>
      </S.Buttons>
    )
  }
  const handleUpdateItem = (newItem: IPostItem) => {
    const newItemArray = []
    items.forEach(item => {
      if (item.id === newItem.id) {
        newItemArray.push(newItem)
      } else {
        newItemArray.push(item)
      }
    })
    setItems(newItemArray)
  }
  const handleBack = () => {
    setEditItem(null)
  }

  const newItems = React.useRef(items)
  React.useEffect(() => {
    newItems.current = items
  }, [items])

  const saveOrder = React.useCallback(async () => {
    const itemIds = []
    newItems.current.forEach(item => {
      itemIds.unshift(item.id)
    })
    const { flush, post } = await postItemService.update_order(itemIds, props.post.id)
    updateAndShowFlashMessage(null, null, flush)
  }, [])

  const onDragEnd = result => {
    // dropped outside the list
    if (!result.destination) {
      return
    }

    let reordered_items = []
    reordered_items = reorder(items, result.source.index, result.destination.index)
    setItems(reordered_items)
  }

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)
    return result
  }

  const getItemStyle = (isDragging, draggableStyle) => ({
    background: isDragging ? 'lightgreen' : 'grey',

    // styles we need to apply on draggables
    ...draggableStyle,
  })

  const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? '#eee' : 'white',
  })

  return (
    <PostContext.Provider value={context}>
      {editItem === null ? (
        <div>
          <S.Header>
            <Button primary={true} handleClick={() => handleCreateItem()}>
              {I18n.t('meta.post.create_menu')}
            </Button>

            <Button primary={false} handleClick={() => openHTML()}>
              {I18n.t('meta.post.print_menu')}
            </Button>

            <Button primary={false} handleClick={() => saveOrder()}>
              {I18n.t('meta.post.save_order')}
            </Button>
          </S.Header>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {(providedd, snapshott) => (
                <ul
                  {...providedd.droppableProps}
                  ref={providedd.innerRef}
                  style={getListStyle(snapshott.isDraggingOver)}
                >
                  {items?.map((item: IPostItem, index: number) => (
                    <Draggable key={item.id} draggableId={'item_' + item.id} index={index}>
                      {(provided, snapshot) => (
                        <li
                          key={index}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                        >
                          <S.PostItem>
                            <S.ItemImage>
                              {item.image_url && item.image_url !== '/images/no-avatar.svg' ? (
                                <img src={item.image_url} alt="" />
                              ) : (
                                <S.NoImage>No Image</S.NoImage>
                              )}
                            </S.ItemImage>
                            <S.ItemInfo>
                              <S.ItemHeader>
                                <div className="PostItemListTitle">{item.name}</div>
                                <div>{item.aasm_state_i18n}</div>
                                <div className="PostItemListDescription">{item.description}</div>
                              </S.ItemHeader>
                              <S.ItemFooter>
                                <div>¥{item.retail_price}</div>
                                <div>
                                  {I18n.t('post.total_ordered_count')} : {item.total_ordered_count}{' '}
                                  (
                                  {item.is_quantity_limited
                                    ? '残り' + (item.quantity - item.total_ordered_count)
                                    : I18n.t('post.no_limit')}
                                  )
                                </div>
                              </S.ItemFooter>
                            </S.ItemInfo>
                            <S.ItemButtons>
                              <EditButtons {...item} />
                            </S.ItemButtons>
                          </S.PostItem>
                        </li>
                      )}
                    </Draggable>
                    // )
                  ))}
                  {providedd.placeholder}
                </ul>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      ) : (
        <PostItemEdit
          deliveryRate={props.deliveryRate}
          item={editItem}
          postId={props.post.id}
          handleBack={handleBack}
          updateItem={handleUpdateItem}
        />
      )}
    </PostContext.Provider>
  )
}

const S: { [key: string]: AnyStyledComponent } = {}
S.PostItem = styled.div`
  display: flex;
  width: 100%;
  color: rgb(33, 37, 41);
  background-color: rgb(255, 255, 255);
  padding: 16px;
  overflow: hidden;
  border-radius: 4px;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(234, 237, 239);
  border-image: initial;
  margin-bottom: 10px;
  @media (max-width: ${BREAKPOINT_TABLET_SMALL}px) {
    display: block;
  }
`
S.Header = styled.div`
  margin-bottom: 24px;
`

S.ItemImage = styled.div`
  position: relative;
  width: 200px;
  height: 120px;
  background-color: rgb(244, 244, 244);
  border-radius: 3px;
  overflow: hidden;
  > img {
    width: inherit;
    height: inherit;
    object-fit: contain;
  }
  @media (max-width: ${BREAKPOINT_TABLET_SMALL}px) {
    width: 100%;
    height: 200px;
  }
`
S.NoImage = styled.p`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f4f4f4;
`
S.ItemInfo = styled.div`
  padding-left: 16px;
  padding-right: 16px;
  display: flex;
  flex-direction: column;
  -webkit-box-pack: justify;
  justify-content: space-between;
  flex: 1 1 0%;
  @media (max-width: ${BREAKPOINT_TABLET_SMALL}px) {
    padding-left: 0px;
  }
`
S.Buttons = styled.div`
  margin-top: 8px;
  max-width: 200px;
`
S.StateChange = styled.div`
  margin-top: 8px;

  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  h4 {
    width: 100%;
  }
`

S.ItemHeader = styled.div`
  .PostItemListTitle {
    font-size: 18px;
  }
  .PostItemListDescription {
    color: rgb(153, 153, 153);
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 4px;
  }
`
S.ItemButtons = styled.div`
  display: flex;
  align-items: center;
`
S.ItemFooter = styled.div``
export default PostItemIndex
