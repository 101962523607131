import React from 'react'
import styled from 'styled-components'
import { BREAKPOINT_DESKTOP } from '../../../static/constants'

const Map: React.FC = () => (
  <MapContainer>
    <div id="map" />
  </MapContainer>
)

const MapContainer = styled.div`
  height: 500px;
  width: 100%;
  #map {
    height: 100%;
    width: 100%;
  }
`
export default Map
