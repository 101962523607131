import { AxiosInstance } from 'axios'
import { IJsonApiSerializer, IJsonResource, IJsonResponse } from '../JsonApiSerializer'

class ReservationService {
  public httpClient: AxiosInstance
  public serializer: IJsonApiSerializer

  constructor(httpClient: AxiosInstance, serializer: IJsonApiSerializer) {
    this.httpClient = httpClient
    this.serializer = serializer
  }

  public getDataFromJson(JsonData) {
    const data = this.serializer.parseResourceData(JsonData, JsonData.data)
    const pagination = { ...JsonData.meta }

    return { data, pagination }
  }

  public parseRelationships(resources: IJsonResponse, resource: IJsonResource | IJsonResource[]) {
    return this.serializer.parseResourceData(resources, resource)
  }

  public getReservationFromJson(data) {
    const reservation = this.serializer.parseResourceData(data, data.data)

    return { reservation }
  }

  public getReservationsFromJson(data) {
    const reservations = this.serializer.parseResourceData(data, data.data)
    const pagination = { ...data.meta }

    return { reservations, pagination }
  }

  public async searchReservations(params) {
    const { data } = await this.httpClient.get(`/api/reservations/search`, { params })
    const reservations = this.serializer.parseResourceData(
      data.reservations,
      data.reservations.data
    )
    const pagination = data.reservations.meta

    return { reservations, pagination }
  }

  public async createReservation(params) {
    const { data } = await this.httpClient.post(`/api/reservations`, { ...params }).catch(() => {
      return { data: null }
    })
    if (!data) {
      return {}
    }
    const { flush } = data
    const reservation = this.serializer.parseResourceData(data.reservation, data.reservation.data)

    return { reservation, flush }
  }

  public async temporaryApproveReservation(id, param) {
    const { data } = await this.httpClient.put(`/api/reservations/${id}/temporary_approve`, param)
    const { flush } = data
    const reservation = this.serializer.parseResourceData(data.reservation, data.reservation.data)

    return { reservation, flush }
  }

  public async approveReservation(id) {
    const { data } = await this.httpClient.put(`/api/reservations/${id}/approve`)
    const { flush } = data
    const reservation = this.serializer.parseResourceData(data.reservation, data.reservation.data)

    return { reservation, flush }
  }

  public async declineReservation(id) {
    const { data } = await this.httpClient.put(`/api/reservations/${id}/decline`)
    const { flush } = data
    const reservation = this.serializer.parseResourceData(data.reservation, data.reservation.data)

    return { reservation, flush }
  }

  public async cancelReservation(id) {
    const { data } = await this.httpClient.put(`/api/reservations/${id}/cancel`)
    const { flush } = data
    const reservation = this.serializer.parseResourceData(data.reservation, data.reservation.data)

    return { reservation, flush }
  }

  public async refundAmountReservation(id) {
    const { data } = await this.httpClient.get(`/api/reservations/${id}/refund_amount`)
    const { price } = data

    return { price }
  }

  public async createMessage(formData) {
    const { data } = await this.httpClient.post(`/api/messages`, formData)
    const message = this.serializer.parseResourceData(data.message, data.message.data)

    return { message }
  }

  public async createReceipt(config) {
    const { data } = await this.httpClient.post('/api/receipts', { receipt: { ...config } })
    const receipt = this.serializer.parseResourceData(data.receipt, data.receipt.data)

    return { receipt }
  }

  public async getTaxiFare(meter) {
    const { data } = await this.httpClient.get(`/api/taxi_fare?meter=${meter}`)

    return { price: data.price }
  }

  // 値段の計算をサーバー側で行うためにここでAPIを叩き計算結果を受け取る
  public getParamStringForCalcPrice(meter, coupon, items) {
    const arrayString = items.reduce((str, item, index) => {
      const query = item.cart_options
        .map((cart_option, option_index) => {
          return cart_option.cart_option_items.map((cart_option_item, option_item_index) => {
            return (
              `array[${index}][cart_options][${option_index}][cart_option_item][${option_item_index}][amount]=${cart_option_item.amount}&` +
              `array[${index}][cart_options][${option_index}][cart_option_item][${option_item_index}][id]=${cart_option_item.id}`
            )
          })
        })
        .flat()
        .join('&')
      return [
        str,
        `array[${index}][post_item_id]=${item.id}`,
        `array[${index}][quantity]=${item.amount}`,
        query,
      ].join('&')
    }, '')
    return `?meter=${meter}${arrayString}&coupon=${coupon}`
  }
  public async calcRetailPriceTotal(meter, coupon, items) {
    const paramString = this.getParamStringForCalcPrice(meter, coupon, items)
    const { data } = await this.httpClient.get(
      `/api/post_items/calc_retail_price_total${paramString}`
    )
    let couponValue = 0
    if (data.coupon_value) {
      couponValue = data.coupon_value
    }

    if (data.retail_price_total) {
      return {
        response: data.retail_price_total,
        coupon_value: couponValue,
      }
    } else {
      return {
        response: data.post_items,
        coupon_value: couponValue,
      }
    }
  }

  public async calcTaxiFareAndTotal(meter, coupon, items) {
    const paramString = this.getParamStringForCalcPrice(meter, coupon, items)
    const { data } = await this.httpClient.get(
      `/api/post_items/calc_taxi_fare_and_total${paramString}`
    )
    let couponValue = 0
    let campaignValue = 0
    let campaignName = ''
    if (data.coupon_value) {
      couponValue = data.coupon_value
    }
    if (data.campaign_value) {
      campaignValue = data.campaign_value
      campaignName = data.campaign_name
    }
    return {
      taxiFare: data.actual_taxi_fare,
      price: data.total_price,
      couponValue,
      campaignValue,
      campaignName,
    }
  }

  public async delivered(id) {
    const { data } = await this.httpClient.put(`/api/reservations/${id}/delivery/delivered`)
    const { flush } = data
    const reservation = this.serializer.parseResourceData(data.reservation, data.reservation.data)

    return { flush, reservation }
  }
}

export default ReservationService
