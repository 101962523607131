import * as Color from 'color'
import IReservationErrorItem from 'core/interfaces/IReservationErrorItem'
import * as moment from 'moment'
import * as React from 'react'
import { DateRangePicker } from 'react-dates'
import 'react-dates/initialize'
import styled, { AnyStyledComponent } from 'styled-components'
import I18n from '../../../../core/i18n'
import { ICartItem, IPost } from '../../../../core/interfaces'
import { postService } from '../../../../core/services'
import {
  BREAKPOINT_TABLET_LARGE,
  BREAKPOINT_TABLET_MOBILE,
  COLORS,
  HEADER_HEIGHT,
  HEADER_HEIGHT_MOBILE,
  PAYMENT_REQUIRED,
} from '../../../../static/constants'
import { formatReservationError } from '../../../../utils/formatAvailibilityError'
import { formatPrice } from '../../../../utils/number'
import { Button, Counter, InputText } from '../../../atoms'

// アコーディオンここから
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from 'react-accessible-accordion'

import ICartOption from 'core/interfaces/ICartOption'
import 'react-accessible-accordion/dist/fancy-example.css'
// アコーディオンここまで

interface IProps {
  post: IPost
  showReservationPanel: boolean
  isSignedIn: boolean
  userLiked: boolean
  cartItems: ICartItem[]
  reservationErrors: IReservationErrorItem[]
  totalPrice: number
  couponValue: any
  user_already_used_coupon: boolean
  ordable: boolean
  available: boolean
  updateCartItems(item, amount, options, sidebar, found?): void
  setShowReservationPanel(show: boolean): void
  createReservation(): void
  toggleLike(): void
  setCouponCode(code: string): void
}

const SideBar: React.FC<IProps> = ({
  post,
  createReservation,
  showReservationPanel,
  setShowReservationPanel,
  reservationErrors,
  isSignedIn,
  cartItems,
  totalPrice,
  ordable,
  available,
  updateCartItems,
  toggleLike,
  userLiked,
  setCouponCode,
  couponValue,
  user_already_used_coupon,
}) => {
  const isOrdable = () => {
    const today = moment()
    const after_tomorrow = moment().add(2, 'days')
    const start = post.event_flag ? moment(post.delivery_start_time) : null
    const end = post.event_flag ? moment(post.delivery_end_time) : null
    let result = 1

    if (post.event_flag && post.force_order_date) {
      if (after_tomorrow > start) {
        result = end.diff(moment().add(1, 'days'), 'days')
      }
    } else {
      if (today > start && end !== null) {
        result = end.diff(moment(), 'days')
      }
    }
    return result > 0 ? true : false
  }

  const canOrderState = post.is_open && post.aasm_state === 'published' && isOrdable()

  const CartItem: React.FC<{ item: ICartItem }> = ({ item }) => {
    const updateAmount = newVal => {
      if (item.amount !== newVal) {
        updateCartItems(item, newVal, item.cart_options, true, null)
      }
    }

    return (
      <S.Cartitem key={item.id}>
        <Accordion allowZeroExpanded={true}>
          <AccordionItem>
            <S.Close onClick={() => updateAmount(0)}>
              <i className="material-icons small">clear</i>
            </S.Close>
            {item.cart_options.length > 0 ? (
              <>
                <AccordionItemHeading>
                  <AccordionItemButton>{item.name}</AccordionItemButton>
                </AccordionItemHeading>
                {item.cart_options.map((option, index) => (
                  <AccordionItemPanel key={`${item.id}${option.id}${index}`}>
                    <S.OptionName>{option.title}</S.OptionName>
                    {option.cart_option_items.map((option_item, option_index) => (
                      <div key={`${item.id}${option_item.id}${option_index}`}>
                        <S.OptionItemName>{option_item.name}</S.OptionItemName>
                        <S.OptionItem>
                          <p>{formatPrice(option_item.price * option_item.amount)}</p>
                        </S.OptionItem>
                      </div>
                    ))}
                  </AccordionItemPanel>
                ))}
                <S.PriceSection>
                  <Counter initValue={item.amount} min={1} max={10} handleChange={updateAmount} />
                  <p>{formatPrice(calcTotalPrice(item))}</p>
                </S.PriceSection>
              </>
            ) : (
              <>
                <S.ItemName>{item.name}</S.ItemName>
                <S.PriceSection>
                  <Counter initValue={item.amount} min={1} max={10} handleChange={updateAmount} />
                  <p>{formatPrice(item.price * item.amount)}</p>
                </S.PriceSection>
              </>
            )}
          </AccordionItem>
        </Accordion>
      </S.Cartitem>
    )
  }

  const changeCouponCodeValue = event => {
    const value = event.target.value
    setCouponCode(value)
  }

  const calcTotalPrice = item => {
    let optionSumItemPrice = 0
    item.cart_options.map(cart_option => {
      cart_option.cart_option_items.map(cart_option_item => {
        optionSumItemPrice += Number(cart_option_item.price)
      })
    })
    return (item.price + optionSumItemPrice) * item.amount
  }

  return (
    <S.Sidebar show={showReservationPanel}>
      <S.Wrapper>
        <S.Panel>
          <S.Hide onClick={() => setShowReservationPanel(false)}>
            <i className="material-icons small">arrow_back</i>
          </S.Hide>
          {post.aasm_state !== 'published' && '注文受付停止中です'}
          {post.aasm_state === 'published' && !post.is_open && '受付時間外です'}
          {PAYMENT_REQUIRED && canOrderState && (
            <S.Cartitems>
              {cartItems.length > 0 ? (
                cartItems.map((item, index) => {
                  return <CartItem key={index} item={item} />
                })
              ) : (
                <p>カートに商品がありません</p>
              )}
              {cartItems.length > 0 && totalPrice && (
                <div className="PostShow_TotalPrice">
                  <span>{I18n.t('generic.total')}</span>
                  <p>{formatPrice(totalPrice)}</p>
                </div>
              )}
              {cartItems.length > 0 && reservationErrors && !available && (
                <ul>
                  {formatReservationError(reservationErrors).map((availibilityError, index) => {
                    return (
                      <li key={index} style={{ fontSize: '12px', color: 'red' }}>
                        {availibilityError.name}は残り在庫{availibilityError.in_stock}です
                      </li>
                    )
                  })}
                </ul>
              )}
            </S.Cartitems>
          )}
          {cartItems.length > 0 && !ordable && available && (
            <p style={{ fontSize: '12px', color: 'red' }}>
              最低注文金額は{formatPrice(post.display_min_price)}円です
            </p>
          )}

          {isSignedIn && post.coupon_exists && ordable && available && (
            <S.Coupon>
              <InputText
                required={false}
                name="coupon_code"
                defaultValue=""
                label="クーポンコード"
                onChangeHandler={event => changeCouponCodeValue(event)}
              />
              {couponValue !== 0 && couponValue !== -1 && (
                <div className="coupon_value"> - {formatPrice(couponValue)}</div>
              )}
              {couponValue === -1 && (
                <div className="coupon_value">入力されたクーポンは無効です</div>
              )}
            </S.Coupon>
          )}

          {isSignedIn ? (
            <Button
              disabled={!canOrderState || !ordable || !available || couponValue === -1}
              primary={true}
              handleClick={createReservation}
            >
              {I18n.t('generic.confirm_reservation')}
            </Button>
          ) : (
            <Button primary={true}>
              <a href="/users/sign_in">{I18n.t('generic.login')}</a>
            </Button>
          )}
        </S.Panel>
        {/* <S.Buttons>
          <Button handleClick={toggleLike}>
            {userLiked ? (
              <>
                <i className="material-icons liked">favorite</i>
                <span>{I18n.t('favorite.is_liked')}</span>
              </>
            ) : (
              <>
                <i className="material-icons">favorite_border</i>
                <span>{I18n.t('favorite.add_like')}</span>
              </>
            )}
          </Button>
        </S.Buttons> */}
      </S.Wrapper>
    </S.Sidebar>
  )
}

const S: { [key: string]: AnyStyledComponent } = {}

S.Sidebar = styled.div<{ show: boolean }>`
  margin-bottom: 48px;
  @media (max-width: ${BREAKPOINT_TABLET_LARGE}px) {
    display: ${({ show }) => (show ? 'block' : 'none')};
    margin: 0;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 10px; //0;
    margin: auto;
    background-color: #fff;
    z-index: 900;
  }
  .Button {
    height: 48px;
  }
`

S.Wrapper = styled.div`
  position: sticky;
  top: ${HEADER_HEIGHT + 32}px;
  width: 320px;
  @media (max-width: ${BREAKPOINT_TABLET_LARGE}px) {
    position: static;
    width: 100%;
    max-width: 720px;
    margin: auto;
    height: 100%;
  }

  .Button {
    width: 100%;
    margin-top: 24px;
  }
`

S.Price = styled.div`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 24px;
`

S.Panel = styled.div`
  padding: 16px;
  border: solid 1px ${COLORS.Border};
  background-color: #fff;
  @media (max-width: ${BREAKPOINT_TABLET_LARGE}px) {
    padding: 24px;
    border: none;
    height: 100%;
    overflow-y: auto;
    padding-top: 110px;
  }
  @media (max-width: ${BREAKPOINT_TABLET_MOBILE}px) {
    padding-top: 70px;
  }
  .Button {
    font-weight: bold;
  }
`
S.Cartitems = styled.div`
  .PostShow_TotalPrice {
    display: flex;
    justify-content: space-between;
    padding-top: 16px;
    margin: 8px 16px;
  }
  .accordion__button {
    cursor: pointer;
  }
  .accordion__button:before {
    display: inline-block;
    content: '';
    height: 10px;
    width: 10px;
    margin-right: 12px;
    border-bottom: 2px solid currentColor;
    border-right: 2px solid currentColor;
    transform: rotate(-45deg);
  }
  .accordion__button[aria-expanded='true']::before,
  .accordion__button[aria-selected='true']::before {
    transform: rotate(45deg);
  }
`
S.Cartitem = styled.div`
  position: relative;
  padding-bottom: 16px;
  margin: 16px 16px;
  border-bottom: 1px solid #eaedef;
  > p {
    display: inline-block;
  }
`

S.PriceSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 32px;
  margin-top: 16px;
`

S.Hide = styled.div`
  display: none;
  margin: 0 0 0 10px;
  cursor: pointer;
  @media (max-width: ${BREAKPOINT_TABLET_LARGE}px) {
    display: block;
  }
`
S.Close = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  @media (max-width: ${BREAKPOINT_TABLET_LARGE}px) {
    display: block;
  }
`
S.ItemName = styled.p`
  width: calc(100% - 30px);
`

S.Buttons = styled.div`
  padding: 16px;
  @media (max-width: ${BREAKPOINT_TABLET_LARGE}px) {
    display: none;
  }

  .Button {
    font-weight: bold;
    color: ${COLORS.Text};
    border: solid 1px
      ${Color(COLORS.Text)
        .lighten(3.6)
        .hex()};
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      opacity: 1;
    }

    .material-icons {
      margin-right: 6px;
      color: ${Color(COLORS.Text)
        .lighten(3.6)
        .hex()};

      &.liked {
        color: #fb585c;
      }
    }
  }
`
S.Coupon = styled.div`
  p {
    width: 165px;
  }
  div.coupon_value {
    float: right;
    margin-right: 20px;
  }
`

S.OptionName = styled.div`
  font-size: 14px;
  margin: 16px 16px;
`

S.OptionItem = styled.div`
  padding-bottom: 8px;
  margin-left: 32px;
  border-bottom: 1px solid #eaedef;
  p {
    text-align: right;
    font-size: 14px;
  }
`

S.OptionItemName = styled.div`
  font-size: 14px;
  margin: 8px 0 0 32px;
`

export default SideBar
