import moment from 'moment'
import * as React from 'react'
import { SingleDatePicker } from 'react-dates'
import 'react-dates/initialize'
import styled, { AnyStyledComponent } from 'styled-components'
import { BUSINESS_TIMES_BY_TEN, COLORS } from '../../static/constants'
import FormItem from '../atoms/_layouts/FormItem'
import Select from '../atoms/Select'

interface IProps {
  name: string
  defaultValue: string
  label?: null | string
  error?: null | string
  required?: boolean
  timeOptionsFilter?: (data: string, index: number, array: string[]) => boolean
  isOutsideRange?: (day) => boolean
  onChangeHandler?: (data) => void
}

const formatBusinessTimeOptions = (filter = (d, i, a) => true) => {
  const times = [{ value: '', label: '-' }]

  BUSINESS_TIMES_BY_TEN.filter(filter).forEach(time =>
    times.push({
      value: time,
      label: time === '23:59' ? '24:00' : time,
    })
  )
  return times
}

const getIitializeValue = defaultValue => {
  if (defaultValue && defaultValue.length > 0) {
    const initDate = moment(defaultValue)
    const initDateTimeStr = initDate.format('YYYY-MM-DD HH:mm')
    const initDateStr = initDate.format('YYYY/MM/DD')
    const initTimeStr = initDate.format('HH:mm')

    return { initDate, initDateTimeStr, initDateStr, initTimeStr }
  }

  return { initDate: null, initDateTimeStr: '', initDateStr: null, initTimeStr: null }
}

const InputDateTime: React.FC<IProps> = ({
  name,
  defaultValue,
  label,
  error,
  required,
  timeOptionsFilter,
  isOutsideRange,
  onChangeHandler,
}) => {
  const [isFocused, setIsFocused] = React.useState<boolean>(false)

  const { initDate, initDateTimeStr, initDateStr, initTimeStr } = getIitializeValue(defaultValue)
  const [selectedDate, setSelectedDate] = React.useState<null | moment.Moment>(initDate)
  const [selectedDateStr, setSelectedDateStr] = React.useState<null | string>(initDateStr)
  const [selectedTimeStr, setSelectedTimeStr] = React.useState<null | string>(initTimeStr)

  const [stringValue, setStringValue] = React.useState<string>(initDateTimeStr)
  const options = formatBusinessTimeOptions(timeOptionsFilter)
  React.useEffect(() => {
    if (selectedDateStr && selectedTimeStr) {
      setSelectedDate(moment(`${selectedDateStr} ${selectedTimeStr}`))
    } else if (selectedDateStr) {
      setSelectedDate(moment(selectedDateStr))
    }
  }, [selectedDateStr, selectedTimeStr])

  React.useEffect(() => {
    if (selectedDate) {
      if (onChangeHandler) {
        onChangeHandler(selectedDate)
      }

      setStringValue(selectedDate.format('YYYY-MM-DD HH:mm'))
    }
  }, [selectedDate])

  const setDate = (date: moment.Moment) => {
    setSelectedDateStr(date.format('YYYY-MM-DD'))
  }

  const setTime = (time: string) => {
    setSelectedTimeStr(time)
  }
  return (
    <FormItem label={label} required={required} error={error}>
      <S.Wrappwer>
        <S.Wrappwer>
          <S.Label>日付</S.Label>
          <SingleDatePicker
            date={selectedDate}
            onDateChange={date => setDate(date)}
            focused={isFocused}
            isOutsideRange={isOutsideRange}
            onFocusChange={({ focused }) => setIsFocused(focused)}
            numberOfMonths={1}
          />
        </S.Wrappwer>
        <S.Wrappwer>
          <S.Label>時間</S.Label>
          <Select
            name=""
            options={options}
            defaultValue={selectedTimeStr ? selectedTimeStr : options[0].value}
            onChangeHandler={e => {
              setTime(e.target.value)
            }}
          />
        </S.Wrappwer>
      </S.Wrappwer>
      <S.Input
        id={name}
        type="text"
        name={name}
        value={stringValue}
        required={required ? true : false}
        ref={null}
        readOnly={true}
      />
    </FormItem>
  )
}

const S: { [key: string]: AnyStyledComponent } = {}
S.Wrappwer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 0 16px 4px 0;
  .DateInput_input {
    font-weight: normal;
    font-size: 16px;
    padding: 8px 12px;
  }
  select {
    min-width: 132px;
    height: 44px;
    border-radius: 2px;
    border: 1px solid #dbdbdb;
  }
`
S.Label = styled.div`
  margin-right: 8px;
`
S.Input = styled.input`
  display: none;

  &[readonly] {
    background-color: ${COLORS.Border};
  }
`

export default InputDateTime
