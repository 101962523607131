import ClassNames from 'classnames'
import I18n from 'core/i18n'
import * as React from 'react'
import styled, { AnyStyledComponent } from 'styled-components'
import { IOptionItem } from '../../core/interfaces'
import { COLORS } from '../../static/constants'
import { Button, CheckBox, FormItemLayout, InputText } from '../atoms'

const urlParams = new URLSearchParams(window.location.search)

interface IOptionFormProps {
  index: number
  optionItems: IOptionItem[]
  addOptionItem: () => void
  errors: any
  removeOptionItem(option_index: number, item_index: number): void
}

interface IErrors {
  [key: string]: string | null
}

const OptionItemForm: React.FC<IOptionFormProps> = props => {
  function toBoolean(data) {
    return data.length > 0 && data.toLowerCase() === 'true'
  }

  {
    /* オプション項目ごとに配列でアイテムの在庫上限数フラグを配列で管理 */
  }
  const limitedItemsArray = () => {
    const limitedArray = []
    props.optionItems.map((optionItem, index) => {
      limitedArray.push(toBoolean(optionItem.is_quantity_limited))
    })
    return limitedArray
  }

  {
    /* 在庫数上限フラグON/OFF時に在庫数disabled制御処理 */
  }
  const [limitedItems, setLimitedItems] = React.useState<boolean[]>(limitedItemsArray())
  const setItemQuantityLimitedStatus = (event, optionItemIndex) => {
    const checked = event.target.checked
    setLimitedItems(prev => {
      const newState = [...prev]
      newState[optionItemIndex] = Boolean(checked)
      return newState
    })
  }

  return (
    <S.Inner>
      <S.Title className="FormLabel">オプションアイテム設定</S.Title>
      {props.optionItems.map((optionItem, index) => {
        return (
          <div key={index} style={{ display: `${optionItem.deleted_at ? 'none' : 'block'}` }}>
            <S.OptionItemFormWrapper>
              <input
                type="hidden"
                id={`post_options_attributes[${props.index}][option_items_attributes][${index}][id]`}
                name={`post_options_attributes[${props.index}][option_items_attributes][${index}][id]`}
                readOnly={true}
                value={optionItem.id}
              />
              <input
                type="hidden"
                id={`post_options_attributes[${props.index}][option_items_attributes][${index}][post_option_id]`}
                name={`post_options_attributes[${props.index}][option_items_attributes][${index}][post_option_id]`}
                readOnly={true}
                value={optionItem.post_option_id}
              />
              <input
                type="hidden"
                id={`post_options_attributes[${props.index}][option_items_attributes][${index}][deleted_at]`}
                name={`post_options_attributes[${props.index}][option_items_attributes][${index}][deleted_at]`}
                readOnly={true}
                value={optionItem.deleted_at ?? ''}
              />
              <S.AddButtonWrap>
                <S.Title className="FormLabel">{`オプションアイテム${index + 1}`}</S.Title>
                <Button small={true} handleClick={() => props.removeOptionItem(props.index, index)}>
                  アイテム削除
                </Button>
              </S.AddButtonWrap>
              <S.FormItem>
                <InputText
                  required={optionItem.deleted_at ? false : true}
                  name={`post_options_attributes[${props.index}][option_items_attributes][${index}][title]`}
                  label={`アイテム名${index + 1}`}
                  defaultValue={optionItem.title}
                  error={
                    props.errors[
                      `post_options_attributes[${props.index}][option_items_attributes][${index}][title]`
                    ]
                  }
                />
                <InputText
                  required={optionItem.deleted_at ? false : true}
                  name={`post_options_attributes[${props.index}][option_items_attributes][${index}][price]`}
                  label={`アイテム価格${index + 1}`}
                  defaultValue={optionItem.price ? String(optionItem.price) : '0'}
                  error={
                    props.errors[
                      `post_options_attributes[${props.index}][option_items_attributes][${index}][price]`
                    ]
                  }
                />
              </S.FormItem>
              <S.FormItem>
                <CheckBox
                  label={`注文数上限${index + 1}`}
                  name={`post_options_attributes[${props.index}][option_items_attributes][${index}][is_quantity_limited]`}
                  defaultChecked={Boolean(limitedItems[index])}
                  onChangeHandler={e => setItemQuantityLimitedStatus(e, index)}
                />
                <InputText
                  name={`post_options_attributes[${props.index}][option_items_attributes][${index}][quantity]`}
                  label={`注文数上限数${index + 1}`}
                  defaultValue={optionItem.quantity ? String(optionItem.quantity) : '0'}
                  readonly={!Boolean(limitedItems[index])}
                  error={
                    props.errors[
                      `post_options_attributes[${props.index}][option_items_attributes][${index}][quantity]`
                    ]
                  }
                />
              </S.FormItem>
              <S.FormItem>
                <S.Title className="FormLabel">{I18n.t('post.total_ordered_count')}</S.Title>
                <>{optionItem.total_ordered_count}</>
                <input
                  type="hidden"
                  id={`post_options_attributes[${props.index}][option_items_attributes][${index}][total_ordered_count]`}
                  name={`post_options_attributes[${props.index}][option_items_attributes][${index}][total_ordered_count]`}
                  readOnly={true}
                  value={optionItem.total_ordered_count ?? '0'}
                />
              </S.FormItem>
            </S.OptionItemFormWrapper>
          </div>
        )
      })}
      <S.RemoveButtonWrap>
        <Button small={true} handleClick={props.addOptionItem}>
          アイテム追加
        </Button>
      </S.RemoveButtonWrap>
    </S.Inner>
  )
}

const S: { [key: string]: AnyStyledComponent } = {}

S.Inner = styled.div`
  margin: 16px 0px;
  padding-bottom: 16px;
  border-bottom: solid 1px ${COLORS.Border};
  button {
  }
`
S.AddButtonWrap = styled.div`
  margin: 16px 0px;
  display: flex;
  align-items: center;
`

S.RemoveButtonWrap = styled.div`
  button {
    margin: 16px 0 auto 40px;
    display: block;
  }
`

S.OptionItemButtonWrapper = styled.div`
  display: flex;
  margin-top: 16px;
  &.disabled {
    opacity: 0.3;
    pointer-events: none;
  }
  & + & {
    margin-top: 16px;
  }
`

S.FormItem = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
  &.disabled {
    opacity: 0.3;
    pointer-events: none;
  }
  & + & {
    margin-top: 16px;
  }
`
S.Title = styled.p`
  padding-left: 16px;
  width: 240px;
  font-size: 15px;
  font-weight: bold;
`

S.OptionItemFormWrapper = styled.div`
  padding-left: 24px;
  p {
    width: 216px;
    padding-left: 16px;
    font-size: 15px;
    font-weight: bold;
  }
  .FormItem {
  }
`
S.Hidden = styled.div`
  display: none;
`

export default OptionItemForm
