import { IAddress } from 'core/interfaces'
import * as React from 'react'
import I18n from '../../../../core/i18n'
import { settingsService, userService } from '../../../../core/services'
import injectGoogleMaps from '../../../../utils/injectGoogleMaps'
import { InputText } from '../../../atoms'
import AddressForm, { ADDRESS_FIELDS, emptyToNull } from '../../../organisms/AddressForm'
import AdminLayoutEdit from '../_layouts/edit'

interface IProps {
  settings: any
  user_address: IAddress
}

const delivery_range = 'delivery_range'
const FIELDS = {
  delivery_range,
  ...ADDRESS_FIELDS,
}
delete FIELDS.phone

const AdminSettingsEdit: React.FC<IProps> = props => {
  const settings = props.settings.reduce((obj, setting) => {
    obj[setting.var] = setting.value
    return obj
  }, {})
  const handleSubmit = React.useCallback(async (initialValues, values) => {
    const promises: Array<Promise<any>> = []

    if (initialValues[delivery_range] !== values[delivery_range]) {
      promises.push(settingsService.updateSetting({ delivery_range: values[delivery_range] }))
    }

    const profileParams = new FormData()
    let flag = false
    Object.keys(ADDRESS_FIELDS).forEach(key => {
      if (initialValues[key] !== values[key]) {
        profileParams.append(`address[${key}]`, emptyToNull(key, values[key]))
        flag = true
      }
    })
    if (flag) {
      promises.push(userService.updateProfile(profileParams))
    }

    await Promise.all(promises)
    location.href = '/admin/address'
  }, [])

  return (
    <AdminLayoutEdit
      model="address"
      title={I18n.t('settings.delivery_range_setting')}
      fields={FIELDS}
      indexLink="/admin/address"
      formItems={
        <>
          <AddressForm
            required={false}
            phoneRequired={false}
            phoneFormHidden={true}
            isGuest={false}
            withMap={true}
            defaultAddress={props.user_address}
          />

          <InputText
            name="delivery_range"
            defaultValue={settings.delivery_range}
            label={`${I18n.t('settings.delivery_range')}（km）`}
          />
        </>
      }
      handleSubmit={handleSubmit}
    />
  )
}

export default injectGoogleMaps(AdminSettingsEdit)
