import { IPost } from 'core/interfaces'
import React from 'react'
import styled, { AnyStyledComponent } from 'styled-components'
import I18n from '../../../../core/i18n'
import Pagination from '../../../atoms/Pagination'
import Spinner from '../../../atoms/Spinner'
import PostItem from './PostItem'

interface IProps {
  showDescription?: boolean
  loading?: boolean
  isSignedIn: boolean
  favorite: boolean
  posts: IPost[]
  meta: any
  keyword?: null | string
  handleOnMouseEnter?(markerId): void
  handleOnMouseLeave?(): void
  getPostsByPage?(page: number): void
  title(count: number): string
}

const PostList: React.FC<IProps> = props => {
  const emptyMessage = props.favorite
    ? I18n.t('favorite.no_favorites')
    : I18n.t('post.out_of_range')

  return (
    <>
      {props.loading ? (
        <Spinner />
      ) : (
        <>
          {props.favorite && <h1>{I18n.t('post.favorite_post_heading')}</h1>}

          <p>{emptyMessage}</p>

          <S.PostItemContainer>
            {props.posts.map((post, index) => (
              <PostItem
                key={index}
                showDescription={props.showDescription}
                isSignedIn={props.isSignedIn}
                handleOnMouseEnter={props.handleOnMouseEnter}
                handleOnMouseLeave={props.handleOnMouseLeave}
                getPostsByPage={props.getPostsByPage}
                title={props.title}
                post={post}
              />
            ))}
          </S.PostItemContainer>

          {props.meta && props.meta.total_pages > 1 && (
            <Pagination
              onChangePageHandler={props.getPostsByPage}
              currentPage={Number(props.meta.current_page)}
              prevPage={props.meta.prev_page}
              nextPage={props.meta.next_page}
              totalPages={props.meta.total_pages}
              totalCount={props.meta.total_count}
            />
          )}
        </>
      )}
    </>
  )
}

const S: { [key: string]: AnyStyledComponent } = {}
S.PostItemContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 32px;
`

export default PostList
