import I18n from 'core/i18n'
import IReservationErrorItem from 'core/interfaces/IReservationErrorItem'

export function formatReservationError(errors: IReservationErrorItem[]): any {
  const message_list = []
  errors.forEach(element => {
    message_list.push({
      name: element.name,
      in_stock: element.in_stock,
    })
  })
  return message_list
}
