import * as React from 'react'

const SvgTodoxiPin = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={92} height={92} fill="none" {...props}>
    <path d="M46,44.9c-1-2.6-3.6-5.8-6.4-9.5C35.1,29.6,30,23,30,17.9c0-8.8,7.2-16,16-16c8.8,0,16,7.2,16,16
      c0,5.2-5.2,11.9-9.8,17.8C49.5,39.3,47.1,42.4,46,44.9z"
      fill="#FFCC66"
      />
    <path d="M46,2.4c8.6,0,15.5,7,15.5,15.5c0,5-5.1,11.7-9.7,17.5c-2.4,3.1-4.6,5.9-5.8,8.3c-1.2-2.5-3.5-5.4-6.1-8.7
      c-4.5-5.7-9.5-12.3-9.5-17.1C30.5,9.4,37.4,2.4,46,2.4 M46,1.4c-9.1,0-16.5,7.4-16.5,16.5S45.2,40.5,46,46.6
      c1.2-6.1,16.5-19.5,16.5-28.7S55.1,1.4,46,1.4L46,1.4z"
      fill="#B1596F"
      />
    <circle cx="46" cy="16.9" r="8.2"
      fill="#FFFFFF"
    />
    <path d="M54.2,16.9H37.8c0-4.5,3.7-8.2,8.2-8.2S54.2,12.3,54.2,16.9z"
      fill="#F3E7D3"
    />
  </svg>
)

export default SvgTodoxiPin
