import * as React from 'react'
import styled, { AnyStyledComponent } from 'styled-components'
import I18n from '../../../../core/i18n'
import { IWindow } from '../../../../core/interfaces'
import { IJsonResponse } from '../../../../core/JsonApiSerializer'
import { adminService } from '../../../../core/services'
import { Button } from '../../../atoms'
import { EditTable } from '../../../organisms'
import AdminLayoutIndex from '../_layouts/'

declare var window: IWindow

interface IProps {
  taxies: {
    taxis: IJsonResponse
  }
}

const COLUMNS = [
  {
    name: I18n.t('generic.name'),
    field: 'name',
    required: true,
  },
  {
    name: I18n.t('generic.email'),
    field: 'email',
    required: true,
  },
  {
    name: I18n.t('generic.zipcode'),
    field: record => <span>{record.address.postal_code1}</span>,
    required: true,
  },
  {
    name: I18n.t('generic.prefectures'),
    field: record => <span>{record.address.state1}</span>,
    required: true,
  },
  {
    name: I18n.t('generic.city'),
    field: record => <span>{record.address.city1}</span>,
    required: true,
  },
  {
    name: I18n.t('generic.street1'),
    field: record => <span>{record.address.other1_1}</span>,
    required: true,
  },
  {
    name: I18n.t('generic.street2'),
    field: record => <span>{record.address.other1_2}</span>,
    required: true,
  },
  {
    name: I18n.t('generic.notify_url'),
    field: record => <span>{record.notify_url}</span>,
    required: true,
  },
]

const AdminTaxiesIndex: React.FC<IProps> = props => {
  const { data, pagination } = adminService.getDataFromJson(props.taxies.taxis)
  const [taxies, setTaxies] = React.useState(data)
  const handleDelete = React.useCallback(
    async id => {
      const { flush } = await adminService.deleteCategory(id)
      window.flashMessages.addMessage({
        text: flush.message,
        type: flush.type,
      })
      setTaxies(taxies.filter(taxi => taxi.id !== id))
    },
    [taxies]
  )

  return (
    <AdminLayoutIndex
      model="taxi"
      heading={
        <S.Heading>
          <span>{I18n.t('activerecord.models.taxi')}</span>
          <Button small={true}>
            <a href="/admin/taxies/new">{I18n.t('generic.create_new')}</a>
          </Button>
        </S.Heading>
      }
      main={
        <EditTable
          editable={true}
          columns={COLUMNS}
          records={taxies}
          pagination={pagination}
          getEditLink={id => `/admin/taxies/${id}/edit`}
        />
      }
    />
  )
}

const S: { [key: string]: AnyStyledComponent } = {}

S.Heading = styled.div`
  .Button {
    margin-left: 24px;
  }
`

export default AdminTaxiesIndex
