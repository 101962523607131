import { Button, Select } from 'components/atoms'
import { postService } from 'core/services'
import moment, { Moment } from 'moment'
import * as React from 'react'
import styled from 'styled-components'
import downloadCSV from 'utils/csv'
import { formatPrice } from 'utils/number'
import { formatSelectOptions } from '..'
import { BREAKPOINT_TABLET_SMALL, COLORS } from '../../../../static/constants'

interface IProps {
  posts: any
  payments: {
    ordered_count: any
    post_transfer_per_day: any
    taxi_transfer_per_day: any
    post_fees_per_day: any
    taxi_fees_per_day: any
    admin_fees_per_day: any
    post_transfer_amount_total: number
    taxi_transfer_amount_total: number
    admin_fees_total: number
    fee_income: any
    coupons_count_used_per_day: any
    coupons_total_value_per_day: number
  }
}

const getCreatedAtQuery = (date: string) => {
  return `/admin/payments/index_by_date?created_at=${date}`
}

const TARGETS = [
  {
    label: '詳細',
    value: 'all',
  },
  {
    label: '期間内合計',
    value: 'total',
  },
]

const getFormattedMonth = (m: moment.Moment) => {
  return m.locale('en').format('YYYY-MM')
}

const params = new URLSearchParams(location.search.substring(1))
const isShowAll = params.get('all') || false
const currentPostID = params.get('post_id') || ''
const currentTaxiID = params.get('taxi_id') || ''
const currentMonth =
  params.get('month') ||
  moment()
    .locale('en')
    .format('YYYY-MM')

const PaymentPage: React.FC<IProps> = props => {
  const [showTarget, setShowTarget] = React.useState<'all' | 'total'>('total')
  const [payments, setPatments] = React.useState(props.payments)

  const initPosts = postService.getPostsFromJson(props.posts.posts)
  const [posts, setPosts] = React.useState(formatSelectOptions(initPosts.posts))
  const [taxies, setTaxies] = React.useState([])

  const [postId, setPostId] = React.useState<string>(currentPostID)
  const [taxiId, setTaxiId] = React.useState<string>(currentTaxiID)
  const currentMoment = moment(currentMonth)
  const showTotal = showTarget === 'total'

  const keys = Object.keys(props.payments.ordered_count)

  const getTaxies = async () => {
    const { taxiList } = await postService.getTaxies()
    setTaxies(formatSelectOptions(taxiList))
  }

  React.useEffect(() => {
    getTaxies()
  }, [])
  const getQuery = (month: string | null = null) => {
    const byMonth = month ? `?month=${month}` : '?all=true'
    const byPost = postId ? `&post_id=${postId}` : ''
    const byTaxi = taxiId ? `&taxi_id=${taxiId}` : ''
    return byMonth + byPost + byTaxi
  }
  const doFilter = (month: string | null = null) => {
    location.href = getQuery(month)
  }

  const showPrevMonth = () => {
    doFilter(getFormattedMonth(currentMoment.subtract(1, 'M')))
  }
  const showCurrentMonth = () => {
    doFilter(getFormattedMonth(moment()))
  }
  const showNextMonth = () => {
    doFilter(getFormattedMonth(currentMoment.add(1, 'M')))
  }
  const showAllMonth = () => {
    doFilter(null)
  }
  const getDataArray = (dateLabel): string[] => {
    return [
      dateLabel,
      payments.ordered_count[dateLabel]?.toString() || '',
      formatPrice(payments.post_transfer_per_day[dateLabel] || 0),
      formatPrice(payments.post_fees_per_day[dateLabel] || 0),
      formatPrice(payments.taxi_transfer_per_day[dateLabel] || 0),
      formatPrice(payments.taxi_fees_per_day[dateLabel] || 0),
      formatPrice(payments.admin_fees_per_day[dateLabel] || 0),
      payments.coupons_count_used_per_day[dateLabel] || 0,
      formatPrice(payments.coupons_total_value_per_day[dateLabel] || 0),
    ]
  }

  const getTotalBaseArray = (paymentsData): string[][] => {
    const labels = ['店舗売上合計', 'タクシー売上合計', '運営負担手数料', '予想手数料収益']
    const data = [
      formatPrice(paymentsData.post_transfer_amount_total),
      formatPrice(paymentsData.taxi_transfer_amount_total),
      formatPrice(paymentsData.admin_fees_total),
      formatPrice(paymentsData.fee_income),
    ]
    return [labels, data]
  }

  const getBaseArray = (dateLabels): string[][] => {
    const labels = [
      '日付',
      '注文数',
      '店舗売上',
      '店舗手数料',
      'タクシー売上',
      'タクシー手数料',
      '運営負担手数料',
    ]
    return [labels, ...dateLabels.map(dateLabel => getDataArray(dateLabel))]
  }

  const getTotalName = () => {
    return '売上合計' + (isShowAll ? '全期間' : currentMoment.format('YYYY/MM'))
  }

  const getName = () => {
    return '売上一覧' + (isShowAll ? '全期間' : currentMoment.format('YYYY/MM'))
  }
  const isCurrentMonth = getFormattedMonth(currentMoment) === getFormattedMonth(moment())
  return (
    <Wrapper>
      <div className="Wrapper_Header">
        <h2>売上一覧: {isShowAll ? '全期間' : currentMoment.format('YYYY/MM')}</h2>
      </div>
      <div className="Header_Download">
        <h3>精算用CSV</h3>
        <Button small={true}>
          <a href={`/admin/payments/report.csv?all=true&locale=ja`}>全てをダウンロード</a>
        </Button>
        <Button small={true}>
          <a href={`/admin/payments/report.csv${getQuery(params.get('month') || null)}&locale=ja`}>
            表示中の内容でダウンロード
          </a>
        </Button>
      </div>
      <div className="Wrapper_Main">
        <div className="filters">
          <h3>期間</h3>
          <div className="buttons">
            <Button small={true} handleClick={showPrevMonth}>
              前月
            </Button>
            <Button small={true} handleClick={showCurrentMonth}>
              今月
            </Button>
            <Button small={true} handleClick={showNextMonth} disabled={isCurrentMonth}>
              次月
            </Button>
            <Button small={true} handleClick={showAllMonth}>
              全期間
            </Button>
          </div>
          <h3>絞り込み</h3>
          {posts.length > 0 && (
            <div className="filter">
              <Select
                name="posts"
                label="店舗選択"
                options={posts}
                defaultValue={postId}
                onChangeHandler={e => setPostId(e.target.value)}
              />
            </div>
          )}
          {taxies.length > 0 && (
            <div className="filter">
              <Select
                name="taxies"
                label="タクシー会社選択"
                options={taxies}
                defaultValue={taxiId}
                onChangeHandler={e => setTaxiId(e.target.value)}
              />
            </div>
          )}
          {posts.length > 0 && taxies.length > 0 && (
            <div className="buttons">
              <Button handleClick={() => doFilter()}>結果をフィルター</Button>
            </div>
          )}
        </div>
        <h2>期間内合計</h2>
        <table>
          <thead>
            <tr>
              <th>店舗売上合計</th>
              <th>タクシー売上合計</th>
              <th>運営負担手数料</th>
              <th>予想手数料収益</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{formatPrice(payments.post_transfer_amount_total)}</td>
              <td>{formatPrice(payments.taxi_transfer_amount_total)}</td>
              <td>{formatPrice(payments.admin_fees_total)}</td>
              <td>{formatPrice(payments.fee_income)}</td>
            </tr>
          </tbody>
        </table>
        <div className="buttons">
          <Button
            small={true}
            handleClick={() => downloadCSV(getTotalBaseArray(payments), getTotalName())}
          >
            CSVで保存
          </Button>
        </div>
        <h2>詳細</h2>
        <table>
          <thead>
            <tr>
              <th>日付</th>
              <th>注文数</th>
              <th>店舗売上</th>
              <th>店舗手数料</th>
              <th>タクシー売上</th>
              <th>タクシー手数料</th>
              <th>運営負担手数料</th>
              <th>Nb of Coupons</th>
              <th>Value of Coupons</th>
            </tr>
          </thead>
          <tbody>
            {keys.map(key => (
              <tr key={key}>
                <td>
                  <a href={getCreatedAtQuery(key)}>{key}</a>
                </td>
                <td>{payments.ordered_count[key]}</td>
                <td>{formatPrice(payments.post_transfer_per_day[key] || 0)}</td>
                <td>{formatPrice(payments.post_fees_per_day[key] || 0)}</td>
                <td>{formatPrice(payments.taxi_transfer_per_day[key] || 0)}</td>
                <td>{formatPrice(payments.taxi_fees_per_day[key] || 0)}</td>
                <td>{formatPrice(payments.admin_fees_per_day[key] || 0)}</td>
                <td>{payments.coupons_count_used_per_day[key] || 0}</td>
                <td>{formatPrice(payments.coupons_total_value_per_day[key] || 0)}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="buttons">
          <Button
            small={true}
            disabled={keys.length === 0}
            handleClick={() => downloadCSV(getBaseArray(keys), getName())}
          >
            CSVで保存
          </Button>
        </div>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  margin: 0 auto;
  padding: 0 12px;
  @media (max-width: ${BREAKPOINT_TABLET_SMALL}px) {
    font-size: 14px;
  }
  .Wrapper_Header {
    display: flex;
    align-items: center;
    height: 48px;
    border-bottom: solid 1px ${COLORS.Border};
  }

  .Header_Download {
    > button {
      margin: 8px;
    }
  }

  .select-label {
    margin-left: 16px;
  }

  .Wrapper_Main {
    overflow: scroll;
  }

  .filters {
    display: flex;
    flex-wrap: wrap;
    padding: 8px 0;
    border-bottom: solid 1px ${COLORS.Border};

    h3 {
      width: 100%;
    }
  }

  .filter {
    padding: 8px 16px;
    max-width: 420px;
    display: flex;

    p {
      width: 140px;
    }
  }

  .buttons {
    padding: 8px;
    display: flex;
  }

  table {
    margin: 16px 0;
    text-align: center;
    border-bottom: solid 1px ${COLORS.Border};
    @media (max-width: ${BREAKPOINT_TABLET_SMALL}px) {
      font-size: 12px;
    }

    tr {
      th,
      td {
        padding: 4px 8px;
      }
    }
  }
`

export default PaymentPage
