import * as React from 'react'

const SvgLogoTodoxiSp = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={205} height={38} fill="none" {...props}>
<g>
	<g>
		<path fill="#090000" d="M4.6,32.8C2,30.5-0.2,26.2,3,18.4c1-2.6,2.2-4.8,3.4-6.9C9.5,5.2,12.7,1.2,20,1c8.6-0.3,12,5.9,13.7,8.7
			c1.1,2.1,2.2,4.7,3.9,9.1c2,5.4,1.6,11.9-1.3,14.2c-4.1,3.3-8.1,4-16.2,4C12.8,37,7.1,35,4.6,32.8z M20,37.6
			c8.4,0,12.5-0.9,16.8-4.2c3-2.3,3.4-9.4,1.4-14.7c-1.6-4.4-2.7-7.3-4.2-9.3c-1.8-2.9-4-9.1-14-9.1C12.7,0.4,9.2,4.7,5.8,11.2
			c-1.1,2.2-2.6,4.5-3.7,7.2C-1.3,26.5,1.3,30.9,4,33.3C6.7,35.6,12.6,37.6,20,37.6z"/>
		<path fill="#090000" d="M17.5,20.8c0,0-1.1,0.7-1.3,1c-0.3,0.6,0.2,1,0.2,1C16.7,22.7,17.7,21.4,17.5,20.8z"/>
		<path fill="#090000" d="M15.8,20h-0.2c0,0-1.4,1-1.6,1.4c-0.2,0.3-0.3,0.5-0.2,1.3c0,0,0.7,0.1,1-1c0.4-1.1,0.5-1.2,0.8-1.4L15.8,20z
			"/>
		<path fill="#090000" d="M14.7,19.4c0,0-1.7,0.4-2.6,1.2c-0.9,0.8-0.3,1.5-0.3,1.5S13.4,20.9,14.7,19.4z"/>
		<path fill="#090000" d="M13.1,19c0,0-1.6,0.2-2.2,0.5c-0.6,0.3-0.5,0.7-0.5,1.1c0,0,0.7-0.1,1.3-0.6S13.1,19,13.1,19z"/>
		<path fill="#090000" d="M11.7,18.7c0,0-1.4-0.1-1.8,0.3s-0.5,0.4-0.4,1.1c0,0,0.3,0,1-0.5C10.9,19.1,11.7,18.7,11.7,18.7z"/>
		<path fill="#090000" d="M11,18.3c0,0-0.4-0.5-1.5-0.1c-0.9,0.3-0.5,1-0.5,1s0.3-0.1,1-0.5C10.4,18.2,11,18.3,11,18.3z"/>
		<path fill="#090000" d="M10.1,15.7c0,0,0.1,0.6,0.8,0.7s1.3,0.1,1.3,0.1s-1.6,0.5-2,1c-0.4,0.3-1.6,0.9-1.6,0.9s-0.4-0.9-0.2-2.1
			c0.2-1,1-1.3,1-1.3l-0.5-0.8c0,0-0.1-1.2,0.3-2.4c0.3-1.1,1-2.3,1.6-2.9c0.5-0.6,1.7-2.2,1.7-2.2s0.2,0.1,0,0.6
			C12.1,7.9,12.4,8,12.4,8l-0.3,0.1l-0.3,0.2l-0.2,0.3l-0.2,0.2l-0.1,0.1L11.5,9c0,0,0.3,0.3,0.3,0.4s-0.3,0.2-0.3,0.2
			s-0.5,0-0.9,0.6c0,0-0.1,0.3,0.3,0.4c0.4,0.2,0.4,0,0.4,0s-0.1,0.5-1.2,1.1c0,0-0.1,0.4,1,0.5c0,0,0.5,0.1,0.6,0.3
			c0,0,0.1,0.2-1.3,0.3c0,0,0.3,0.3,1,0.6l0,0c0.9,0.3,1.1,0.1,1.1,0.1s0.1,0.3-2.2,0.3c0,0,1,0.9,2.2,1.3c1.4,0.5,2.4,0.9,3.7,1
			c1.3,0.2,2.6,0.5,2.6,0.5s-2.2,0.1-4-0.3S10.1,15.7,10.1,15.7z"/>
		<path fill="#090000" d="M18.9,13L18.9,13l0.1,0.3l-0.1,0.2l-0.3,0.3L18.4,14l-0.3,0.3L18,14.6v0.2l0.1,0.2l0.2,0.2l0.2,0.1h0.2
			l0.3-0.1l0.3-0.2l0.2-0.1l0.2-0.1h0.2l-0.1-0.4v-0.3v-0.4c0,0,0.1-0.5,0.3-0.7v0.3V14v0.7c0,0,0,0.3,0.3,0.6c0,0,0.3,0.2,0.4,0.1
			c0.3-0.1,0.3-0.3,0.3-0.3s0.1-0.1,0.2-0.3c0.1-0.3,0.1-0.9,0.1-0.9l-0.1-0.8c0,0,0.2,0,0.3,0.5c0.2,0.5-0.1,1.3-0.1,1.3
			s0,0.1,0.3,0.3s0.5,0.3,0.6,0.3c0.1,0.1,0.3,0.1,0.4-0.1s0.2-0.3,0.2-0.6c-0.1-0.3-0.5-0.9-0.5-0.9s-0.4-0.5-0.4-0.6
			c0,0,0.3-0.1,0.7,0.5c0.5,0.6,0.5,1.1,0.5,1.1s0,0.5-0.3,0.9c0,0-0.3,0.1-0.9-0.1c-0.5-0.3-0.8-0.4-0.8-0.4s-0.2,0.4-0.8,0.3
			c-0.6-0.1-0.9-0.3-0.9-0.3s-0.1,0-0.2,0.1s-0.5,0.3-1,0.3c-0.4,0.1-0.5-0.1-0.6-0.2c-0.1-0.2-0.3-0.9,0.1-1.4
			C18.4,13.3,18.9,13,18.9,13z"/>
		<path fill="#090000" d="M22,17.1c0,0,6.5-0.9,8.9-2.2c0,0-0.1-0.3-2.2-0.3c0,0,0.4-0.3,1.5-0.3c1-0.2,0.9-0.3,0.9-0.3
			s-1.4-0.3-1.4-0.7c0,0,1.2-0.1,1.5-0.3c0,0,0-0.2-1.2-1.5c0,0,0.1-0.2,0.7,0c0,0,0.3-0.2,0.2-0.4c-0.2-0.3-0.3-0.6-1-0.7
			c0,0,0.3-0.6,0.1-1c-0.4-0.4-0.7-0.6-0.8-0.6c0,0,0.2-0.7-0.1-1.3c0,0,2.4,2.6,3.1,5.2s-0.3,3.2-0.3,3.2s0.7,0.4,0.9,1.8
			s-0.3,1.6-0.3,1.6s-0.7-0.1-1.8-1.1c-1.2-1-1.7-0.8-1.7-0.8s0-0.2,1.5-0.2c0,0,0.7-0.1,0.6-0.5c0,0-1.9,0.1-4.3,0.5
			C24.3,17.4,22,17.1,22,17.1z"/>
		<path fill="#090000" d="M23,21c0,0,0.4,0.2,0.7,1.4c0,0,0.3,0.9,0.9,0.6c0,0,0.3-0.3-0.3-1.3C23.8,21,23,21,23,21z"/>
		<path fill="#090000" d="M25,20.4c0,0,0.4,0.4,0.7,1.4c0.3,1,0.9,1.2,1,1.2c0,0,0.5-0.3,0.1-1.4C26.2,20.5,25,20.4,25,20.4z"/>
		<path fill="#090000" d="M26.2,19.9c0,0,0.6,0.3,1.6,1.5c0,0,0.7,1,1.3,1.1c0,0,0.4-0.4-0.3-1.4C28.1,20.2,26.2,19.9,26.2,19.9z"/>
		<path fill="#090000" d="M27.6,19.6c0,0,0.5,0,1.5,1c0,0,0.7,0.8,1.3,0.7c0,0,0.2-0.9-1-1.4C28.7,19.6,27.6,19.6,27.6,19.6z"/>
		<path fill="#090000" d="M29.2,19.4c0,0,0.6,0,1.1,0.9c0,0,0.5,0.7,1,0.7c0,0,0.3-0.6-0.2-1C30.1,19,29.2,19.4,29.2,19.4z"/>
		<path fill="#090000" d="M29.8,18.9c0,0,0.7-0.1,1,0.5c0.4,0.5,1,0.5,1,0.5s0.1-0.6-0.7-1C30.6,18.5,29.8,18.9,29.8,18.9z"/>
		<path fill="#090000" d="M15.9,20.9c0,0,0.3,0.1,1-0.8s1.8-1.3,3-1.3c1.2-0.1,2.6,0.3,3,0.8c0.5,0.3,0.6,0.5,1,1.2
			c0,0,0.3,0.3,0.7,0.4c0,0-0.3-1.3-1.1-1.8c-0.9-0.6-1.6-1-2.9-1c-1.4-0.1-2.4,0.3-2.7,0.3C16.5,19.4,15.9,20.9,15.9,20.9z"/>
		<path fill="#C44237" d="M16.3,20.8c0,0-0.2-0.1-0.1-0.3c0.2-0.2,0.9-1,1.4-1.3s1.7-0.6,2.4-0.6s1.8-0.1,3.2,0.7c0,0,0.9,0.3,1.5,1.6
			c0,0,0.1,0.1,0,0.3s-0.3,0-0.3,0s-0.5-1-1.2-1.4c-0.7-0.5-1.8-1-3.2-0.9s-2.2,0.5-2.2,0.5s-0.6,0.3-1.2,1.1
			C16.6,20.5,16.5,20.8,16.3,20.8z"/>
		<path fill="#090000" d="M16.7,8.1c0,0-0.7,0.3-0.2,1c0,0,0.3,0.3,0.5,0s0.2-0.5,0.2-0.5s0.6,0.7,0.4,2.1c-0.2,1.3-1,1.7-1,1.7
			s-0.9,0.7-2.3,0c-1-0.4-1.3-2-1-3.1C13.5,8.8,14,8,14.9,7.8C16.1,7.5,16.7,8.1,16.7,8.1z"/>
		<path fill="#090000" d="M15.6,7.4c0,0,1.3-0.1,2,1.4c0,0,0.9,1.7-0.2,3.2S15,13.1,15,13.1s-1.3,0-2-1.7s0.3-2.9,0.3-2.9
			S14,7.4,15.6,7.4z M13.8,7.6c0,0-1.3,1-1.3,2.6s1,2.6,1,2.6s1.3,1,2.6,0.5c1.3-0.4,1.7-1.9,1.7-1.9s0.5-1.5-0.2-2.9
			s-2.2-1.3-2.2-1.3C14.6,7.1,13.8,7.6,13.8,7.6z"/>
		<path fill="#090000" d="M27,8.3c0,0-0.5,0.3-0.1,1c0,0,0.3,0.3,0.5,0.1C27.7,9.1,27.6,9,27.6,9s0.8,1,0.4,2.3
			c-0.3,1.4-1.3,1.6-1.3,1.6s-1.7,0.7-2.7-1C23,10.3,24,9,24,9S25.4,7.5,27,8.3z"/>
		<path fill="#090000" d="M26.1,7.7c0,0,1.6-0.1,2.2,1.6s0,2.8,0,2.8s-0.7,1.6-2.5,1.4s-2.2-2.2-2.2-2.2S23,8.1,26.1,7.7z M23.9,8.4
			c-1.1,1-0.7,3-0.7,3s0.6,2.2,2.4,2.2s2.5-1,2.5-1s1.2-1.2,0.5-3.4c-0.6-2-2.4-1.7-2.4-1.7S25,7.3,23.9,8.4z"/>
		<path fill="#090000" d="M33.8,15.9c-0.1-1.2-0.6-3-1-3.8c-0.3-0.8-1.1-2.5-1.5-3.2C31,8.2,30.1,7,29.2,6.1S27.6,4.8,27,4.5
			s-2.2-0.1-3.3,0.1c-1.1,0.2-1.8,0.2-2.7,0.3c-0.9,0.1-2.6-0.3-2.6-0.3s-2.6-0.7-4.2,0S11.8,6,10.4,7.8s-2.2,4.3-2.8,5.5
			c-0.3,1.1-0.9,3.8-0.7,4.9c0.2,1.2,0.5,2.3,1.6,3.3s2,1.2,3.8,1.8c1.8,0.6,7.5,1,7.5,1s2.2,0.1,4.1,0.1c1.9-0.1,2.9-0.3,4.6-0.6
			c1.6-0.3,2.9-1,3.8-1.9c0.9-0.9,1.3-2.8,1.3-2.8S34,17.1,33.8,15.9z M33.7,18.1c0,1.3-0.9,2.9-1.3,3.2c-0.4,0.3-1.6,1.6-3.4,1.9
			c-1.7,0.3-4.8,0.9-6.9,0.7C20,23.7,20,23.8,20,23.8s-4.1-0.1-5.5-0.5C13,23,11.6,22.8,10,21.9s-2.2-2-2.5-3.7S7.8,14,7.8,14
			s0.7-2.9,1.7-4.4s1.4-2.5,3.2-3.8c1.8-1.4,3.4-1.4,3.8-1.4c0.3,0,1.2,0.1,2.4,0.3h-0.1c0,0-0.3,0.2-0.3,0.4S18.6,6,18.6,6
			c-1.6-0.6-2.4-1-3.7-1S13,6,13,6c2.2-0.8,2.9,0.1,4.8,0.8s2.5-0.1,2.5-0.1c0.1-1.2-1.2-1.9-1.2-1.9L20,4.9c0.1,0,0.1,0.1,0.2,0.1
			c0.8,0.3,1.7,0.2,2.7,0c-0.3,0.2-1,0.6-1.4,1c-0.4,0.7,0.2,1.2,0.2,1.2c0.3,0.4,2.9-0.5,2.9-0.5s2.2-0.9,2.9-0.5
			c0.9,0.4,1,0.4,1,0.4s0.1-1.3-1.9-1.3C25.4,5.2,23,6.2,23,6.2c-0.1-0.2,0.2-0.6,0.3-0.9C23.4,5,23,5,23,5l0.7-0.2h-0.1
			c1-0.2,1.8-0.3,2.5-0.1c1.6,0.5,2.6,0.8,4.1,2.6c1.6,1.7,2.5,3.9,3,5.9C33.6,15.1,33.7,16.8,33.7,18.1z"/>
	</g>
	<g fill="#090000">
		<path d="M51.5,30.5c-0.6,0-1.4-0.9-1.4-1c-0.1-0.4-0.3-0.9-0.3-3.9c0-2.9,0.3-4.9,0.5-6.2c-0.8,0-2.7,0.7-2.9,0.7
			s-0.5-0.5-0.5-0.9c0-0.1,0-0.3,0.1-0.3c0.3-0.3,3.9-1.5,8-1.5c8.5,0,9.3,4.1,9.3,5.7C64.3,26.7,60.6,30.5,51.5,30.5z M54.8,18.9
			c-1.3,0-2.1,0.1-2.2,0.1c-0.2,1-0.5,2.7-0.7,4.6c-0.2,2.1-0.3,4.3-0.3,5.5c4.8,0,10-1.4,10-6C61.6,21.3,60.4,18.9,54.8,18.9z"/>
		<path d="M81.4,26.3c0,0.5-0.3,0.8-0.6,0.8c0,0-0.4,0-1-0.1c0.7,1.7,1.1,3,1.1,3.1c0,0.2-0.3,0.3-0.6,0.3c-0.6,0-0.6-0.2-0.7-0.3
			c0,0-0.7-1.5-1.6-3.3c-0.4,0-0.9-0.1-1.2-0.1c-1-0.1-2.8-0.1-4.6-0.1c-1.6,0-3.3,0-4.7,0.1c-1,1.8-1.6,3.4-1.7,3.4
			c0,0.1-0.1,0.3-0.7,0.3c-0.3,0-0.6-0.1-0.6-0.3c0-0.1,0.4-1.5,1.2-3.2c-0.5,0-1,0-1.2,0.1c-0.3,0-0.5-0.3-0.5-0.7
			c0-0.2,0.1-0.2,0.2-0.3c0.5-0.1,1.2-0.3,2.1-0.3c1.6-3.7,4.2-8.4,6.5-8.4s4.7,4.3,6.3,8c1,0.1,2,0.3,2.2,0.4s0.3,0.3,0.3,0.4
			C81.4,26.1,81.4,26.2,81.4,26.3z M72.7,20c-1.2,0-3,2.9-4.5,5.5c2.6-0.3,5.6-0.3,8-0.3H77C75.5,22.6,73.8,20,72.7,20z"/>
		<path d="M87.2,26.5c1.1,0.4,2.1,0.7,2.9,1c4.5,1.5,5.4,1.3,5.4,1.9c0,0.5-0.3,0.9-0.9,1c0,0-0.1,0-0.2,0c-0.9,0-3.5-0.9-6-1.8
			c-1.8-0.7-3.5-1.5-4.6-2.1c0,1.1,0,2.1,0,2.7v0.6c0,0.3-0.3,0.5-0.7,0.5c-0.3,0-0.8-0.2-0.9-0.4c-0.1-0.4-0.3-1.6-0.3-4.6
			c0-2.9,0.3-4.9,0.5-6.2c-0.7,0-2.7,0.7-2.9,0.7S79,19.3,79,18.9c0-0.1,0-0.3,0.1-0.3c0.3-0.3,3.9-1.5,8-1.5c5.9,0,9.1,1.5,9.1,4
			C96.3,24.2,91.5,26,87.2,26.5z M88.4,18.5c-1.6,0-2.4,0-3.6,0.3c-0.2,1-0.5,2.7-0.7,4.7C84,24,84,24.7,84,25.2c0.2,0,0.3,0,0.4,0
			h0.1c4.9-0.1,9.1-1.7,9.1-4.1C93.6,18.9,90.3,18.5,88.4,18.5z"/>
		<path d="M109.5,28c-1.3,1.6-3.3,2.4-5.3,2.4s-4.1-0.9-5.3-2.4c-1.5-1.8-1.9-4.3-1.9-6.2c0-0.7,0.1-1.4,0.2-1.9
			c0.3-1.8,1.2-2.4,2.2-2.5l0,0c0.3,0,0.7,0.1,0.7,0.6s-0.6,1.9-0.6,4.6c0,3.8,1.5,6.6,4.8,6.6c3.2,0,4.8-2.8,4.8-6.6
			c0-2.7-0.7-4-0.7-4.6c0-0.5,0.4-0.6,0.7-0.6l0,0c1,0,1.9,0.7,2.2,2.5c0.1,0.5,0.2,1.2,0.2,1.9C111.4,23.7,111,26.2,109.5,28z"/>
		<path d="M131.1,30.3L131.1,30.3c-1.9,0-2.3-2.7-2.3-4.8s0.2-2.9,0.2-3.9V21c0-1-0.1-2.1-1.1-2.1c-2.1,0-3.6,3.9-3.6,9.3
			c0,0.6-0.3,1.2-1.1,1.2c-0.6,0-0.9-0.3-0.9-1v-0.1c0-0.6,0-1.2,0-1.7c0-7.9-1.8-7.9-1.8-7.9c-2,0-3.6,3.2-3.6,10.3
			c0,0.8-0.7,1-1.3,1c-0.4,0-0.9,0-0.9-0.7l0,0c0-0.7,0-1.3,0-1.9c0-6.7-1.2-8.1-2.9-8.1c-0.3,0-0.9-1-0.9-1.6
			c0-0.3,0.1-0.5,0.4-0.5c2.7,0,4,1.8,4.7,4c0.3-1,1.6-3.9,4.3-3.9c0,0,3.1,0,3.5,3.5c1.2-3.4,4.1-3.4,4.1-3.4c2.4,0,3.6,1.2,3.6,4
			c0,0.7-0.4,5-0.4,5.1c0,3.1,1.2,2.8,1.2,3.3C132,30.2,131.3,30.3,131.1,30.3z"/>
		<path d="M149.5,26.3c0,0.5-0.3,0.8-0.6,0.8c0,0-0.4,0-1-0.1c0.7,1.7,1.1,3,1.1,3.1c0,0.2-0.3,0.3-0.6,0.3c-0.6,0-0.6-0.2-0.7-0.3
			c0,0-0.7-1.5-1.6-3.3c-0.4,0-0.9-0.1-1.2-0.1c-1-0.1-2.8-0.1-4.6-0.1c-1.6,0-3.3,0-4.7,0.1c-1,1.8-1.6,3.4-1.7,3.4
			c0,0.1-0.1,0.3-0.7,0.3c-0.3,0-0.6-0.1-0.6-0.3c0-0.1,0.4-1.5,1.2-3.2c-0.5,0-1,0-1.2,0.1c-0.3,0-0.5-0.3-0.5-0.7
			c0-0.2,0.1-0.2,0.2-0.3c0.5-0.1,1.2-0.3,2.1-0.3c1.6-3.7,4.2-8.4,6.5-8.4s4.7,4.3,6.3,8c1,0.1,2,0.3,2.2,0.4s0.3,0.3,0.3,0.4
			C149.5,26.1,149.5,26.2,149.5,26.3z M140.7,20c-1.2,0-3,2.9-4.5,5.5c2.6-0.3,5.6-0.3,8-0.3h0.8C143.6,22.6,141.9,20,140.7,20z"/>
		<path d="M157.3,30.5c-0.6,0-1.4-0.9-1.4-1c-0.1-0.4-0.3-0.9-0.3-3.9c0-2.9,0.3-4.9,0.5-6.2c-0.8,0-2.7,0.7-2.9,0.7
			s-0.5-0.5-0.5-0.9c0-0.1,0-0.3,0.1-0.3c0.3-0.3,3.9-1.5,8-1.5c8.5,0,9.3,4.1,9.3,5.7C170.1,26.7,166.3,30.5,157.3,30.5z
			 M160.6,18.9c-1.3,0-2.1,0.1-2.2,0.1c-0.2,1-0.5,2.7-0.7,4.6c-0.2,2.1-0.3,4.3-0.3,5.5c4.8,0,10-1.4,10-6
			C167.4,21.3,166.2,18.9,160.6,18.9z"/>
		<path d="M176.2,27.6c-0.3,0.7-1.4,2.2-3.1,2.2c-2.3,0-3.2-1.6-3.2-2.7c0-1.5,1.3-2.7,2.9-2.7c1.2,0,2.2,0.7,2.2,1.6
			c0,1-1,1.6-2.2,1.6c-0.3,0-0.8-0.1-1.1-0.2c0.2,0.7,0.8,1,1.5,1c1.5,0,2.2-0.9,2.9-2.1c0.1-0.1,0.1-0.2,0.2-0.2s0.3,0.3,0.3,0.7
			C176.3,27.3,176.3,27.4,176.2,27.6z M172.8,25.6c-0.6,0-1.1,0.6-1.2,1.3c0.1,0.1,0.3,0.1,0.6,0.1c0.6,0,1.4-0.2,1.4-0.7
			S173.3,25.6,172.8,25.6z"/>
		<path d="M179.8,27.6c-0.3,0.5-1,2.2-2.4,2.2c-1.5,0-1.6-3.1-1.6-3.3c-0.1-1.1-0.1-2.5-0.1-4.2s0.1-3.6,0.3-3.9
			c0.1-0.1,0.6-0.3,0.8-0.3c0.3,0,0.6,0.3,0.6,0.3c0,0.1,0.1,1,0.1,2.3c0,1.6-0.2,4.2-0.2,6.1v0.7c0,0.7,0.1,1.2,0.4,1.2
			c0.5,0,1.3-1,1.8-2.2c0.1-0.1,0.1-0.2,0.2-0.2s0.3,0.3,0.3,0.7v0.1C179.8,27.3,179.8,27.5,179.8,27.6z"/>
		<path d="M181,21.9c0,0.6-0.5,1.1-1.1,1.1l0,0c-0.6,0-1-0.5-1-1.2v-0.1c0-0.7,0.5-1.1,1-1.1h0.1C180.4,20.7,181,21.2,181,21.9
			L181,21.9z M183,27.6c-0.3,0.5-1,2.2-2.4,2.2c-1.3,0-1.7-1.3-1.7-3c0-0.4,0.1-1.9,0.2-2.1c0.1-0.2,0.3-0.3,0.7-0.3
			s0.7,0.1,0.8,0.3c0,0,0,0.2,0,0.4c0,0.5-0.1,1.5-0.1,2.1c0,0.9,0.1,1.6,0.5,1.6c0.5,0,1.3-1,1.8-2.2c0.1-0.1,0.1-0.2,0.2-0.2
			s0.3,0.3,0.3,0.7l0,0C183.1,27.3,183,27.4,183,27.6z"/>
		<path d="M187,24.7c0,0.6-0.6,5.1-2.9,5.1c-2.2,0-2.7-4.2-2.7-5v-0.1c0-0.3,0.4-0.3,0.9-0.3c0.7,0,0.9,0.1,1,0.3c0.1,1,0.4,4,1.3,4
			c0.7,0,1.3-2.6,1.4-4c0-0.3,0.3-0.3,0.5-0.3h0.2C186.9,24.4,187,24.5,187,24.7L187,24.7z"/>
		<path d="M193.4,27.6c-0.3,0.7-1.4,2.2-3.1,2.2c-2.3,0-3.2-1.6-3.2-2.7c0-1.5,1.3-2.7,2.9-2.7c1.2,0,2.2,0.7,2.2,1.6
			c0,1-1,1.6-2.2,1.6c-0.3,0-0.8-0.1-1.1-0.2c0.2,0.7,0.8,1,1.5,1c1.5,0,2.2-0.9,2.9-2.1c0.1-0.1,0.1-0.2,0.2-0.2s0.3,0.3,0.3,0.7
			C193.5,27.3,193.5,27.4,193.4,27.6z M190,25.6c-0.6,0-1.1,0.6-1.2,1.3c0.1,0.1,0.3,0.1,0.6,0.1c0.6,0,1.4-0.2,1.4-0.7
			S190.5,25.6,190,25.6z"/>
		<path d="M198.7,27.6c-0.3,0.5-1,2.2-2.4,2.2c-1,0-1.4-0.6-1.4-1.4c0-0.5,0.4-1.5,0.6-1.9c0-0.1,0-0.1,0-0.1c0-0.1-0.1-0.2-0.2-0.2
			c-0.4,0-1-0.2-1.2-0.3c-0.2,0.5-1,2.4-1.4,2.4c-0.2,0-0.3-0.6-0.3-0.7s0.7-1,1-2.1c-0.1-0.1-0.6-0.3-0.6-1.3c0-0.3,0.3-1,0.7-1
			c0.6,0,0.9,0.5,0.9,1.2c0.3,0.2,1.3,0.3,1.7,0.3c0.7,0,1.1,0.3,1.1,0.9s-0.9,2.1-0.9,2.5c0,0.1,0.1,0.3,0.2,0.3
			c0.5,0,1.3-1,1.8-2.2c0.1-0.1,0.1-0.2,0.2-0.2s0.3,0.3,0.3,0.7C198.9,27.3,198.8,27.4,198.7,27.6z"/>
		<path d="M205.2,27.6c-0.6,1.1-1.3,1.9-2.1,2.5c0,1.2-0.1,2.3-0.2,3.2c-0.2,2.2-1.6,2.9-2.4,2.9c-1.2,0-2.1-1-2.1-2.4
			c0-1.8,1.5-2.6,3.1-3.7v-1.4c-0.4,0.6-1,1.1-1.8,1.1c-1.3,0-1.8-1.3-1.8-3c0-0.4,0.1-1.9,0.2-2.1c0.1-0.2,0.3-0.3,0.7-0.3
			s0.8,0.1,0.9,0.3c0,0,0,0.2,0,0.4c0,0.5-0.1,1.5-0.1,2.1c0,0.9,0.1,1.6,0.5,1.6s1-0.7,1.5-1.5v-0.4c0-1.4,0-2,0.1-2.2
			s0.3-0.3,0.7-0.3s0.7,0.1,0.8,0.3c0,0.1,0.1,2.1,0.1,4.2c0.7-0.6,1.4-1.4,1.8-2.3c0-0.1,0.1-0.2,0.2-0.2s0.3,0.3,0.3,0.7v0.1
			C205.3,27.3,205.3,27.4,205.2,27.6z M201.5,31.2c-1.1,0.7-1.9,1.4-1.9,2.5c0,0.3,0.2,1,1,1c0.7,0,1-0.9,1-2.2
			C201.5,32.2,201.5,31.8,201.5,31.2z"/>
	</g>
	<g fill="#090000">
		<path d="M50.9,10.7V7.6H48V7.2h6.2v0.4h-2.9v3.1h3.3v0.4h-3.3V14h-0.4v-2.9h-3.3v-0.4C47.6,10.7,50.9,10.7,50.9,10.7z M49.1,8.1
			c0.4,0.7,0.7,1.5,0.9,2.1c-0.3,0.1-0.3,0.2-0.4,0.2c-0.2-0.6-0.4-1.4-0.9-2.1L49.1,8.1z M53.7,8.2c-0.3,0.6-0.7,1.4-1.1,2.1
			l-0.3-0.2c0.4-0.7,0.8-1.6,1-2.1L53.7,8.2z"/>
		<path d="M57.4,9.3h5.9v0.4h-5.9v1.2c0,1.1-0.2,2.2-0.8,3.2c-0.2-0.2-0.3-0.2-0.3-0.3c0.5-0.9,0.7-1.9,0.7-2.9V9.4h0.2L57.4,9.3
			L57.4,9.3z M57.4,7.3v1h2.2V6.9H60v1.3h2.2v-1h0.4v1.4h-5.7V7.3H57.4z M60.1,11.8v-1h-2.2v-0.3h4.8v0.3h-2.2v1h2.7v0.4h-2.7V14
			h-0.4v-1.8h-2.5v-0.4H60.1z"/>
		<path d="M67.5,8.2c-0.3,1.9-1,3.9-2.1,5.3L65,13.2c1-1.3,1.7-3.3,2.1-5.1L67.5,8.2z M71.8,13.2l-0.5,0.2c-0.3-1.5-1.2-3.5-2.2-5.2
			L69.5,8C70.6,9.7,71.5,11.7,71.8,13.2z"/>
		<path d="M77.2,13.9h-0.5v-4c-1,0.7-2,1.2-2.9,1.6L73.5,11c1.8-0.7,4.4-2.5,5.4-3.8l0.3,0.3c-0.5,0.6-1.3,1.3-2.2,2L77.2,13.9
			L77.2,13.9z"/>
		<path d="M83.3,9.4l-0.5-2l0.4-0.1l0.5,2.1l3.5-0.7l0.3,0.2c-0.3,0.8-0.9,1.8-1.4,2.6l-0.4-0.3c0.4-0.6,1-1.5,1.1-2.1l-3,0.6l1,4
			l-0.4,0.1l-1-4.1l-2.1,0.4l-0.1-0.4L83.3,9.4z"/>
	</g>
	<g fill="#090000">
		<path d="M96.2,10.3v0.5h-6.3v-0.5H96.2z"/>
	</g>
	<g fill="#090000">
		<path d="M119.4,13.9l-0.3-0.3c1.6-0.8,2.1-1.7,2.1-3.4H118V9.7h6.3v0.4h-2.7C121.6,11.9,121.2,12.9,119.4,13.9z M123.3,7.8v0.4
			h-4.5V7.8H123.3z M123.5,7.2c0.3,0.3,0.6,0.7,0.9,1l-0.3,0.2c-0.3-0.3-0.5-0.8-0.8-1L123.5,7.2z M124.2,6.7c0.3,0.3,0.6,0.7,0.9,1
			l-0.3,0.2c-0.3-0.4-0.5-0.8-0.9-1L124.2,6.7z"/>
		<path d="M127.4,7.5v3.7H127V7.5H127.4z M131.1,7.4v2.9c0,1.6-0.8,2.9-2.5,3.5l-0.3-0.4c1.6-0.7,2.3-1.7,2.3-3.1V7.4H131.1z"/>
		<path d="M135.9,8.3c-0.3,1.9-1.1,3.9-2.1,5.2l-0.4-0.3c1-1.3,1.7-3.2,2-5L135.9,8.3z M140.3,13.2l-0.5,0.2
			c-0.3-1.5-1.2-3.5-2.2-5.1l0.4-0.2C139.1,9.8,139.9,11.7,140.3,13.2z M138.8,7.1c0.3,0.4,0.4,0.9,0.6,1.3l-0.3,0.1
			c-0.2-0.4-0.3-0.9-0.6-1.3L138.8,7.1z M139.9,6.8c0.3,0.4,0.4,0.9,0.6,1.3l-0.3,0.2c-0.2-0.4-0.3-0.9-0.6-1.3L139.9,6.8z"/>
		<path d="M143.1,7.5v3.7h-0.4V7.5H143.1z M146.9,7.4v2.9c0,1.6-0.8,2.9-2.5,3.5l-0.3-0.4c1.6-0.7,2.3-1.7,2.3-3.1V7.4H146.9z"/>
		<path d="M155.7,10.3v0.5h-6.3v-0.5H155.7z"/>
		<path d="M159.1,8.8V7.2h0.4v1.6h2.8V7.2h0.4v1.6h1.5v0.4h-1.5V10c0,1.8-0.7,2.9-2.5,3.8l-0.3-0.4c1.7-0.8,2.3-1.8,2.3-3.4V9h-2.8
			v2.1h-0.3v-2h-1.5V8.8H159.1z"/>
		<path d="M172.5,10.3v0.5h-6.3v-0.5H172.5z"/>
		<path d="M180.1,9.2c-1.1,0.5-2.7,1-4.2,1.4V12c0,0.9,0.3,1.1,1.2,1.2c0.3,0,0.8,0,1.3,0c0.7,0,1.5,0,1.9-0.1v0.5
			c-0.6,0-1.3,0.1-2,0.1c-0.5,0-1,0-1.3,0c-1-0.1-1.6-0.5-1.6-1.6V7.5h0.5v2.7c1.5-0.3,3-0.9,4.1-1.4L180.1,9.2z M179.6,7.3
			c0.3,0.3,0.6,0.7,0.9,1l-0.3,0.2c-0.3-0.3-0.5-0.8-0.9-1L179.6,7.3z M180.3,6.8c0.3,0.3,0.6,0.7,0.9,1L180.9,8
			c-0.3-0.3-0.5-0.8-0.9-1L180.3,6.8z"/>
		<path d="M189,13.5l-0.4,0.3c-0.6-0.8-1.6-1.8-2.4-2.4c-0.9,1-1.9,1.9-3,2.4l-0.3-0.4c2.2-1,4-3.3,4.5-5h-4.2V8h4.3l0.3,0.2
			c-0.3,1-0.9,2-1.6,2.9C187.3,11.7,188.2,12.7,189,13.5z"/>
	</g>
</g>
  </svg>
)

export default SvgLogoTodoxiSp
