import axios from 'axios'
import * as React from 'react'
import styled, { AnyStyledComponent } from 'styled-components'
import I18n from '../../core/i18n'
import { BREAKPOINT_TABLET_SMALL } from '../../static/constants'

interface IContents {
  createdAt: string
  id: string
  keyImage: {
    url: string
  }
  publishedAt: string
  title: string
  body: string
  updatedAt: string
  viewDate: string
  visible: boolean
}

const API_URL = 'https://darumadeli.microcms.io/api/v1/info'
const API_KEY = '657f85a1-cb2a-485b-9dcb-ad844f25016c'


const TopNews: React.FC<{}> = props => {
  const [contents, setContents] = React.useState<IContents[]>([])
  const getData = async () => {
    const { data } = await axios.get(API_URL, { headers: { 'X-API-KEY': API_KEY } })

    if (data.contents) {
      setContents(data.contents)
    }
  }

  React.useEffect(() => {
    getData()
  }, [])

  return (
    <S.TopNews>
      <S.Title>キャンペーン</S.Title>
      <S.Contents>
        {contents.length > 0 ? (
          contents.map(content => (
            <S.Content key={content.id}>
              <img src={content.keyImage?.url} alt="" />
              <S.ContentMain>
                <h2>{content.title}</h2>
                <a href={'/info/' + content.id}>{I18n.t('generic.see_more')}</a>
              </S.ContentMain>
            </S.Content>
          ))
        ) : (
          <S.NoContents>キャンペーンはありません</S.NoContents>
        )}
      </S.Contents>
    </S.TopNews>
  )
}

const S: { [key: string]: AnyStyledComponent } = {}

S.TopNews = styled.section`
  width: 100%;
  max-width: 1360px;
  margin: 24px auto;
  padding: 0 16px;
`

S.Title = styled.h1`
  font-size: 24px;
  font-weight: bold;
`

S.NoContents = styled.h2`
  font-size: 18px;
  font-weight: normal;
  margin: 24px 0;
  padding: 0 20px;
`

S.Contents = styled.div`
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  margin: 0;
`

S.Content = styled.div`
  display: block;
  width: 100%;
  padding: 60px 0;
  text-align: center;
  margin: 0 auto;
@media (max-width: ${BREAKPOINT_TABLET_SMALL}px) {
    width: 100%;
  }

  > img {
    width: 160px;
    height: 120px;
    object-fit: cover;
  }
`

S.ContentMain = styled.div`
  margin-left: 20px;

  h2 {
    font-size: 18px;
  }

  p {
    margin-top: 10px;
    font-size: 16px;
  }
`

export default TopNews
