import axios from 'axios'
import * as React from 'react'
import styled, { AnyStyledComponent } from 'styled-components'
import { BREAKPOINT_TABLET_SMALL, BREAKPOINT_TABLET_LARGE, COLORS } from '../../static/constants'

interface IContents {
  createdAt: string
  id: string
  keyImage: {
    url: string
  }
  publishedAt: string
  title: string
  body: string
  updatedAt: string
  viewDate: string
  visible: boolean
}

const API_URL = 'https://darumadeli.microcms.io/api/v1/news'
const API_KEY = '657f85a1-cb2a-485b-9dcb-ad844f25016c'

const TopNews: React.FC<{}> = props => {
  const [contents, setContents] = React.useState<IContents[]>([])
  const getData = async () => {
    const { data } = await axios.get(API_URL, { headers: { 'X-API-KEY': API_KEY } })

    if (data.contents) {
      setContents(data.contents)
    }
  }

  React.useEffect(() => {
    getData()
  }, [])

  return (
    <S.TopNews>
      <S.Title>キャンペーン・お知らせ</S.Title>
      <S.Contents>
        {contents.length > 0 ? (
          contents.map(content => (
            <S.Content key={content.id}>
              <img src={content.keyImage?.url} alt="" />
              <S.ContentMain>
                <h2>{content.title}</h2>
                <div dangerouslySetInnerHTML={{ __html: content.body }} />
              </S.ContentMain>
            </S.Content>
          ))
        ) : (
          <S.NoContents>お知らせはありません</S.NoContents>
        )}
      </S.Contents>
    </S.TopNews>
  )
}

const S: { [key: string]: AnyStyledComponent } = {}

S.TopNews = styled.section`
  width: 100%;
  max-width: 1360px;
  margin: 24px auto;
  padding: 0 16px;
`

S.Title = styled.h1`
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  font-style: italic;
`

S.NoContents = styled.h2`
  font-size: 18px;
  font-weight: normal;
  margin: 24px 0;
  padding: 0 20px;
`

S.Contents = styled.div`
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  margin: 0px;
`

S.Content = styled.div`
  display: flex;
  width: 50%;
  padding: 60px 20px 30px;
  border-bottom: none;
  margin-bottom: 120px;

  @media (max-width: ${BREAKPOINT_TABLET_SMALL}px) {
    padding: 30px 0px 0px;
    border-bottom: none;
    width: 100%;
    display: block;
  }
  @media (max-width: ${BREAKPOINT_TABLET_LARGE}px) {
    display: block;
  }

  > img {
    height: 200px;
    width: 330px;
    margin: 0 auto;
    display: block;
  }
`

S.ContentMain = styled.div`
  margin-left: 20px;

  h2 {
    font-size: 18px;
    margin-top: 18px;
  }

  p {
    padding-right: 22px;
  }
`

export default TopNews
