import { IPager, IPost } from 'core/interfaces'
import React from 'react'
import styled, { AnyStyledComponent } from 'styled-components'
import I18n from '../../../core/i18n'
import IAddress from '../../../core/interfaces/IAddress'
import { IJsonResponse } from '../../../core/JsonApiSerializer'
import { postService } from '../../../core/services'
import {
  BREAKPOINT_DESKTOP,
  BREAKPOINT_TABLET_MOBILE,
  COLORS,
  HEADER_HEIGHT_MOBILE,
} from '../../../static/constants'
import injectGoogleMaps from '../../../utils/injectGoogleMaps'
import { urlParams, useMarkers, usePosts } from './lib'
import Map from './Map'
import PostList from './PostList'
import Search from './Search'

interface IProps {
  isSignedIn: boolean
  favorite: boolean
  posts: {
    posts: IPost[]
  }
  user_address: any
  meta: IPager
  tags: IJsonResponse
  categories: IJsonResponse
  address?: IAddress
  delivery_range?: number
}

// 札幌市
const DEFAULT_LAT = '43.06417'
const DEFAULT_LNG = '141.34694'

export const PostsWithMap: React.FC<IProps> = props => {
  // show area select panels
  if (
    !urlParams.get('area') &&
    !urlParams.get('lat') &&
    !urlParams.get('lng') &&
    !urlParams.get('keywords') &&
    !urlParams.get('category_ids[]') &&
    !props.favorite
  ) {
    const zoom = window.innerWidth > BREAKPOINT_DESKTOP ? 13 : 12
    // Circle中心
    const lat = props.address ? props.address.latitude1 : DEFAULT_LAT
    const lng = props.address ? props.address.longitude1 : DEFAULT_LNG
    // MAP中心
    const user_lat =
      props.isSignedIn && props.user_address
        ? props.user_address.latitude1
        : props.address
        ? props.address.latitude1
        : DEFAULT_LAT
    const user_lng =
      props.isSignedIn && props.user_address
        ? props.user_address.longitude1
        : props.address
        ? props.address.longitude1
        : DEFAULT_LNG
    location.href = `/?lat=${lat}&lng=${lng}&user_lat=${user_lat}&user_lng=${user_lng}&zoom=${zoom}&radius=${props.delivery_range ??
      3}`
  } else {
    const PostSearchIndexWithGoogleMap = injectGoogleMaps(PostSearchIndex)
    return <PostSearchIndexWithGoogleMap {...props} />
  }
}

const PostSearchIndex: React.FC<IProps> = props => {
  const { posts: initialPosts, pagination: initialPagination } = React.useMemo(
    () => postService.getPostsFromJson(props.posts.posts),
    []
  )
  const defaultKeyword = React.useMemo(() => urlParams.get('keywords'), [])

  const { loading, map, posts, toggleLike, getPostsByPage, pagination } = usePosts({
    favorite: props.favorite,
    initialPosts:
      props.favorite || urlParams.get('keywords') || urlParams.get('category_ids[]')
        ? initialPosts
        : [],
    initialPagination: props.favorite ? initialPagination : null,
  })

  function getCurrentPosition() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(setHref)
    }
  }
  function setHref(position?: any) {
    const currentPosition = position
      ? new google.maps.LatLng(position.coords.latitude, position.coords.longitude)
      : null
    const zoom = window.innerWidth > BREAKPOINT_DESKTOP ? 13 : 12
    let href = '/?'
    if (!props.address) {
      href += 'area=%E6%9C%AD%E5%B9%8C'
    } else {
      if (props.address) {
        href += `lat=${props.address.latitude1}&lng=${props.address.longitude1}`
      }
      if (currentPosition) {
        href += `&user_lat=${currentPosition.lat()}&user_lng=${currentPosition.lng()}`
      }
      href += `&zoom=${zoom}&radius=${props.delivery_range ?? 3}`
    }
    location.href = href
  }

  const { handleOnMouseEnter, handleOnMouseLeave } = useMarkers({
    toggleLike,
    posts,
    map,
  })

  const title = (count: number) =>
    props.favorite
      ? I18n.t('post.result_favorite', { count })
      : I18n.t('post.result_search', { count })

  return (
    <S.Container>
      {!props.favorite && <Search getCurrentPosition={getCurrentPosition} />}

      <Map />

      <PostList
        loading={loading}
        title={title}
        handleOnMouseEnter={handleOnMouseEnter}
        handleOnMouseLeave={handleOnMouseLeave}
        getPostsByPage={getPostsByPage}
        isSignedIn={props.isSignedIn}
        favorite={props.favorite}
        posts={posts}
        meta={pagination}
        keyword={defaultKeyword}
      />
    </S.Container>
  )
}

const S: { [k: string]: AnyStyledComponent } = {}
S.Container = styled.div`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  @media (max-width: ${BREAKPOINT_TABLET_MOBILE}px) {
    top: ${HEADER_HEIGHT_MOBILE}px;
  }
`

S.Title = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 10px;

  h2 {
    padding: 0 30px
    color: ${COLORS.Text};
    border-bottom: 3px solid;
    border-image: linear-gradient(to right, #ff00c3 0%, yellow 100%);
    border-image-slice: 1;
  }
`

export default PostsWithMap
