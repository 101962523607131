import * as moment from 'moment'
import * as React from 'react'
import styled, { AnyStyledComponent } from 'styled-components'
import injectGoogleMaps from 'utils/injectGoogleMaps'
import I18n from '../../../../../core/i18n'
import { IWindow } from '../../../../../core/interfaces'
import { IReservation } from '../../../../../core/interfaces'
import { IJsonResponse } from '../../../../../core/JsonApiSerializer'
import { reservationService } from '../../../../../core/services'
import {
  BREAKPOINT_TABLET_LARGE,
  BREAKPOINT_TABLET_MOBILE,
  BREAKPOINT_TABLET_SMALL,
  COLORS,
  HEADER_HEIGHT,
} from '../../../../../static/constants'
import { Button, Panel } from '../../../../atoms'
import { getDeliveryState } from '../../index'
import ApproveModal from './ApproveModal'
import StatusModal from './StatusModal'

declare var window: IWindow
interface IProps {
  reservations: {
    reservations: IJsonResponse
  }
  taxi: {
    taxi: IJsonResponse
  }
  isAdmin: boolean
}

const HostShowIndex: React.FC<IProps> = props => {
  // const { data: reservation } = reservationService.getDataFromJson(props.reservation)
  // const reservationItems = reservation.reservation_items

  const { reservations: initialReservation } = reservationService.getReservationsFromJson(
    props.reservations
  )

  const { data: initalTaxi } = reservationService.getDataFromJson(props.taxi)
  const [reservation, setReservation] = React.useState(initialReservation)
  const [taxi, setTaxi] = React.useState(initalTaxi)
  const [deliveryTime, setDeliveryTime] = React.useState<number>(0)
  const [processing, setProcessing] = React.useState(false)
  const [specifiedDeriverlyTime, setSpecifiedDeriverlyTime] = React.useState<{
    label: string
    data: {
      DD: string
      HH: string
      mm: string
    }
  }>(null)

  // to do: will use specifiedPickUpTime to display pickup time
  const [specifiedPickUpTime, setSpecifiedPickUpTime] = React.useState<{
    label: string
    data: {
      DD: string
      HH: string
      mm: string
    }
  }>(null)

  React.useEffect(() => {
    const specifiedDeliveryMoment = moment(reservation.specified_delivery_time_at)
    const newSpecifiedDeliveryTime = {
      label: specifiedDeliveryMoment.format('YYYY-MM-DD HH:mm'),
      data: {
        DD: specifiedDeliveryMoment.format('DD'),
        HH: specifiedDeliveryMoment.format('HH'),
        mm: specifiedDeliveryMoment.format('mm'),
      },
    }
    setSpecifiedDeriverlyTime({ ...newSpecifiedDeliveryTime })
  }, [reservation])

  React.useEffect(() => {
    const specifiedPickUpMoment = moment(reservation.specified_pickup_time_at)
    const newSpecifiedPickUpTime = {
      label: specifiedPickUpMoment.format('YYYY-MM-DD HH:mm'),
      data: {
        DD: specifiedPickUpMoment.format('DD'),
        HH: specifiedPickUpMoment.format('HH'),
        mm: specifiedPickUpMoment.format('mm'),
      },
    }
    setSpecifiedPickUpTime({ ...newSpecifiedPickUpTime })
  }, [reservation])

  const setDuration = (post, guest) => {
    const distanceMatrixService = new google.maps.DistanceMatrixService()

    distanceMatrixService.getDistanceMatrix(
      {
        origins: [post],
        destinations: [guest],
        travelMode: google.maps.TravelMode.DRIVING,
        drivingOptions: {
          departureTime: new Date(),
          trafficModel: google.maps.TrafficModel.BEST_GUESS,
        },
      },
      (response, status) => {
        if (status === google.maps.DistanceMatrixStatus.OK) {
          const results = response.rows[0].elements
          const duration = results[0].duration.value
          if (duration) {
            const minute = Math.round(duration / 60)
            setDeliveryTime(minute)
          }
        }
      }
    )
  }

  React.useEffect(() => {
    if (reservation.address && reservation.post.address) {
      const { latitude1: postLatitude, longitude1: postLongitude } = reservation.post.address
      const { latitude1: gusetLatitude, longitude1: gusetLongitude } = reservation.address
      const postLatLng = new google.maps.LatLng(postLatitude, postLongitude)
      const userLatLng = new google.maps.LatLng(gusetLatitude, gusetLongitude)

      setDuration(postLatLng, userLatLng)
    }
  }, [google, reservation])

  const handleDecline = React.useCallback(async () => {
    const { reservation: declineReservation, flush } = await reservationService.declineReservation(
      reservation.id
    )
    window.globalModal.closeModal()

    setReservation(declineReservation)
    window.flashMessages.addMessage({ text: flush.message, type: flush.type })
  }, [reservation])

  const handleCancel = React.useCallback(async () => {
    const { reservation: canceledReservation, flush } = await reservationService.cancelReservation(
      reservation.id
    )
    window.globalModal.closeModal()

    setReservation(canceledReservation)
    window.flashMessages.addMessage({ text: flush.message, type: flush.type })
  }, [reservation])

  const handleApproveModal = async () => {
    // 処理中(true)なら非同期処理せずに抜ける
    if (processing) {
      return
    }
    setProcessing(true) // 処理中フラグを上げる

    location.href = `/reservations/${reservation.uuid}/approve`
  }

  const handleCancelModal = async () => {
    // 処理中(true)なら非同期処理せずに抜ける
    if (processing) {
      return
    }
    setProcessing(true) // 処理中フラグを上げる

    location.href = `/reservations/${reservation.uuid}/cancel`
  }

  const setDeliveryStatus = updateDelivery => {
    setReservation({
      ...reservation,
      delivery: updateDelivery,
    })
  }

  const titleHead = (
    <S.TitleHeader>
      <span className="header-title">{I18n.t('reservation.detail')}</span>
      {reservation.workflow_state === 'requested' && (
        <>
          <Button primary={true} handleClick={handleApproveModal}>
            {I18n.t('reservation.approved')}
          </Button>
          <Button primary={true} handleClick={handleCancelModal}>
            {I18n.t('reservation.cancel')}
          </Button>
        </>
      )}
    </S.TitleHeader>
  )
  return (
    <S.LayoutIndex>
      <S.Main>
        <Panel title={titleHead}>
          <ul>
            {reservation.post.event_flag && (
              <S.ListItem>
                <S.ListItemTitle />
                <div>
                  <p className="reservationRemark">これは予約の注文です</p>
                </div>
              </S.ListItem>
            )}
            <S.ListItem>
              <S.ListItemTitle>{I18n.t('reservation.reservation_id')}</S.ListItemTitle>
              <S.ListItemContent>
                <div>{reservation.id}</div>
              </S.ListItemContent>
            </S.ListItem>
            <S.ListItem>
              <S.ListItemTitle>{I18n.t('reservation.user_id')}</S.ListItemTitle>
              <S.ListItemContent>
                <div>{reservation.user.id}</div>
              </S.ListItemContent>
            </S.ListItem>
            <S.ListItem>
              <S.ListItemTitle>{I18n.t('reservation.post_id')}</S.ListItemTitle>
              <S.ListItemContent>
                <div>{reservation.post.id}</div>
              </S.ListItemContent>
            </S.ListItem>
            <S.ListItem>
              <S.ListItemTitle>{I18n.t('reservation.reservation_date')}</S.ListItemTitle>
              <S.ListItemContent>
                <div>{reservation.created_at}</div>
              </S.ListItemContent>
            </S.ListItem>
            <S.ListItem>
              <S.ListItemTitle>{I18n.t('reservation.reservation_info')}</S.ListItemTitle>
              <S.ListItemContent>
                {reservation.reservation_items.map((item, index) => (
                  <p key={item.id}>
                    <span>{item.post_item?.name}</span>
                    <span> x </span>
                    <span>{item.quantity}</span>
                    <S.ListItemOption>
                      {item.reservation_item_options.map(option => (
                        <p key={option.id}>
                          <span>{option.option_name}</span>
                          <span> - </span>
                          <span>{option.option_item_name}</span>
                        </p>
                      ))}
                    </S.ListItemOption>
                  </p>
                ))}
              </S.ListItemContent>
            </S.ListItem>
            {reservation.remarks && (
              <S.ListItem className="border_bottom">
                <S.ListItemTitle>{I18n.t('generic.note')}</S.ListItemTitle>
                <S.ListItemContent>
                  <div>
                    <p className="reservationRemark">{reservation.remarks}</p>
                  </div>
                </S.ListItemContent>
              </S.ListItem>
            )}
            <S.ListItem>
              <S.ListItemTitle>{I18n.t('reservation.taken_post')}</S.ListItemTitle>
              <S.ListItemContent>
                <p>{reservation.post.name}</p>
                <p>電話: {reservation.post.address?.phone || ''}</p>
                <p>所在地: {reservation.post.public_address}</p>
              </S.ListItemContent>
            </S.ListItem>
            <S.ListItem>
              <S.ListItemTitle>{I18n.t('reservation.taken_taxi')}</S.ListItemTitle>
              <S.ListItemContent>
                <p>{taxi.name}</p>
                <p>電話: {taxi.address?.phone || ''}</p>
                <p>
                  住所:
                  {taxi.address?.postal_code1 && <span>〒{taxi.address?.postal_code1}</span>}
                  <span> {taxi.address?.state1 || ''}</span>
                  <span> {taxi.address?.city1 || ''}</span>
                  <span> {taxi.address?.other1_1 || ''}</span>
                  <span> {taxi.address?.other1_2 || ''}</span>
                </p>
              </S.ListItemContent>
            </S.ListItem>
            {reservation.workflow_state === 'requested' && (
              <S.ListItem>
                <S.ListItemTitle>お届け希望時間</S.ListItemTitle>
                <S.ListItemContent>
                  <div>{specifiedDeriverlyTime?.label}</div>
                </S.ListItemContent>
              </S.ListItem>
            )}
            {reservation.delivery?.estimated_delivery_time_at && (
              <S.ListItem>
                <S.ListItemTitle>{I18n.t('reservation.delivery_time')}</S.ListItemTitle>
                <S.ListItemContent>
                  <div>
                    {moment(reservation.delivery.estimated_delivery_time_at).format(
                      I18n.t('reservation.date_format')
                    )}
                  </div>
                </S.ListItemContent>
              </S.ListItem>
            )}
            <S.ListItem>
              <S.ListItemTitle>{I18n.t('reservation.customer_info')}</S.ListItemTitle>
              <S.ListItemContent>
                <p>{reservation.user.username}</p>
                <p>電話:{reservation.address?.phone}</p>
                <p>
                  <span>{I18n.t('generic.address')}: </span>
                  {`${reservation.address?.google_full_address} ${reservation.address?.other1_2}`}
                  {/* {reservation.address?.postal_code1 && (
                    <span>〒{reservation.address?.postal_code1}</span>
                  )}
                  <span> {reservation.address?.state1 || ''}</span>
                  <span> {reservation.address?.city1 || ''}</span>
                  <span> {reservation.address?.other1_1 || ''}</span>
                  <span> {reservation.address?.other1_2 || ''}</span> */}
                </p>
              </S.ListItemContent>
            </S.ListItem>
            <S.ListItem>
              <S.ListItemTitle>{I18n.t('price.subtotal')}</S.ListItemTitle>
              <S.ListItemContent>
                <div>¥{reservation.price}</div>
              </S.ListItemContent>
            </S.ListItem>
            <S.ListItem>
              <S.ListItemTitle>{I18n.t('price.taxi_fare')}</S.ListItemTitle>
              <S.ListItemContent>
                <div>¥{reservation.actual_taxi_fare}</div>
              </S.ListItemContent>
            </S.ListItem>
            {props.isAdmin && (
              <S.ListItem>
                <S.ListItemTitle>{I18n.t('reservation.estimated_fare')}</S.ListItemTitle>
                <S.ListItemContent>
                  <div>¥{reservation.estimated_taxi_fare}</div>
                </S.ListItemContent>
              </S.ListItem>
            )}
            {reservation.used_campaign && (
              <S.ListItem>
                <S.ListItemTitle>{reservation.campaign_name}</S.ListItemTitle>
                <S.ListItemContent>
                  <div>-¥{reservation.used_campaign}</div>
                </S.ListItemContent>
              </S.ListItem>
            )}
            {reservation.used_coupon && (
              <S.ListItem>
                <S.ListItemTitle>クーポン</S.ListItemTitle>
                <S.ListItemContent>
                  <div>-¥{reservation.used_coupon}</div>
                </S.ListItemContent>
              </S.ListItem>
            )}
            <S.ListItem className="border_bottom">
              <S.ListItemTitle>{I18n.t('price.total')}</S.ListItemTitle>
              <S.ListItemContent>
                <div>¥{reservation.total_price}</div>
              </S.ListItemContent>
            </S.ListItem>

            {reservation.post.memo_to_taxi && (
              <S.ListItem className="border_bottom">
                <S.ListItemTitle>{I18n.t('post.memo_to_taxi')}</S.ListItemTitle>
                <S.ListItemContent>
                  <div>{reservation.post.memo_to_taxi}</div>
                </S.ListItemContent>
              </S.ListItem>
            )}
          </ul>
          <S.Buttons>
            <div>
              <Button>
                <a href={`/${props.isAdmin ? 'admin' : 'host'}/reservations/`}>注文履歴一覧へ</a>
              </Button>
            </div>
          </S.Buttons>
        </Panel>
      </S.Main>
    </S.LayoutIndex>
  )
}

const S: { [key: string]: AnyStyledComponent } = {}
S.TitleHeader = styled.div`
  font-size: 18px;
  button {
    margin-left: 24px;
  }
`
S.LayoutIndex = styled.div`
  display: flex;
  height: calc(100vh - ${HEADER_HEIGHT}px);
  margin: 0 auto;
  background-color: #f6f8f9;
  @media (max-width: ${BREAKPOINT_TABLET_LARGE}px) {
    display: block;
  }
  @media print {
    font-size: 12px;
  }
`

S.Main = styled.section`
  flex: 1;
  padding: 16px;
  overflow: auto;
`

S.ListItem = styled.li`
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  &.border_bottom {
    border-bottom: solid 1px ${COLORS.Border};
    padding-bottom: 20px;
  }

  .reservationRemark {
    font-weight: bold;
    color: red;
  }

  @media print {
    flex-wrap: nowrap;
    margin-top: 0px;
  }
`

S.ListItemTitle = styled.div`
  width: 40%;
  @media (max-width: ${BREAKPOINT_TABLET_SMALL}px) {
    width: 100%;
    margin-top: 16px;
  }

  @media print {
    width: 20%;
  }
`

S.ListItemContent = styled.div`
  width: 60%;
  white-space: pre-wrap;
  @media (max-width: ${BREAKPOINT_TABLET_SMALL}px) {
    width: 100%;
    margin-top: 8px;
  }
  @media print {
    width: 80%;
  }
`
S.Buttons = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 40px;
  justify-content: space-between;
  @media (max-width: ${BREAKPOINT_TABLET_MOBILE}px) {
    > div {
      margin: 8px 0;
    }
  }

  @media print {
    display: none;
  }
`
S.Declined = styled.div`
  text-align: center;
  color: ${COLORS.Danger};
  margin-top: 30px;
  border: solid 1px;
  padding: 8px;

  @media print {
    display: none;
  }
`

S.ListItemOption = styled.div`
  font-size: 14px;
  padding-left: 16px;
`

export default injectGoogleMaps(HostShowIndex)
