import * as React from 'react'
import I18n from '../../../../core/i18n'
import { adminService } from '../../../../core/services'
import { CheckBox, InputText } from '../../../atoms'
import AdminLayoutNew from '../_layouts/new'

const FIELDS = {
  name: 'name',
  position: 'position',
  direct_order_flag: 'direct_order_flag',
}

const title = I18n.t('admin.create_new', {
  model: I18n.t('category', { scope: 'activerecord.models' }),
})

const AdminCategoryNew: React.FC<{}> = props => {
  const handleSubmit = React.useCallback(async (initialValues, values) => {
    const { createdCategory, flush } = await adminService.createCategory(values)
    // TODO 遷移先でのFlush
    location.href = '/admin/categories'
  }, [])

  return (
    <AdminLayoutNew
      model="category"
      title={title}
      fields={FIELDS}
      indexLink="/admin/categories"
      formItems={
        <>
          <InputText required={true} name="name" defaultValue="" label="Name" />
          <InputText required={true} name="position" defaultValue="" label="Position" />
          <CheckBox
            label={I18n.t('generic.direct_order_flag')}
            name="direct_order_flag"
            defaultChecked={false}
          />
        </>
      }
      handleSubmit={handleSubmit}
    />
  )
}

export default AdminCategoryNew
